import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import { Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const ScTag = styled(Tag)`
  text-transform: uppercase;
`;

const VehicleStatusLabel = ({ vehicle }: { vehicle: Vehicle }) => {
  const { t } = useTranslation();

  if (!vehicle.status) {
    return null;
  }

  if (vehicle.status === 'Sold' || vehicle.status?.code === 'sold') {
    return <ScTag color="gold">{t('VEHICLE__STATUS_SOLD')}</ScTag>;
  }

  if (vehicle.status === 'Reserved' || vehicle.status?.code === 'reserved') {
    return <ScTag color="blue">{t('VEHICLE__STATUS_RESERVED')}</ScTag>;
  }

  return <ScTag color="green">{t('VEHICLE__STATUS_AVAILABLE')}</ScTag>;
};

export default VehicleStatusLabel;
