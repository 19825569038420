export var save = function (localStorageKey, data) {
    if (data != null) {
        window.localStorage.setItem(localStorageKey, JSON.stringify(data));
    }
    else {
        window.localStorage.removeItem(localStorageKey);
    }
};
export var load = function (localStorageKey) {
    if (typeof window === 'undefined') {
        return undefined;
    }
    var dataStr = window.localStorage.getItem(localStorageKey);
    return dataStr ? JSON.parse(dataStr) : undefined;
};
