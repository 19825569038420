import { __assign, __read, __rest } from "tslib";
import { ConditionType, FilterOperatorTypes, } from '@hypercharge/portal-lambda-sdk/lib/types';
import { isArray, isEmpty, pick } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import { hyperfetch, json } from '../../utils/httpClient';
import { Status } from '../../utils/types';
import { OPTION_SOLD_BY_PARAM, useSearchParams } from './SearchParamsProvider';
import { getFiltersForNewUsed } from './SearchResultsProvider';
var isUsedAggregationValueMap = {
    0: 'New',
    1: 'Used',
};
var inOptionAggregationValueMap = {
    0: 'Available',
    1: 'Reserved',
};
var SearchAggregationsContext = createContext(undefined);
export var SearchAggregationsProvider = function (_a) {
    var url = _a.url, _b = _a.showNew, showNew = _b === void 0 ? false : _b, _c = _a.showDemo, showDemo = _c === void 0 ? false : _c, _d = _a.showUsed, showUsed = _d === void 0 ? false : _d, _e = _a.isWebsite, isWebsite = _e === void 0 ? true : _e, _f = _a.facetsAggregations, facetsAggregations = _f === void 0 ? [] : _f, otherProps = __rest(_a, ["url", "showNew", "showDemo", "showUsed", "isWebsite", "facetsAggregations"]);
    var params = useSearchParams().params;
    var _g = __read(useState(Status.Idle), 2), status = _g[0], setStatus = _g[1];
    var _h = __read(useState(), 2), aggregations = _h[0], setAggregations = _h[1];
    var _j = __read(useState([]), 2), preSelectedAggregations = _j[0], setPreSelectedAggregations = _j[1];
    var filteredAggregations = useMemo(function () {
        if (!aggregations)
            return null;
        if (isEmpty(preSelectedAggregations))
            return aggregations;
        return pick(aggregations, Object.keys(aggregations).filter(function (aggField) { return !preSelectedAggregations.includes(aggField); }));
    }, [aggregations, preSelectedAggregations]);
    var updateAggregations = useCallback(function () {
        var newFilters = params.filters.filters;
        var myVehiclesFilter = {
            filters: [
                {
                    data: params[OPTION_SOLD_BY_PARAM] || '',
                    field: 'optionBy.entityId',
                    operator: FilterOperatorTypes.is,
                },
                {
                    data: params[OPTION_SOLD_BY_PARAM] || '',
                    field: 'soldBy.entityId',
                    operator: FilterOperatorTypes.is,
                },
            ],
            condition: ConditionType.or,
        };
        var filters = params[OPTION_SOLD_BY_PARAM]
            ? [getFiltersForNewUsed(showNew, showDemo, showUsed), myVehiclesFilter]
            : [getFiltersForNewUsed(showNew, showDemo, showUsed)];
        var aggRequest = {
            query: {
                filters: filters,
                condition: ConditionType.and,
            },
            facetsConditionQuery: {
                filters: newFilters,
                condition: ConditionType.and,
            },
            fullText: params.textQuery,
            aggregationLimit: params.aggregationLimit,
            facetsAggregations: facetsAggregations,
        };
        setStatus(Status.Loading);
        var _a = hyperfetch(url, {
            method: 'POST',
            body: json(aggRequest),
        }), promise = _a.promise, abort = _a.abort;
        promise
            .then(function (_a) {
            var data = _a.data, error = _a.error;
            if (error != null || data == null) {
                setStatus(Status.Error);
            }
            else {
                if (isArray(data.isUsed)) {
                    data.isUsed.map(function (aggregation) {
                        aggregation.value = isUsedAggregationValueMap[aggregation.value];
                        // translate
                        return aggregation;
                    });
                }
                if (isArray(data.inOption)) {
                    data.inOption.map(function (aggregation) {
                        aggregation.value = inOptionAggregationValueMap[aggregation.value];
                        // translate
                        return aggregation;
                    });
                }
                setAggregations(data);
                setStatus(Status.Success);
            }
        })
            .catch(function (e) {
            console.log(e);
        });
        return function () {
            abort();
        };
    }, [params.textQuery, params.filters, url, showNew, showDemo, showUsed]);
    useEffect(updateAggregations, [updateAggregations]);
    var value = useMemo(function () { return ({
        status: status,
        aggregations: aggregations,
        filteredAggregations: filteredAggregations,
        preSelectedAggregations: preSelectedAggregations,
        setPreSelectedAggregations: setPreSelectedAggregations,
        updateAggregations: updateAggregations,
    }); }, [status, aggregations, filteredAggregations, preSelectedAggregations]);
    return React.createElement(SearchAggregationsContext.Provider, __assign({ value: value }, otherProps));
};
export var useSearchAggregations = function () {
    var value = useContext(SearchAggregationsContext);
    if (value === undefined) {
        throw new Error('useSearchAggregations must be used inside a SearchAggregationsProvider');
    }
    return value;
};
