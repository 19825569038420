import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import { head } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const ScKey = styled.td`
  vertical-align: top; /* Not middle so that it looks good with the multi-line comments field */
  height: 25px;
`;
const ScValue = styled.td`
  vertical-align: top;
  height: 25px;
  padding-left: 35px;
  font-weight: 600;
`;

const dateTimeFormat = 'DD-MM-YYYY HH:mm';

const renderMultiLineText = (value: string) => {
  const lines = value.split('\n');
  return (
    <span title={value}>
      {lines.length > 1
        ? lines.map((item, index) => (
            <span key={index}>
              {item}
              {index < lines.length - 1 && <br />}
            </span>
          ))
        : head(lines) || '\u00a0'}
    </span>
  );
};

const VehicleOrderInfo = ({ vehicle, className }: { vehicle: Vehicle; className?: string }) => {
  const { t } = useTranslation();

  return (
    <table className={className}>
      <tbody>
        <tr>
          <ScKey>{t('VEHICLE__ORDER_ID')}</ScKey>
          <ScValue>{vehicle.orderId}</ScValue>
        </tr>
        {!!vehicle.orderCreatedAt && (
          <tr>
            <ScKey>{t('VEHICLE__ORDER_CREATED_AT')}</ScKey>
            <ScValue>{moment(vehicle.orderCreatedAt).format(dateTimeFormat)}</ScValue>
          </tr>
        )}
        {!!vehicle.lastModificationAt && (
          <tr>
            <ScKey>{t('VEHICLE__ORDER_MODIFICATION_DATE')}</ScKey>
            <ScValue>{moment(vehicle.lastModificationAt).format(dateTimeFormat)}</ScValue>
          </tr>
        )}
        {!!vehicle.announcedDeliveryAt && (
          <tr>
            <ScKey>{t('VEHICLE__ORDER_ANNOUNCED_DELIVERY')}</ScKey>
            <ScValue>{moment(vehicle.announcedDeliveryAt).format(dateTimeFormat)}</ScValue>
          </tr>
        )}
        {!!vehicle.purchaseInvoiceDate && (
          <tr>
            <ScKey>{t('VEHICLE__PURCHASE_INVOICE_DATE')}</ScKey>
            <ScValue>{moment(vehicle.purchaseInvoiceDate).format(dateTimeFormat)}</ScValue>
          </tr>
        )}
        {!!vehicle.stockLocation && (
          <tr>
            <ScKey>{t('VEHICLE__STOCK_LOCATION')}</ScKey>
            <ScValue>{vehicle.stockLocation.title}</ScValue>
          </tr>
        )}
        {!!vehicle.comments && (
          <tr>
            <ScKey>{t('VEHICLE__COMMENTS')}</ScKey>
            <ScValue>{renderMultiLineText(vehicle.comments)}</ScValue>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default VehicleOrderInfo;
