import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { BREAKPOINTS } from '../../utils/constants';
import { getNumberDisplay } from '../../utils/cms';
import VehicleStatusLabel from './VehicleStatusLabel';
import { splitVinCode } from '@hypercharge/digitaldealer-commons/lib/utils/helpers';
import { KILOMETRAGE_PROPERTY_ID } from '@hypercharge/digitaldealer-commons/lib/constants';

const ScContainer = styled.div`
  display: flex;

  @media (max-width: ${BREAKPOINTS.sm}px) {
    flex-direction: column;
  }
`;
const ScInfoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`;
const ScInfo = styled.span`
  white-space: nowrap;
  font-size: 0.75rem;
  & + & {
    margin-left: 15px;
  }
`;
const ScInfoTitle = styled.span`
  color: #757575;
`;
const ScInfoValue = styled.span`
  font-weight: 600;
`;
const ScVIN = styled.code``;
const ScVIN8 = styled.span`
  padding: 0 1px;
  margin: 0 1px;
  border: 1px solid #ddd;
`;

const VehicleTopInfo = ({ vehicle, className }: { vehicle: Vehicle; className?: string }) => {
  const { t } = useTranslation();

  // since this is the browse page, showing the time doesn't seem that important
  const deliveryDateString =
    vehicle.announcedDeliveryAt != null
      ? moment(vehicle.announcedDeliveryAt).format('DD-MM-YYYY')
      : undefined;

  // client wants the last 8 characters highlighted
  const vinParts: string[] | [] = splitVinCode(vehicle.vin);

  return (
    <ScContainer className={className}>
      <div>
        <VehicleStatusLabel vehicle={vehicle} />
      </div>
      <ScInfoContainer>
        <ScInfo>
          <ScInfoTitle>{t('VEHICLE__ORDER_ID')} </ScInfoTitle>
          <ScInfoValue>{vehicle.orderId}</ScInfoValue>
        </ScInfo>
        {vehicle.announcedDeliveryAt != null ? (
          <ScInfo>
            <ScInfoTitle>{t('VEHICLE__ORDER_DELIVERY')} </ScInfoTitle>
            <ScInfoValue>{deliveryDateString}</ScInfoValue>
          </ScInfo>
        ) : null}
        {!!vinParts.length ? (
          <ScInfo>
            <ScInfoTitle>{t('VEHICLE__VIN')} </ScInfoTitle>
            <ScInfoValue>
              <ScVIN>
                {vinParts[0]}
                {Boolean(vinParts[1]) && <ScVIN8>{vinParts[1]}</ScVIN8>}
              </ScVIN>
            </ScInfoValue>
          </ScInfo>
        ) : null}
        {!!vehicle && !!vehicle[KILOMETRAGE_PROPERTY_ID] && (
          <ScInfo>
            <ScInfoTitle>km </ScInfoTitle>
            <ScInfoValue>
              {getNumberDisplay(
                vehicle[KILOMETRAGE_PROPERTY_ID],
                {
                  precision: 0,
                  units: { en: '' },
                  useGrouping: true,
                },
                'en'
              )}
            </ScInfoValue>
          </ScInfo>
        )}
      </ScInfoContainer>
    </ScContainer>
  );
};

export default VehicleTopInfo;
