import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import { Button, Dropdown, Menu, message, Modal, Tooltip } from 'antd';
import { Icon } from '@ant-design/compatible';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useAuth } from '../../context/auth/AuthProvider';
import {
  deleteVehicleReservation,
  deleteVehicleSale,
  sellVehicle,
  updateVehicleReservation
} from '../../utils/vehicle';
import ReservationModal, { OnOkPropsT } from './ReservationModal';
import SaleModal from './SaleModal';
import { Link } from 'react-router-dom';
import { VehicleReservation } from '@hypercharge/digitaldealer-commons/lib/types/common';

const { confirm } = Modal;

type Props = {
  buttonsSmallView?: boolean;
  className?: string;
  updateAggregations?: () => void;
  getResultAfterSell?: () => void;
  onChangeReservation?: (reservation: VehicleReservation | null) => void;
  setVehicle: (newVehicle: Vehicle) => void;
  vehicle: Vehicle;
  isSmall?: boolean;
  hideInfo?: boolean;
};

const ScContainer = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto;
`;
const ScButtons = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: min-content;
  grid-auto-flow: column;
  justify-self: end;
`;
const ScSmallButton = styled(Button)`
  transition: width 150ms ease !important;
  width: 46px;
`;

const ScMoreIcon = styled(Icon)`
  font-size: 2em;
`;

const ScDropdownContainer = styled.div`
  text-align: center;
`;

const ScSmallDeleteButton = styled(ScSmallButton as any)`
  :hover,
  :focus {
    color: #ff4d4f !important;
    border-color: #ff4d4f !important;
  }
`;

const VehicleActions = ({
  buttonsSmallView = false,
  className,
  getResultAfterSell,
  updateAggregations,
  onChangeReservation,
  setVehicle,
  hideInfo,
  vehicle,
  isSmall
}: Props) => {
  const { t } = useTranslation();
  const { authData } = useAuth();
  const [isReservationModalVisible, setIsReserveModalVisible] = useState<boolean>(false);
  const [isSaleModalVisible, setIsSaleModalVisible] = useState<boolean>(false);

  const vehicleIsReserved: boolean = vehicle.status && vehicle.status.code === 'reserved';
  const vehicleIsSold: boolean = vehicle.status && vehicle.status.code === 'sold';
  const userSoldVehicle: boolean =
    !!authData && !!vehicle.soldBy && vehicle.soldBy.entityId === authData.userId;
  const userReservedVehicle: boolean =
    !!authData && !!vehicle.optionBy && vehicle.optionBy.entityId === authData.userId;

  const handleDeleteSale = () => {
    confirm({
      icon: <Icon type="close" style={{ color: '#ff4d4f' }} />,
      title: t('VEHICLE__DELETE_SALE_FORM_TITLE'),
      cancelText: t('FORM__CANCEL'),
      okText: t('FORM__DELETE'),
      okType: 'danger',
      onOk: async () => {
        try {
          const newVehicle = await deleteVehicleSale(vehicle.entityId);
          message.success(t('MESSAGE__SUCCESS'));
          setVehicle(newVehicle);
        } catch {
          message.error(t('MESSAGE__ERROR'));
        }
      },
      onCancel: () => {}
    });
  };

  const handleDeleteReservation = () => {
    confirm({
      icon: <Icon type="close" style={{ color: '#ff4d4f' }} />,
      title: t('VEHICLE__DELETE_OPTION_FORM_TITLE'),
      cancelText: t('FORM__CANCEL'),
      okText: t('FORM__DELETE'),
      okType: 'danger',
      onOk: async () => {
        try {
          const { vehicle: newVehicle, reservation } = await deleteVehicleReservation(
            vehicle.entityId
          );
          message.success(t('MESSAGE__SUCCESS'));
          setVehicle(newVehicle);
          if (onChangeReservation) {
            onChangeReservation(reservation || null);
          }

          if (updateAggregations) {
            setTimeout(updateAggregations, 1000);
          }
        } catch {
          message.error(t('MESSAGE__ERROR'));
        }
      },
      onCancel: () => {}
    });
  };

  const toggleReservationModal = () => {
    setIsReserveModalVisible(v => !v);
  };

  const toggleSaleModal = () => {
    setIsSaleModalVisible(v => !v);
  };

  const handleReservation = async ({
    endDate,
    clientEmail,
    comment,
    clientLanguage
  }: OnOkPropsT) => {
    try {
      const { vehicle: newVehicle, reservation } = await updateVehicleReservation(
        vehicle.entityId,
        endDate,
        clientEmail,
        comment,
        clientLanguage
      );
      toggleReservationModal();
      message.success(t('MESSAGE__SUCCESS'));
      setVehicle(newVehicle);
      if (onChangeReservation) {
        onChangeReservation(reservation || null);
      }
      if (updateAggregations) {
        setTimeout(updateAggregations, 1000);
      }
    } catch {
      message.error(t('MESSAGE__ERROR'));
    }
  };

  const handleSale = async () => {
    try {
      if (vehicle.optionBy) {
        // Remove existing reservation
        await deleteVehicleReservation(vehicle.entityId);
      }

      const newVehicle: Vehicle = await sellVehicle(vehicle.entityId);

      toggleSaleModal();

      setVehicle(newVehicle);

      message.success(t('MESSAGE__SUCCESS'));
    } catch {
      message.error(t('MESSAGE__ERROR'));
    }
  };

  const TableActionButtons = () => (
    <ScButtons>
      {!vehicleIsSold && !vehicleIsReserved ? (
        <Tooltip placement="bottom" title={t('VEHICLE__TAKE_OPTION')}>
          <ScSmallButton onClick={toggleReservationModal}>
            <Icon theme="twoTone" type="calendar" />
          </ScSmallButton>
        </Tooltip>
      ) : null}

      {!vehicleIsSold && vehicleIsReserved && userReservedVehicle ? (
        <Tooltip placement="bottom" title={t('VEHICLE__DELETE_OPTION')}>
          <ScSmallDeleteButton onClick={handleDeleteReservation}>
            <Icon
              type="close"
              style={{
                color: '#ff4d4f'
              }}
            />
          </ScSmallDeleteButton>
        </Tooltip>
      ) : null}

      {vehicleIsSold && userSoldVehicle ? (
        <Tooltip placement="bottom" title={t('VEHICLE__DELETE_SALE')}>
          <ScSmallDeleteButton onClick={handleDeleteSale}>
            <Icon
              type="close"
              style={{
                color: '#ff4d4f'
              }}
            />
          </ScSmallDeleteButton>
        </Tooltip>
      ) : null}

      {(!vehicleIsSold && !vehicleIsReserved) ||
      (!vehicleIsSold && (vehicleIsReserved && userReservedVehicle)) ? (
        <Tooltip placement="bottom" title={t('VEHICLE__MARK_SOLD')}>
          <ScSmallButton onClick={toggleSaleModal}>
            <Icon theme="twoTone" twoToneColor="#fba400" type="trophy" />
          </ScSmallButton>
        </Tooltip>
      ) : null}

      {!hideInfo ? (
        <Tooltip placement="bottom" title={t('VEHICLE_ROW__DETAILS')}>
          <ScSmallButton>
            <Link to={`/vehicle/${vehicle.entityId}`}>
              <Icon theme="twoTone" type="eye" />
            </Link>
          </ScSmallButton>
        </Tooltip>
      ) : null}
    </ScButtons>
  );

  const ScMenuItem = styled(Menu.Item)`
    line-height: 1.3em !important;

    .anticon {
      display: inline-block;
      margin: 0.5em;
    }

    .text {
      display: inline-block;
      margin: 0.5em;
    }
  `;

  const DropdownActionButtons = (
    <Menu>
      {!vehicleIsSold && !vehicleIsReserved ? (
        <ScMenuItem onClick={toggleReservationModal}>
          <Icon theme="twoTone" type="calendar" />
          <span className={'text'}>{t('VEHICLE__TAKE_OPTION')}</span>
        </ScMenuItem>
      ) : null}

      {!vehicleIsSold && vehicleIsReserved && userReservedVehicle ? (
        <ScMenuItem onClick={handleDeleteReservation}>
          <Icon
            type="close"
            style={{
              color: '#ff4d4f'
            }}
          />
          <span className={'text'}>{t('VEHICLE__DELETE_OPTION')}</span>
        </ScMenuItem>
      ) : null}

      {vehicleIsSold && userSoldVehicle ? (
        <ScMenuItem onClick={handleDeleteSale}>
          <Icon
            type="close"
            style={{
              color: '#ff4d4f'
            }}
          />
          <span className={'text'}>{t('VEHICLE__DELETE_SALE')}</span>
        </ScMenuItem>
      ) : null}

      {(!vehicleIsSold && !vehicleIsReserved) ||
      (!vehicleIsSold && (vehicleIsReserved && userReservedVehicle)) ? (
        <ScMenuItem onClick={toggleSaleModal}>
          <Icon theme="twoTone" twoToneColor="#fba400" type="trophy" />
          <span className={'text'}>{t('VEHICLE__MARK_SOLD')}</span>
        </ScMenuItem>
      ) : null}

      <ScMenuItem>
        <Link to={`/vehicle/${vehicle.entityId}`}>
          <Icon theme="twoTone" type="eye" />
          <span className={'text'}>{t('VEHICLE_ROW__DETAILS')}</span>
        </Link>
      </ScMenuItem>
    </Menu>
  );

  return (
    <div className={className}>
      {!isSmall ? (
        <ScContainer>
          <TableActionButtons />
        </ScContainer>
      ) : (
        <ScDropdownContainer>
          <Dropdown overlay={DropdownActionButtons} placement="bottomRight">
            <ScMoreIcon theme="outlined" type="more" />
          </Dropdown>
        </ScDropdownContainer>
      )}

      {isReservationModalVisible ? (
        <ReservationModal
          visible={isReservationModalVisible}
          onOk={handleReservation}
          onCancel={toggleReservationModal}
        />
      ) : null}

      {isSaleModalVisible ? (
        <SaleModal visible={isSaleModalVisible} onOk={handleSale} onCancel={toggleSaleModal} />
      ) : null}
    </div>
  );
};

export default VehicleActions;
