import {
  AggregationType,
  BucketAggregationResult,
  StatsAggregationResult,
} from '@hypercharge/portal-lambda-sdk/lib/types';

export type DisplayAggOption = {
  selected?: boolean;
  id: string;
  title: string;
  count: number;
};

export type DisplayAgg = TermDisplayAgg | RangeDisplayAgg;

export type TermDisplayAgg = {
  field: string;
  title: string;
  options: DisplayAggOption[];
  type?: string;
  displayFormatter?: (value: number, language: string) => string;
};

export const isRange = (value: any): value is AggregationRange => {
  return typeof value === 'object' && ('start' in value || 'end' in value);
};

export const isStatsAggregationResult = (value: any): value is StatsAggregationResult => {
  return typeof value === 'object' && 'min' in value && 'max' in value && 'count' in value;
};

export const isBucketAggregationResultArray = (value: any): value is BucketAggregationResult[] => {
  const isArray = Array.isArray(value);
  return (
    isArray &&
    (!value.length || value.every((item) => 'value' in item && 'label' in item && 'count' in item))
  );
};

export type AggregationRange = {
  start?: number;
  end?: number;
};

export type RangeDisplayAgg = {
  field: string;
  title: string;
  min: number;
  max: number;
  step?: number;
  value: AggregationRange;
  type: AggregationType.range | AggregationType.stats;
  displayFormatter?: (value: number, language: string) => string;
  options: DisplayAggOption[];
};
