import { Layout } from 'antd';
import { toUpper } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import styled from 'styled-components/macro';
import ErrorBoundary from '../components/ErrorBoundary';
import { UnauthenticatedAppProviders } from '../context';
import { useAuth } from '../context/auth/AuthProvider';
import { useTenant } from '../context/tenant/TenantProvider';
import EmailCodeForm from '../login/EmailCodeForm';
import EmailLoginForm from '../login/EmailLoginForm';
import Footer from './Footer';

const { Content } = Layout;

const ScLayout = styled(Layout)`
  min-height: 100vh;
`;

const UnauthenticatedApp = ({ location }: RouteComponentProps<any>) => {
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { tenant } = useTenant();
  const { from } = (location.state as any) || { from: { pathname: '/' } };

  return !isAuthenticated ? (
    <UnauthenticatedAppProviders>
      <ScLayout>
        <Helmet title={`${toUpper(tenant.id)} ${t('DIGITAL_DEALER')}: ${t('AUTH__SIGN_IN')}`} />

        <Content>
          <ErrorBoundary>
            <Switch>
              <Route path="/login/code" component={EmailCodeForm} />
              <Route path="/login" component={EmailLoginForm} />
              <Redirect to="/login" />
            </Switch>
          </ErrorBoundary>
        </Content>

        <Footer />
      </ScLayout>
    </UnauthenticatedAppProviders>
  ) : (
    <Redirect to={from} />
  );
};

export default UnauthenticatedApp;
