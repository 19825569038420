import { Layout } from 'antd';
import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Loading } from '../components/Loading';
import '../context/i18n'; // import i18n just so that it gets bundled
import ErrorPage from './ErrorPage';
import Footer from './Footer';

const { Content } = Layout;

const ScLayout = styled(Layout)`
  min-height: 100vh;
`;

const UnsupportedBrowser = () => {
  const { t } = useTranslation();
  return (
    <ScLayout>
      <Helmet title={t('DIGITAL_DEALER')} />
      <Content>
        <ErrorPage title={t('BROWSER_NOT_SUPPORTED__TITLE')} imageUrl="/images/notSupported.png">
          <>
            {t('BROWSER_NOT_SUPPORTED__TEXT')}{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/chrome/">
              Google Chrome
            </a>
            {t('BROWSER_NOT_SUPPORTED__OR')}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.mozilla.org/en-US/firefox/new/"
            >
              Firefox
            </a>
            .<div>{t('BROWSER_NOT_SUPPORTED__THANKS')}</div>
          </>
        </ErrorPage>
      </Content>
      <Footer />
    </ScLayout>
  );
};

const withSuspense = (Component: any) => () => {
  return (
    <Suspense fallback={<Loading />}>
      <Component />
    </Suspense>
  );
};

export default withSuspense(UnsupportedBrowser);
