import { __assign, __awaiter, __generator } from "tslib";
export var OtherErrors;
(function (OtherErrors) {
    OtherErrors[OtherErrors["FetchError"] = 0] = "FetchError";
})(OtherErrors || (OtherErrors = {}));
export var HttpErrors;
(function (HttpErrors) {
    HttpErrors[HttpErrors["ForbiddenRequestError"] = 0] = "ForbiddenRequestError";
    HttpErrors[HttpErrors["NotFoundRequestError"] = 1] = "NotFoundRequestError";
    HttpErrors[HttpErrors["UnknownRequestError"] = 2] = "UnknownRequestError";
    HttpErrors[HttpErrors["UnauthenticatedRequestError"] = 3] = "UnauthenticatedRequestError";
    HttpErrors[HttpErrors["InvalidRequestError"] = 4] = "InvalidRequestError";
})(HttpErrors || (HttpErrors = {}));
//
// Globals
//
var responseInterceptors = [];
var requestInterceptors = [];
export var registerResponseInterceptor = function (interceptor) {
    responseInterceptors.push(interceptor);
};
export var unregisterResponseInterceptor = function (interceptor) {
    var index = responseInterceptors.indexOf(interceptor);
    if (index >= 0) {
        responseInterceptors.splice(index, 1);
    }
};
export var registerRequestInterceptor = function (interceptor) {
    requestInterceptors.push(interceptor);
};
export var unregisterRequestInterceptor = function (interceptor) {
    var index = requestInterceptors.indexOf(interceptor);
    if (index >= 0) {
        requestInterceptors.splice(index, 1);
    }
};
var defaultHeaders = {
    'Content-Type': 'application/json; charset=utf-8',
};
export var setDefaultHeader = function (header, value) {
    defaultHeaders[header] = value;
};
export var clearDefaultHeader = function (header) {
    delete defaultHeaders[header];
};
export var json = function (body) {
    return new Blob([JSON.stringify(body)], { type: 'application/json' });
};
export var hyperfetch = function (url, options) {
    if (options === void 0) { options = {}; }
    // Cancel logic
    var controller = new AbortController();
    var headers = options ? __assign(__assign({}, defaultHeaders), options.headers) : defaultHeaders;
    var returnPromise = Promise.all(requestInterceptors.map(function (interceptor) { return interceptor(url, options); }))
        .then(function () {
        // do fetch
        var responsePromise = fetch(url, __assign(__assign({ credentials: 'same-origin' }, options), { headers: new Headers(headers), signal: controller.signal }));
        // Register response interceptors
        responseInterceptors.forEach(function (interceptor) {
            responsePromise = responsePromise.then(function (res) { return interceptor(res, options); });
        });
        return responsePromise;
    })
        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!(response.status < 300)) return [3 /*break*/, 4];
                    _b = {};
                    if (!(response.status === 200)) return [3 /*break*/, 2];
                    return [4 /*yield*/, response.json()];
                case 1:
                    _a = (_c.sent());
                    return [3 /*break*/, 3];
                case 2:
                    _a = undefined;
                    _c.label = 3;
                case 3: return [2 /*return*/, (_b.data = _a,
                        _b)];
                case 4:
                    switch (response.status) {
                        case 400:
                            return [2 /*return*/, {
                                    error: HttpErrors.InvalidRequestError,
                                    errorData: response.json(),
                                }];
                        case 401:
                            return [2 /*return*/, { error: HttpErrors.UnauthenticatedRequestError }];
                        case 403:
                            return [2 /*return*/, { error: HttpErrors.ForbiddenRequestError }];
                        case 404:
                            return [2 /*return*/, { error: HttpErrors.NotFoundRequestError }];
                        default:
                            return [2 /*return*/, { error: HttpErrors.UnknownRequestError }];
                    }
                    _c.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); })
        .catch(function (e) {
        if (e instanceof DOMException) {
            throw e;
        }
        return { error: OtherErrors.FetchError, errorData: e };
    });
    return {
        promise: returnPromise,
        abort: function () {
            controller.abort();
        },
    };
};
export var failOnNullOrError = function (_a) {
    var promise = _a.promise;
    return __awaiter(void 0, void 0, void 0, function () {
        var _b, data, error;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, promise];
                case 1:
                    _b = _c.sent(), data = _b.data, error = _b.error;
                    if (data != null && error == null) {
                        return [2 /*return*/, data];
                    }
                    else {
                        throw new Error('Failed request');
                    }
                    return [2 /*return*/];
            }
        });
    });
};
export var failOnError = function (_a) {
    var promise = _a.promise;
    return __awaiter(void 0, void 0, void 0, function () {
        var _b, data, error;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, promise];
                case 1:
                    _b = _c.sent(), data = _b.data, error = _b.error;
                    if (error) {
                        throw new Error('Failed request: ' + HttpErrors[error]);
                    }
                    return [2 /*return*/, data];
            }
        });
    });
};
