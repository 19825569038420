import { Breadcrumb as AntdBreadcrumb } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';

const Breadcrumb = ({ location }: RouteComponentProps) => {
  const { Item } = AntdBreadcrumb;
  const { t } = useTranslation();
  const [showBreadcrumb, setShowBreadcrumb] = useState(false);

  //TODO: This is dummy implementation of routing for this application.
  // If necessary, we should create a way to register the routes by application module.
  // In case of a small improvement, we should take a look at using the "match" effect.
  React.useEffect(() => {
    setShowBreadcrumb(location.pathname.startsWith('/vehicle'));
  }, [location]);

  return showBreadcrumb ? (
    <AntdBreadcrumb>
      <Item>
        <Link to="/">{t('HOME')}</Link>
      </Item>
      <Item>{t('VEHICLE__DETAILS')}</Item>
    </AntdBreadcrumb>
  ) : null;
};

const _Breadcrumb = withRouter(Breadcrumb);
export { _Breadcrumb as Breadcrumb };
