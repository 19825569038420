import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import VehicleStatusLabel from './VehicleStatusLabel';
import { VehicleReservation } from '@hypercharge/digitaldealer-commons/lib/types/common';

const ScKey = styled.td`
  vertical-align: middle;
  height: 25px;
`;
const ScValue = styled.td`
  vertical-align: middle;
  height: 25px;
  padding-left: 35px;
  font-weight: 600;
`;

const dateTimeFormat = 'DD-MM-YYYY HH:mm';

const VehicleDealInfo = ({
  vehicle,
  className,
  reservation
}: {
  vehicle: Vehicle;
  className?: string;
  reservation: VehicleReservation | null;
}) => {
  const { t } = useTranslation();
  return (
    <table className={className}>
      <tbody>
      <tr>
        <ScKey>{t('DEAL__STATUS')}</ScKey>
        <ScValue>
          <VehicleStatusLabel vehicle={vehicle} />
        </ScValue>
      </tr>
      {vehicle.soldBy == null || vehicle.status?.code !== 'sold' ? null : (
        <tr>
          <ScKey>{t('DEAL__SALESPERSON')}</ScKey>
          <ScValue>{vehicle.soldBy.title}</ScValue>
        </tr>
      )}
      {vehicle.saleCcf == null ? null : (
        <tr>
          <ScKey>{t('DEAL__SALE_CCF')}</ScKey>
          <ScValue>{vehicle.saleCcf}</ScValue>
        </tr>
      )}

      {!!vehicle.saleDate && vehicle.status?.code === 'sold' && (
        <tr>
          <ScKey>{t('DEAL__SALE_DATE')}</ScKey>
          <ScValue>{moment(vehicle.saleDate).format(dateTimeFormat)}</ScValue>
        </tr>
      )}
      {vehicle.optionBy == null ? null : (
        <tr>
          <ScKey>{t('DEAL__SALESPERSON')}</ScKey>
          <ScValue>{vehicle.optionBy.title}</ScValue>
        </tr>
      )}
      {reservation?.comment && vehicle.status?.code === 'reserved' && (
        <tr>
          <ScKey>{t('FORM__COMMENT')}</ScKey>
          <ScValue>{reservation.comment}</ScValue>
        </tr>
      )}
      {!!vehicle.optionStartDate && (
        <tr>
          <ScKey>{t('DEAL__RESERVE_START_DATE')}</ScKey>
          <ScValue>{moment(vehicle.optionStartDate).format(dateTimeFormat)}</ScValue>
        </tr>
      )}
      {!!vehicle.optionEndDate && (
        <tr>
          <ScKey>{t('DEAL__RESERVE_END_DATE')}</ScKey>
          <ScValue>{moment(vehicle.optionEndDate).format(dateTimeFormat)}</ScValue>
        </tr>
      )}
      </tbody>
    </table>
  );
};

export default VehicleDealInfo;
