import { __assign } from "tslib";
import { ConditionType } from '@hypercharge/portal-lambda-sdk/lib/types';
import { ALL_RESPONSE_FIELDS_WILDCARD } from '@hypercharge/lambda-utils/lib/search/searchUtils';
import { DEFAULT_LANGUAGE } from '../constants';
var requestTemplate = {
    languageCode: DEFAULT_LANGUAGE,
    query: {
        condition: ConditionType.and,
        filters: [],
    },
    limit: 500,
    offset: 0,
    fullTextFields: [],
    fullText: '',
    responseFields: [ALL_RESPONSE_FIELDS_WILDCARD],
    sortBy: [],
};
function getDefaultFilterRequest() {
    return __assign({}, requestTemplate);
}
export { getDefaultFilterRequest };
