import { useEffect, useState } from 'react';
import {
  RESULTS_TABLE_VIEW_VALUE,
  RESULTS_VIEW_PARAM
} from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchParamsProvider';
import { ResultViewType } from '@hypercharge/digitaldealer-commons/src/types/common';

type isWideViewHookType = {
  resultView: ResultViewType;
  isWideView: boolean;
};

export function useIsWideView(): isWideViewHookType {
  // TODO: Rewrite this `window location` to router hooks, when dependency will updated to last version
  const resultViewFromParams = new URLSearchParams(window.location.search).get(
    RESULTS_VIEW_PARAM
  ) as ResultViewType | null;
  const [resultView, setResultView] = useState<ResultViewType>(resultViewFromParams || 'card');

  useEffect(() => {
    if (resultViewFromParams) {
      setResultView(resultViewFromParams);
    }
  }, [resultViewFromParams]);

  return {
    resultView,
    isWideView: resultView === RESULTS_TABLE_VIEW_VALUE
  };
}
