import { Modal } from 'antd';
import moment, { Moment } from 'moment';
import Calendar from 'rc-calendar';
import 'rc-calendar/assets/index.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useTenant } from '../../context/tenant/TenantProvider';
import * as Yup from 'yup';
import { Dropdown } from '../../components/Dropdown';

const ScContainer = styled.div`
  text-align: center;
`;
const ScCalendar = styled(Calendar)`
  border: none !important;
  box-shadow: none !important;
  margin: 0 auto;
`;
const ScIncorrectText = styled.div`
  color: red;
`;

const ScInput = styled.input`
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 9px;
  flex-basis: 100%;
  width: 50%;
}
`;

const ScInputSet = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  max-width: 100%;
`;

const MINIMUM_RESERVATION_DAYS = 1;
const MAXIMUM_RESERVATION_DAYS = 2;

const getMilliseconds = (value: Moment): number => value.utc().valueOf();

export type OnOkPropsT = {
  endDate: number;
  clientEmail?: string;
  comment?: string;
  clientLanguage?: string;
};

const ReserveModal = ({
  visible,
  onOk,
  onCancel
}: {
  visible: boolean;
  onOk: (p: OnOkPropsT) => Promise<void>;
  onCancel: () => void;
}) => {
  const unmounted = useRef(false);
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const { tenant } = useTenant();
  let reservationDuration = MAXIMUM_RESERVATION_DAYS;
  if (tenant && tenant.intranet && tenant.intranet.reservationDuration) {
    reservationDuration = tenant.intranet.reservationDuration;
  }

  const {
    t,
    i18n: { language }
  } = useTranslation();
  const [endDate, setEndDate] = useState<number>(
    getMilliseconds(moment().add(reservationDuration, 'days'))
  );

  const [clientLanguage, setClientLanguage] = useState(language);
  const [clientEmail, setClientEmail] = useState<string | undefined>(undefined);
  const [comment, setComment] = useState<string | undefined>(undefined);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const isDateDisabled = (value: Moment | undefined): boolean => {
    if (value) {
      const firstAllowedEpoch = moment()
        .startOf('day')
        .add(MINIMUM_RESERVATION_DAYS, 'days')
        .valueOf();
      const lastAllowedEpoch = moment()
        .endOf('day')
        .add(reservationDuration, 'days')
        .valueOf();
      return (
        // Disabled before today
        value.valueOf() < firstAllowedEpoch ||
        // Disabled after the maximum number of reservation days
        value.valueOf() > lastAllowedEpoch
      );
    }
    return false;
  };
  const handleSelect = (m: Moment) => setEndDate(getMilliseconds(m));
  const handleOk = async () => {
    if (!isEmailValid && !!clientEmail) {
      return;
    }
    setSubmitting(true);
    await onOk({ endDate, clientEmail, comment, clientLanguage });
    if (!unmounted.current) {
      setSubmitting(false);
    }
  };

  const formSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email()
          .required()
      }),
    []
  );

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const email = e.target.value;
      formSchema.isValid({ email }).then(isValid => {
        setClientEmail(email);
        setIsEmailValid(isValid);
      });
    },
    [formSchema]
  );

  return (
    <Modal
      confirmLoading={submitting}
      destroyOnClose={true}
      onCancel={onCancel}
      onOk={handleOk}
      okText={t('VEHICLE__TAKE_OPTION')}
      title={t('VEHICLE__RESERVE_FORM_TITLE')}
      visible={visible}
    >
      <ScContainer>
        {t('VEHICLE__OPTION_END_DATE')}
        <ScCalendar
          defaultValue={moment(endDate)}
          showDateInput={false}
          onSelect={handleSelect}
          disabledDate={isDateDisabled}
        />

        <ScInputSet>
          <ScInput
            type="email"
            style={{
              outlineColor: !isEmailValid && !!clientEmail ? 'red' : '',
              marginRight: '9px'
            }}
            placeholder={t('FORM__EMAIL_CLIENT')}
            onChange={handleEmailChange}
          />

          <Dropdown
            value={clientLanguage}
            options={tenant.availableLanguages.map(l => ({ label: l.toUpperCase(), value: l }))}
            onChange={l => {
              setClientLanguage(l.target.value);
            }}
          />
        </ScInputSet>
        {!isEmailValid && !!clientEmail && (
          <ScIncorrectText>&#42;{t('FORM__INVALID_EMAIL')}</ScIncorrectText>
        )}

        <ScInputSet>
          <ScInput
            type="text"
            placeholder={t('FORM__COMMENT')}
            onChange={e => setComment(e.target.value)}
          />
        </ScInputSet>
      </ScContainer>
    </Modal>
  );
};

export default ReserveModal;
