import { Modal } from 'antd';
import { Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import * as Yup from 'yup';

export type SaleValues = {
  saleCcf: string;
};

const initialValues: SaleValues = {
  saleCcf: ''
};

const ScContainer = styled.div`
  text-align: center;
`;

const SaleModal = ({
  visible,
  onOk,
  onCancel
}: {
  visible: boolean;
  onOk: () => Promise<void>;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);

  const formSchema = useMemo(
    () =>
      Yup.object().shape({
        saleCcf: Yup.string() // TODO: enforce numbers
          .length(8, t('Should be 8 numbers')) // TODO translate
          .matches(/^\d+$/, 'Only numbers') // TODO translate
          .required(t('FORM__REQUIRED'))
      }),
    [t]
  );

  const handleOk = () => {
    setSubmitting(true);
    onOk().finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleOk}
      validateOnBlur={true}
      validateOnChange={false}
      validationSchema={formSchema}
    >
      <Modal
        confirmLoading={submitting}
        destroyOnClose={true}
        onCancel={onCancel}
        onOk={handleOk}
        cancelText={t('FORM__CANCEL')}
        okText={t('VEHICLE__MARK_SOLD')}
        title={t('VEHICLE__SELL_FORM_TITLE')}
        visible={visible}
      >
        <ScContainer>{t('VEHICLE__SELL_FORM_TEXT')}</ScContainer>
      </Modal>
    </Formik>
  );
};

export default SaleModal;
