import { __assign, __read, __rest, __spreadArray, __values } from "tslib";
import { AggregationType, ConditionType, } from '@hypercharge/portal-lambda-sdk/lib/types';
import { set } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState, } from 'react';
import { FilterOperatorTypes, PAGE_SIZE, RELEVANCE_SORT_KEY, AGGREGATION_LIMIT, } from '../../constants';
import { useQuery } from '../router/UrlQueryProvider';
export var RESULTS_VIEW_PARAM = 'results-view';
export var RESULTS_CARD_VIEW_VALUE = 'card';
export var RESULTS_TABLE_VIEW_VALUE = 'table';
export var PAGE_NUMBER_PARAM = 'page';
export var PAGE_SIZE_PARAM = 'pageSize';
export var AGGREGATION_LIMIT_PARAM = 'aggLimit';
export var TEXT_PARAM = 'q';
export var SORT_PARAM = 's';
export var OPTION_SOLD_BY_PARAM = 'optionOrSoldBy';
var NON_AGG_PARAMS = [TEXT_PARAM, PAGE_NUMBER_PARAM, PAGE_SIZE_PARAM, SORT_PARAM];
export var TERM_FILTERS_PREFIX = 'ft';
export var RANGE_FILTERS_PREFIX = 'fr';
export var STATS_FILTERS_PREFIX = 'fs';
export var FILTERS_REGEX = new RegExp("^(" + TERM_FILTERS_PREFIX + "|" + RANGE_FILTERS_PREFIX + "|" + STATS_FILTERS_PREFIX + ")_(.+)$");
export var RANGE_REGEX = new RegExp("^(\\d+|\\*)-(\\d+|\\*)$");
export var SearchParamsContext = createContext(undefined);
export var SearchParamsProvider = function (_a) {
    var defaultSortKey = _a.defaultSortKey, defaultTableSortKey = _a.defaultTableSortKey, currentView = _a.currentView, otherProps = __rest(_a, ["defaultSortKey", "defaultTableSortKey", "currentView"]);
    var defSort = (currentView && currentView === 'table' ? defaultTableSortKey : defaultSortKey) || '';
    var _b = useQuery(), query = _b.query, setQueryParams = _b.setQueryParams, removeQueryParams = _b.removeQueryParams;
    var firstRun = useRef(true);
    var _c = __read(useState(function () { return getSearchParams(query, defSort); }), 2), params = _c[0], setParams = _c[1];
    var _d = __read(useState(function () { return getAggSearchParams(query); }), 2), aggParams = _d[0], setAggParams = _d[1];
    var _e = __read(useState({ card: defaultSortKey, table: defaultTableSortKey }), 2), curSort = _e[0], setCurSort = _e[1];
    var _f = __read(useState(defSort), 2), defaultSort = _f[0], setDefaultSort = _f[1];
    if (currentView) {
        useEffect(function () { return setDefaultSort((currentView === 'table' ? defaultTableSortKey : defaultSortKey) || ''); }, [currentView]);
        useEffect(function () { return setCurSort(function (s) {
            var _a;
            return (__assign(__assign({}, s), (_a = {}, _a[currentView] = query.s, _a)));
        }); }, [query.s]);
    }
    var setAggFilter = useCallback(function (field, value, type) {
        var _a;
        var param = getQueryParam(field, type);
        var parsedQuery = (_a = {}, _a[param] = [value.toString()], _a);
        var filterQuery = getSearchParams(parsedQuery, defaultSort || '');
        setParams(filterQuery);
        setAggParams(getAggSearchParams(parsedQuery));
    }, [defaultSort]);
    var setAggParam = useCallback(function (aggParam) {
        var queryParam = getQueryParam(aggParam.field, aggParam.type);
        var data = toQueryParamValue(aggParam);
        setQueryParams({ key: queryParam, value: data }, { key: PAGE_NUMBER_PARAM, value: '1' });
    }, [setQueryParams]);
    var setParam = useCallback(function (param, value) {
        var changes;
        if (!Array.isArray(param)) {
            changes = [{ key: param, value: value.toString() }];
        }
        else {
            changes = param;
        }
        // Note: Every time a search parameter changes, we must reset the page number
        // (unless it is the page number parameter that changed).
        if (param !== PAGE_NUMBER_PARAM && param !== RESULTS_VIEW_PARAM) {
            changes.push({
                key: PAGE_NUMBER_PARAM,
                value: '1',
            });
        }
        // If a new text query is given, make the sort be by relevance,
        // if after that the user specifies a different sort order, allow it
        if (param === TEXT_PARAM && value) {
            changes.push({
                key: SORT_PARAM,
                value: RELEVANCE_SORT_KEY,
            });
        }
        // If I clear the text query, it no longer makes sense to ask a sort by relevance
        if (param === TEXT_PARAM && !value && params.sortBy === RELEVANCE_SORT_KEY) {
            changes.push({
                key: SORT_PARAM,
                value: defaultSort,
            });
        }
        // if the user is not on the browse page when he decides to update a
        // search parameter, send her to the browse page first.
        // * Removed temporarily to make it compatible with current public website version - Gabriel 23/Sep/19
        // if (history.location.pathname !== '/') {
        //   history.push({ pathname: '/' });
        // }
        setQueryParams.apply(void 0, __spreadArray([], __read(changes)));
    }, [defaultSort, params.sortBy, setQueryParams]);
    var clearAll = useCallback(function () {
        removeQueryParams.apply(void 0, __spreadArray(__spreadArray([], __read(NON_AGG_PARAMS)), __read(getAggParams(query))));
    }, [query, removeQueryParams]);
    var clearAggParam = useCallback(function (field, type) {
        var queryParam = getQueryParam(field, type);
        removeQueryParams(queryParam, PAGE_NUMBER_PARAM);
    }, [removeQueryParams]);
    useEffect(function () {
        if (firstRun.current) {
            firstRun.current = false;
        }
        else {
            // TODO do a previous and current query diff, and based on the changed query params,
            // only compute the necessary properties in our params object, thus allowing downstream
            // components to avoid unecessary re-renders
            // - Ivo 30/Jul/19
            setParams(getSearchParams(query, defaultSort));
            setAggParams(getAggSearchParams(query));
        }
    }, [query, defaultSort]);
    var value = useMemo(function () { return ({
        aggParams: aggParams,
        clearAggParam: clearAggParam,
        clearAll: clearAll,
        params: params,
        setAggParam: setAggParam,
        setParam: setParam,
        setAggFilter: setAggFilter,
        curSort: curSort,
    }); }, [aggParams, clearAggParam, clearAll, params, setAggParam, setParam, setAggFilter, curSort]);
    return React.createElement(SearchParamsContext.Provider, __assign({ value: value }, otherProps));
};
export var useSearchParams = function () {
    var query = useContext(SearchParamsContext);
    if (query === undefined) {
        throw new Error('useSearchParams must be used inside a SearchParamsProvider');
    }
    return query;
};
//
//
// Utilities
//
//
var getAggParams = function (query) {
    return Object.keys(query).filter(function (p) { return FILTERS_REGEX.test(p); });
};
var getQueryParam = function (field, type) {
    switch (type) {
        case AggregationType.range:
            return RANGE_FILTERS_PREFIX + "_" + field;
        case AggregationType.stats:
            return STATS_FILTERS_PREFIX + "_" + field;
        case AggregationType.term:
            return TERM_FILTERS_PREFIX + "_" + field;
        default:
            return '';
    }
};
var toQueryParamValue = function (aggParam) {
    switch (aggParam.type) {
        case AggregationType.range:
        case AggregationType.stats:
            return aggParam.data.start ||
                aggParam.data.start === 0 ||
                aggParam.data.end ||
                aggParam.data.end === 0
                ? (aggParam.data.start || aggParam.data.start === 0 ? aggParam.data.start : '*') + "-" + (aggParam.data.end || aggParam.data.end === 0 ? aggParam.data.end : '*')
                : [];
        case AggregationType.term:
            return aggParam.data;
    }
    return '';
};
var fromQueryParamValue = function (field, type, data) {
    switch (type) {
        case AggregationType.range:
        case AggregationType.stats:
            var rangeMatch = data.match(RANGE_REGEX);
            if (rangeMatch == null || (rangeMatch[1] === '*' && rangeMatch[2] === '*')) {
                throw new Error('Invalid range parameter');
            }
            var _a = __read(parseRangeValue(data), 2), start = _a[0], end = _a[1];
            return {
                field: field,
                type: type,
                data: {
                    start: start,
                    end: end,
                },
            };
        case AggregationType.term:
            return {
                field: field,
                type: type,
                data: data,
            };
        default:
            throw new Error('Unknown aggregation type');
    }
};
var toAggType = function (prefix) {
    switch (prefix) {
        case RANGE_FILTERS_PREFIX:
            return AggregationType.range;
        case STATS_FILTERS_PREFIX:
            return AggregationType.stats;
        case TERM_FILTERS_PREFIX:
        default:
            return AggregationType.term;
    }
};
var getSearchParams = function (query, defaultSortKey) {
    var _a;
    return (_a = {},
        _a[OPTION_SOLD_BY_PARAM] = getOptionSoldBy(query),
        _a.page = getPageNumber(query),
        _a.textQuery = getTextQuery(query),
        _a.filters = getConditionQuery(query),
        _a.pageSize = getPageSize(query),
        _a.sortBy = getSort(query, defaultSortKey),
        _a.aggregationLimit = getAggregationLimit(query),
        _a);
};
var getOptionSoldBy = function (query) {
    return query[OPTION_SOLD_BY_PARAM];
};
var getPageSize = function (query) {
    return query[PAGE_SIZE_PARAM] ? Number(query[PAGE_SIZE_PARAM]) : PAGE_SIZE;
};
var getAggregationLimit = function (query) {
    return query[AGGREGATION_LIMIT_PARAM] ? Number(query[AGGREGATION_LIMIT_PARAM]) : AGGREGATION_LIMIT;
};
var getPageNumber = function (query) {
    return query[PAGE_NUMBER_PARAM] ? Number(query[PAGE_NUMBER_PARAM]) : 1;
};
var getTextQuery = function (query) {
    return query[TEXT_PARAM];
};
var getSort = function (query, defaultSortKey) {
    return query[SORT_PARAM] ? query[SORT_PARAM] : defaultSortKey;
};
var getAggSearchParams = function (query) {
    var e_1, _a;
    var params = [];
    try {
        for (var _b = __values(Object.entries(query)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = __read(_c.value, 2), param = _d[0], value = _d[1];
            if (value != null) {
                var match = param.match(FILTERS_REGEX);
                if (match != null) {
                    var prefix = match[1];
                    var field = match[2];
                    var aggType = toAggType(prefix);
                    var aggParam = fromQueryParamValue(field, aggType, value);
                    params.push(aggParam);
                }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return params;
};
var getConditionQuery = function (query) {
    var e_2, _a, e_3, _b, e_4, _c;
    var filtersByFieldOp = {};
    var _loop_1 = function (param, value) {
        var match = param.match(FILTERS_REGEX);
        if (match != null) {
            var filterType = match[1];
            var field_1 = match[2];
            switch (filterType) {
                case TERM_FILTERS_PREFIX:
                    var fieldFilters = value.map(function (v) {
                        return {
                            data: v,
                            field: field_1,
                            operator: FilterOperatorTypes.is,
                        };
                    });
                    set(filtersByFieldOp, [field_1, FilterOperatorTypes.is], fieldFilters);
                    break;
                case RANGE_FILTERS_PREFIX:
                case STATS_FILTERS_PREFIX:
                    var _l = __read(parseRangeValue(value), 2), start = _l[0], end = _l[1];
                    set(filtersByFieldOp, [field_1, FilterOperatorTypes.greaterThanOrEqual], [
                        {
                            operator: FilterOperatorTypes.between,
                            data: { start: start, end: end },
                            field: field_1,
                        },
                    ]);
                    break;
                default:
                // do nothing
            }
        }
    };
    try {
        for (var _d = __values(Object.entries(query)), _e = _d.next(); !_e.done; _e = _d.next()) {
            var _f = __read(_e.value, 2), param = _f[0], value = _f[1];
            _loop_1(param, value);
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
        }
        finally { if (e_2) throw e_2.error; }
    }
    var filters = [];
    try {
        for (var _g = __values(Object.values(filtersByFieldOp)), _h = _g.next(); !_h.done; _h = _g.next()) {
            var filtersByOp = _h.value;
            try {
                for (var _j = (e_4 = void 0, __values(Object.values(filtersByOp))), _k = _j.next(); !_k.done; _k = _j.next()) {
                    var fieldFilters = _k.value;
                    if (fieldFilters.length > 1) {
                        filters.push({
                            condition: ConditionType.or,
                            filters: fieldFilters,
                        });
                    }
                    else {
                        filters.push(fieldFilters[0]);
                    }
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_k && !_k.done && (_c = _j.return)) _c.call(_j);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return { condition: ConditionType.and, filters: filters };
};
var parseRangeValue = function (value) {
    var rangeMatch = value.match(RANGE_REGEX);
    if (rangeMatch == null || (rangeMatch[1] === '*' && rangeMatch[2] === '*')) {
        throw new Error('Invalid range parameter');
    }
    return [
        rangeMatch[1] === '*' ? undefined : Number(rangeMatch[1]),
        rangeMatch[2] === '*' ? undefined : Number(rangeMatch[2]),
    ];
};
