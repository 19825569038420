import { Layout } from 'antd';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { SearchAndActions, UserActions } from '../components/UserActions';
import { useTenant } from '../context/tenant/TenantProvider';
import { ScWrapper } from '../layout/styles';
import { getImageUrl } from '../utils/cms';
import { BREAKPOINTS } from '../utils/constants';
import { StyledAppSearch } from './AppSearch';

const { Header } = Layout;

const ScHeader = styled(Header)`
  background: #162133 !important;
  border-bottom: 1px solid lightgray;
  padding: 0 !important;
`;
const ScGrid = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 0 5px;
`;

const ScLogo = styled.div`
  cursor: pointer;
  overflow: hidden;
  margin-left: 5px;
  height: 64px;
  img {
    max-height: 40px;
  }
`;

const ScFlex = styled.div`
  display: flex;
`;

const Logo = () => {
  const { tenant } = useTenant();
  return (
    <ScLogo>
      <Link to="/">
        <img alt="logo" src={getImageUrl(tenant.id, tenant.logoImageId || '', { height: 40 })} />
      </Link>
    </ScLogo>
  );
};

const HeaderForLargeScreens = () => {
  return (
    <>
      <Logo />
      <SearchAndActions isSmall={false} />
    </>
  );
};

const HeaderForMediumAndSmallScreens = () => {
  const ScCenter = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
  `;
  return (
    <>
      <UserActions asDropdown={true} icon="menu" />
      <ScCenter>
        <Logo />
      </ScCenter>
    </>
  );
};

const AppHeader = ({ fullWidth }: { fullWidth: boolean }) => {
  const isLarge = useMediaQuery({ minWidth: BREAKPOINTS.lg });

  return (
    <>
      <ScHeader>
        <ScWrapper fullWidth={fullWidth}>
          <ScGrid>
            {isLarge ? <HeaderForLargeScreens /> : <HeaderForMediumAndSmallScreens />}
          </ScGrid>
        </ScWrapper>
      </ScHeader>

      {!isLarge && (
        <ScHeader>
          <ScFlex>
            <StyledAppSearch />
          </ScFlex>
        </ScHeader>
      )}
    </>
  );
};

export default AppHeader;
