import { RELEVANCE_SORT_KEY } from '@hypercharge/digitaldealer-commons/lib/constants';
import {
  RESULTS_CARD_VIEW_VALUE,
  RESULTS_TABLE_VIEW_VALUE,
  SORT_PARAM,
  useSearchParams
} from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchParamsProvider';
import { OptionT } from '@hypercharge/digitaldealer-commons/lib/types/common';
import React, { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Dropdown } from '../../components/Dropdown';
import { useSearchResults } from '../../context/search/results';
import { BREAKPOINTS, VISIBLE_SORT_OPTIONS } from '../../utils/constants';
import { Radio } from 'antd';
import { Icon } from '@ant-design/compatible';
import { Status } from '@hypercharge/digitaldealer-commons/lib/utils/types';
import { useIsWideView } from '../../hooks/is-wide-view.hook';
import { useQuery} from '@hypercharge/digitaldealer-commons/lib/providers/router/UrlQueryProvider';

const ScContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap-reverse;
  margin-bottom: 15px;

  @media (max-width: ${BREAKPOINTS.md}px) {
    flex-wrap: wrap;
  }
`;

const ScTotal = styled.div`
  width: 27%;
  color: #162133;
  white-space: nowrap;
  font-size: 1.1rem;

  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 50%;
  }
`;

const ScSort = styled.div`
  width: 46%;
  cursor: default;
  text-align: right;
  white-space: nowrap;

  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    margin-top: 10px;
  }

  span {
    margin-right: 5px;
  }
`;

const ScSwitcherWrap = styled.div`
  width: 27%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 50%;
    justify-content: flex-end;
  }

  .title {
    color: #464646;
    margin-right: 10px;
  }

  .buttons {
    display: flex;
    align-items: center;

    label {
      width: 40px;
      height: 40px;
      padding: 4px 12px;
    }
  }
`;

const ResultsHeader = ({ isWideView }) => {
  const { totalCount } = useSearchResults();
  const { t } = useTranslation();

  return (
    <ScContainer>
      <ScTotal>{t('BROWSE__RESULTS_HEADER', { count: totalCount || 0 })}</ScTotal>

      <ViewSwitcher />

      {!isWideView && (
        <ScSort>
          <span>{t('BROWSE__SORT_BY')}</span>
          <SortMenu />
        </ScSort>
      )}
    </ScContainer>
  );
};

const SortMenu = () => {
  const { t } = useTranslation();
  const { params } = useSearchParams();
  const { setParam } = useSearchParams();

  const handleMenuClick = (e: ChangeEvent<HTMLSelectElement>) => {
    setParam(SORT_PARAM, e.target.value);
  };

  const options: OptionT[] = useMemo(() => {
    const newOptions = VISIBLE_SORT_OPTIONS.map(sortKey => ({
      label: t(`BROWSE__SORT_BY_${sortKey}`),
      value: sortKey
    }));

    if (params.textQuery) {
      newOptions.push({
        label: t(`BROWSE__SORT_BY_${RELEVANCE_SORT_KEY}`),
        value: RELEVANCE_SORT_KEY
      });
    }

    return newOptions;
  }, [params.textQuery, t]);

  return <Dropdown value={params.sortBy} options={options} onChange={handleMenuClick} />;
};

const ViewSwitcher = () => {
  const { t } = useTranslation();
  const { setParam, curSort } = useSearchParams();
  const { query } = useQuery();
  const { setStatus } = useSearchResults();
  const { resultView } = useIsWideView();

  const onSwitch = ev => {
    // TODO: Re-write the view change with the method from the ʻis-wide-view` hook, when react-router will updated to the latest version

    const newView = ev.target.value;

    const sParams: any = [];

    if (query.s) {
      sParams.push({ key: SORT_PARAM, value: curSort[newView] })
    }

    setParam([
      ...sParams,
      { key: 'results-view', value: newView }
    ]);

    setStatus(Status.Loading);
  };

  return (
    <ScSwitcherWrap>
      <div className={'title'}>{t('BROWSE__RESULT_VIEW_TITLE')}</div>

      <div className={'buttons'}>
        <Radio.Group value={resultView} onChange={onSwitch}>
          <Radio.Button value={RESULTS_CARD_VIEW_VALUE}>
            <Icon type="pic-center" />
          </Radio.Button>

          <Radio.Button value={RESULTS_TABLE_VIEW_VALUE}>
            <Icon type="menu" />
          </Radio.Button>
        </Radio.Group>
      </div>
    </ScSwitcherWrap>
  );
};

export default ResultsHeader;
