import { __read } from "tslib";
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { PRICE_PRECISION, VIN_REGEX } from '../constants';
export var getFormattedPrice = function (price, locale) {
    if (!locale) {
        if (typeof window !== 'undefined') {
            locale = window.navigator.language || 'nl-BE';
        }
        else {
            locale = 'nl-BE';
        }
    }
    var options = {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: price % 1 === 0 ? undefined : PRICE_PRECISION,
    };
    return price.toLocaleString(locale, options);
};
export var uppercaseFirstChar = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export var parseRichTextToString = function (richText) {
    var options = {
        encodeHtml: false,
    };
    return !!richText ? new QuillDeltaToHtmlConverter(richText.ops, options).convert() : '';
};
export function splitVinCode(vin) {
    if (!vin) {
        return [];
    }
    var match = vin.match(VIN_REGEX);
    if (!match) {
        return [vin];
    }
    return [match[1], match[2]];
}
export var formatSort = function (val) {
    return val.split(',').map(function (sortVal) {
        var _a = __read(sortVal.split('-'), 2), field = _a[0], order = _a[1];
        return { field: field, order: order === 'ascend' ? 'ASC' : 'DESC' };
    });
};
export var getTranslatedPropertyName = function (name, language) {
    return "" + name + (language.charAt(0).toUpperCase() + language.slice(1));
};
// T === string | RichText
export var getTranslatedPropertyValue = function (obj, name, language) {
    var langPropName = getTranslatedPropertyName(name, language);
    var value = obj[langPropName];
    if (!value) {
        value = obj[getTranslatedPropertyName(name, 'en')];
    }
    if (!value) {
        value = obj[name];
    }
    return value;
};
export var conditionFilterSection = {
    entityId: 'conditionFilterSection',
    titleEn: 'Condition',
    titleFr: 'État',
    titleNl: 'Staat',
    quickFilters: [
        {
            query: {
                data: 'Used',
                field: 'condition.title',
                operator: 'IS_NOT',
            },
            titleEn: 'New',
            titleFr: 'Nouveau',
            titleNl: 'Nieuw',
            order: 1,
            icon: null,
            iconType: null,
            entityId: 'conditionNew',
        },
        {
            query: {
                data: 'New',
                field: 'condition.title',
                operator: 'IS_NOT',
            },
            titleEn: 'Used',
            titleFr: 'Occasion',
            titleNl: 'Gebruikt',
            order: 1,
            icon: null,
            iconType: null,
            entityId: 'conditionUsed',
        },
    ],
    isEnabled: true,
    order: 0,
};
