import { Layout } from 'antd';
import get from 'lodash/get';
import React, { useMemo } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import styled, { ThemeProvider } from 'styled-components';
import { Browse } from '../browse/Browse';
import { Breadcrumb } from '../components/Breadcrumb';
import ErrorBoundary from '../components/ErrorBoundary';
import { AuthenticatedAppProviders } from '../context';
import { useAuth } from '../context/auth/AuthProvider';
import { TenantProvider, useTenant } from '../context/tenant/TenantProvider';
import AppHeader from '../header/AppHeader';
import { VehicleDetails } from '../vehicle/VehicleDetails';
import Footer from './Footer';
import NotFound from './NotFound';
import { ScWrapper } from './styles';
import { useIsWideView } from '../hooks/is-wide-view.hook';
import { BREAKPOINTS } from '../utils/constants';
import { useMediaQuery } from 'react-responsive';
import { SearchResultsProvider } from '../context/search/results';
import { VEHICLE_DEFINITION_ID } from '@hypercharge/digitaldealer-commons/lib/constants';
import { SearchAggregationsProvider } from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchAggregationsProvider';
import { CacheProvider } from '../context/cache/CacheProvider';

const { Content } = Layout;

const ScContent = styled(Content)`
  position: relative;
  padding: 10px 5px;
`;
const ScLayout = styled(Layout)`
  min-height: 100vh !important;
  position: relative !important;
`;

const ScBreadcrumb = styled.div`
  margin-bottom: 0.7rem;
`;

const AppWithTheme = ({ children }) => {
  const { tenant } = useTenant();
  const theme = get(tenant, 'theme', {
    primary: '#fafbfc'
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const AuthenticatedApp = ({ location }: RouteComponentProps<any>) => {
  const { isAuthenticated } = useAuth();
  const isSmall = useMediaQuery({ maxWidth: BREAKPOINTS.lg });

  // Wide layout for the table view of the vehicle results
  const { isWideView } = useIsWideView();
  const { tenant } = useTenant();
  const facetsAggregations = useMemo(() => (tenant.facets ? tenant.facets.map(f => f.agg) : []), [
    tenant.facets
  ]);

  const fullWidth = isWideView && location.pathname === '/';

  return isAuthenticated ? (
    <AuthenticatedAppProviders>
      <TenantProvider>
        <SearchResultsProvider isWebsite={false}>
          <SearchAggregationsProvider
            isWebsite={false}
            url={`/api/private/search/${VEHICLE_DEFINITION_ID}/aggregations`}
            facetsAggregations={facetsAggregations}
          >
            <CacheProvider>
              <AppWithTheme>
                <ScLayout>
                  <Layout>
                    <AppHeader fullWidth={fullWidth} />

                    <ScContent>
                      <ErrorBoundary>
                        <ScWrapper fullWidth={fullWidth} isSmall={isSmall}>
                          <ScBreadcrumb>
                            <Breadcrumb />
                          </ScBreadcrumb>

                          <Switch>
                            <Route exact path="/" component={Browse} />

                            <Route exact path="/vehicle/:id" component={VehicleDetails} />

                            <Route path="*" component={NotFound} />
                          </Switch>
                        </ScWrapper>
                      </ErrorBoundary>
                    </ScContent>

                    <Footer fullWidth={fullWidth} />
                  </Layout>
                </ScLayout>
              </AppWithTheme>
            </CacheProvider>
          </SearchAggregationsProvider>
        </SearchResultsProvider>
      </TenantProvider>
    </AuthenticatedAppProviders>
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: {
          from: location
        }
      }}
    />
  );
};

export default AuthenticatedApp;
