import React, { createContext, useCallback, useContext, useMemo, useRef } from 'react';

type ContextValue = {
  cache: React.MutableRefObject<any>;
  cacheKey: React.MutableRefObject<string>;
  setCache: (val: any) => void;
  setCacheKey: (val: string) => void;
};
/** Cache key need to know for which url/parameters value is cached */

export const CacheContext = createContext<ContextValue | undefined>(undefined);

export const CacheProvider = props => {
  const cache = useRef();
  const setCache = useCallback(
    value => {
      cache.current = value;
    },
    [cache]
  );

  const cacheKey = useRef();
  const setCacheKey = useCallback(
    value => {
      cacheKey.current = value;
    },
    [cacheKey]
  );

  const value = useMemo(
    () => ({
      cache,
      setCache,
      cacheKey,
      setCacheKey
    }),
    [cache, setCache, cacheKey, setCacheKey]
  );

  return <CacheContext.Provider value={value} {...props} />;
};

export const useCache = (): ContextValue => {
  const value = useContext(CacheContext);
  if (value === undefined) {
    throw new Error('useCache must be used inside a CacheProvider');
  }
  return value;
};
