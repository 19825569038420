import { Property } from '@hypercharge/cms/lib/types';
import { VEHICLE_DEFINITION_ID } from '@hypercharge/digitaldealer-commons/lib/constants';
import {
  AggSearchParam,
  OPTION_SOLD_BY_PARAM,
  useSearchParams,
} from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchParamsProvider';
import { AggregationResultSet, AggregationType } from '@hypercharge/portal-lambda-sdk/lib/types';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import React, { ChangeEvent, useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi';
import { PropertyMap, useCmsDefinitions } from '../../context/cms/CmsDefinitionsProvider';
import { getTranslation } from '../../utils/cms';
import { AVAILABLE_AGGREGATIONS, getFormattedNumber } from '../../utils/constants';
import {
  ScAggBlockLink,
  ScAggContainer,
  ScAggOption,
  ScAggOptions,
  ScAggsContainer,
  ScAggTitle,
  ScCheckbox,
  ScCheckboxContent,
  ScCheckboxCount,
  ScCheckboxTitle,
  ScSearchAll,
  ScSlider,
} from './styles';
import * as i18next from 'i18next';
import { useAuth } from '../../context/auth/AuthProvider';
import { useTenant } from '../../context/tenant/TenantProvider';
import { Facet } from '@hypercharge/digitaldealer-commons/lib/types/common';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import {
  DisplayAgg,
  DisplayAggOption,
  isBucketAggregationResultArray,
  isRange,
  isStatsAggregationResult,
} from './types';

const MIN_COLLAPSIBLE_LENGTH = 5;
const MAX_COLLAPSIBLE_LENGTH = 100;
const DEFAULT_AGG_MIN = 0;
const DEFAULT_AGG_MAX = 1000000;
const DEFAULT_AGG_STEP = 100;

export const AggregationsContent = ({ aggregations }: { aggregations: AggregationResultSet }) => {
  const { aggParams } = useSearchParams();
  const { properties } = useCmsDefinitions();
  const { t, i18n } = useTranslation();
  const { tenant } = useTenant();

  const displayAggs: DisplayAgg[] = useMemo(
    () => toDisplayAggs(aggregations, aggParams, properties || {}, i18n.language, t, tenant.facets),
    [aggParams, aggregations, i18n.language, properties, t, tenant.facets]
  );

  return (
    <ScAggsContainer>
      <MySoldReserved />

      {displayAggs.map((agg) => {
        return agg.type === AggregationType.range || agg.type === AggregationType.stats ? (
          <RangeAggBlock key={agg.field} displayAgg={agg} aggParams={aggParams} />
        ) : (
          <AggBlock key={agg.field} displayAgg={agg} />
        );
      })}
    </ScAggsContainer>
  );
};

const RangeAggBlock = (args) => {
  const {
    i18n: { language },
  } = useTranslation();
  const {
    displayAgg: { title, field, type, min, max, step, value, displayFormatter },
    aggParams,
  } = args;

  const [currentValue, setCurrentValue] = useState({
    min: value.start || min,
    max: value.end || max,
  });
  const { setAggParam } = useSearchParams();

  const onAfterChange = useCallback(
    (value: number[] | number) => {
      setAggParam({
        field,
        data: {
          start: value[0] !== min ? value[0] : undefined,
          end: value[1] !== max ? value[1] : undefined,
        },
        type: type,
      });
    },
    [field, setAggParam, type, min, max]
  );

  const onChange = useCallback((value: number[] | number) => {
    setCurrentValue({
      min: value[0],
      max: value[1],
    });
  }, []);

  const currMin = value.start || min;
  const currMax = value.end || max;

  useEffect(() => {
    !aggParams.length && setCurrentValue({ min, max });
  }, [aggParams.length, min, max]);

  return (
    <ScAggContainer>
      <ScAggTitle>{title}</ScAggTitle>
      <ScSlider
        range
        marks={{
          [min]: (displayFormatter && displayFormatter(currMin, language)) || currMin,
          [max]: (displayFormatter && displayFormatter(currMax, language)) || currMax,
        }}
        // defaultValue={[currMin, currMax]}
        value={[currentValue.min, currentValue.max]}
        max={max}
        tipFormatter={displayFormatter}
        onChange={onChange}
        onAfterChange={onAfterChange}
        step={step}
      />
    </ScAggContainer>
  );
};

const AggBlock = ({ displayAgg }: { displayAgg: DisplayAgg }) => {
  const { setAggParam } = useSearchParams();
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const onChange = useCallback(
    (opId: string, checked: boolean) => {
      setAggParam({
        field: displayAgg.field,
        data: checked
          ? [...displayAgg.options.filter((op) => op.selected).map((op) => op.id), opId]
          : displayAgg.options.filter((op) => op.id !== opId && op.selected).map((op) => op.id),
        type: AggregationType.term,
      });
    },
    [displayAgg.field, displayAgg.options, setAggParam]
  );

  const toggleCollapse = () => setCollapsed((s) => !s);

  const isCollapsible =
    displayAgg.options.length > MIN_COLLAPSIBLE_LENGTH &&
    displayAgg.options.length <= MAX_COLLAPSIBLE_LENGTH;
  const isSearchable = displayAgg.options.length > MAX_COLLAPSIBLE_LENGTH;
  let visibleOptions =
    !isCollapsible || collapsed
      ? displayAgg.options.slice(0, MIN_COLLAPSIBLE_LENGTH)
      : displayAgg.options;

  // push selected results to the top
  visibleOptions = sortBy(visibleOptions, (op) => !op.selected);

  return (
    <ScAggContainer>
      <ScAggTitle>{displayAgg.title}</ScAggTitle>
      {isSearchable ? <ScSearchAll /> : null}
      <ScAggOptions>
        {visibleOptions.map((op) => (
          <AggRow key={`${displayAgg.field}_${op.id}`} op={op} onChange={onChange} />
        ))}
      </ScAggOptions>
      {isCollapsible && collapsed ? <Expand onClick={toggleCollapse} /> : null}
      {isCollapsible && !collapsed ? <Collapse onClick={toggleCollapse} /> : null}
    </ScAggContainer>
  );
};

const Collapse = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <ScAggBlockLink onClick={onClick}>
      <FiChevronsUp />
      {t('SHOW_LESS')}
    </ScAggBlockLink>
  );
};

const Expand = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <ScAggBlockLink onClick={onClick}>
      <FiChevronsDown />
      {t('SHOW_MORE')}
    </ScAggBlockLink>
  );
};

const AggRow = ({
  op,
  onChange,
}: {
  op: DisplayAggOption;
  onChange: (id: string, checked: boolean) => void;
}) => {
  const onToggle = (e: ChangeEvent<HTMLInputElement>) => onChange(op.id, e.target.checked);
  return (
    <ScAggOption>
      <ScCheckbox checked={op.selected} onChange={onToggle}>
        <ScCheckboxContent>
          <ScCheckboxTitle selected={op.selected}>{op.title}</ScCheckboxTitle>
          <ScCheckboxCount>{op.count}</ScCheckboxCount>
        </ScCheckboxContent>
      </ScCheckbox>
    </ScAggOption>
  );
};

const MySoldReserved = () => {
  const { params, setParam } = useSearchParams();
  const { authData } = useAuth();
  const { t } = useTranslation();

  const selected = useMemo(() => {
    return !!params[OPTION_SOLD_BY_PARAM];
  }, [params]);

  const onChange = useCallback(
    (checked: boolean) => {
      setParam(OPTION_SOLD_BY_PARAM, checked ? authData?.userId || '' : '');
    },
    [setParam, authData]
  );

  return (
    <ScAggContainer>
      <ScAggOption>
        <ScCheckbox checked={selected} onChange={(a) => onChange(a.target.checked)}>
          <ScCheckboxContent>
            <ScCheckboxTitle selected={selected}>{t('BROWSE__MY_RESERVED_SOLD')}</ScCheckboxTitle>
          </ScCheckboxContent>
        </ScCheckbox>
      </ScAggOption>
    </ScAggContainer>
  );
};

const toDisplayAggs = (
  aggregations: AggregationResultSet,
  aggParams: AggSearchParam[],
  properties: PropertyMap,
  language: string,
  t: i18next.TFunction,
  facets: Facet[]
): DisplayAgg[] => {
  const displayAggs: DisplayAgg[] = [];
  const paramsByField = keyBy(aggParams, (ap) => ap.field);

  const customAggregations = facets.map((facet) => {
    const availableFormatter = AVAILABLE_AGGREGATIONS.find(
      (p) => p.propertyId === facet.propertyId
    )?.displayFormatter;
    return {
      definition: VEHICLE_DEFINITION_ID,
      min: DEFAULT_AGG_MIN,
      max: DEFAULT_AGG_MAX,
      step: DEFAULT_AGG_STEP,
      displayFormatter: availableFormatter || ((val, locale) => getFormattedNumber(val, locale)),
      orderBy: 'count',
      ...facet.agg,
      ...omit(facet, 'agg'),
    };
  });

  for (let availableAggregation of customAggregations) {
    const { field, propertyId, type, displayFormatter } = availableAggregation;

    if (isEmpty(aggregations[field])) {
      continue;
    }

    let property: Facet | Property | undefined =
      facets.find((f) => f.propertyId === propertyId) ||
      (properties != null ? get(properties, [VEHICLE_DEFINITION_ID, propertyId]) : undefined);

    if (!property) {
      continue;
    }

    const fieldParams = paramsByField[field];
    const title = getTranslation(
      property.labels,
      language,
      getTranslation(
        get(properties, [VEHICLE_DEFINITION_ID, propertyId, 'labels']),
        language,
        field
      )
    );

    let displayAgg: DisplayAgg;
    if (type === AggregationType.range) {
      const { min, max, step } = availableAggregation;
      displayAgg = {
        title,
        field,
        type,
        min,
        max,
        step,
        value: (isRange(fieldParams?.data) && fieldParams.data) || {},
        displayFormatter,
        options: [],
      };
    } else if (type === AggregationType.stats) {
      const { step } = availableAggregation;
      const aggResult = aggregations[field];
      if (!isStatsAggregationResult(aggResult)) {
        continue;
      }
      const { min, max } = aggResult;
      if (max < step) {
        continue;
      }

      displayAgg = {
        title,
        field,
        type,
        min: Math.floor(min),
        max: Math.ceil(max),
        step,
        value: (isRange(fieldParams?.data) && fieldParams.data) || {},
        displayFormatter,
        options: [],
      };
    } else {
      const aggregationValue = aggregations[field];
      if (!isBucketAggregationResultArray(aggregationValue)) {
        continue;
      }

      const selectedFieldTerms: Set<string> =
        fieldParams != null && fieldParams.type === AggregationType.term
          ? new Set(fieldParams.data)
          : new Set();

      displayAgg = {
        title,
        field,
        type,
        displayFormatter,
        options: aggregationValue.map((opt) => {
          return {
            id: opt.value,
            title: opt.label,
            selected: selectedFieldTerms.has(opt.value),
            count: opt.count,
          };
        }),
      };
    }

    displayAggs.push(displayAgg);
  }

  return displayAggs;
};
