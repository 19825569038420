import { history } from '@hypercharge/digitaldealer-commons/lib/providers/router/history';
import { UrlQueryProvider } from '@hypercharge/digitaldealer-commons/lib/providers/router/UrlQueryProvider';
import { SearchParamsProvider } from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchParamsProvider';
import React, { Suspense } from 'react';
import { Router } from 'react-router';
import { Loading } from '../components/Loading';
import { LoginDataProvider } from '../login/LoginDataProvider';
import { DEFAULT_CARD_SORT, DEFAULT_TABLE_SORT } from '../utils/constants';
import AnalyticsTracking from './analytics/AnalyticsTracking';
import { AuthProvider } from './auth/AuthProvider';
import { CmsDefinitionsProvider } from './cms/CmsDefinitionsProvider';
import { useIsWideView } from '../hooks/is-wide-view.hook';
import './i18n'; // import i18n just so that it gets bundled
import { OnlineProvider } from './online/OnlineProvider';
import { Splash } from './splash/Splash';
import { SplashProvider } from './splash/SplashProvider';
import { TenantProvider } from './tenant/TenantProvider';
import { UserProvider } from './user/UserProvider';

export const AppProviders = ({ children }: any) => {
  return (
    <Router history={history}>
      <TenantProvider>
        <AnalyticsTracking>
          <UrlQueryProvider>
            <Suspense fallback={<Loading />}>
              <SplashProvider>
                <OnlineProvider>
                  <AuthProvider>{children}</AuthProvider>
                </OnlineProvider>
              </SplashProvider>
            </Suspense>
          </UrlQueryProvider>
        </AnalyticsTracking>
      </TenantProvider>
    </Router>
  );
};

export const AuthenticatedAppProviders = ({ children }: any) => {
  const { resultView } = useIsWideView();

  return (
    <CmsDefinitionsProvider>
      <UserProvider>
        <SearchParamsProvider
          defaultSortKey={DEFAULT_CARD_SORT}
          defaultTableSortKey={DEFAULT_TABLE_SORT}
          currentView={resultView}
        >
          <Splash>{children}</Splash>
        </SearchParamsProvider>
      </UserProvider>
    </CmsDefinitionsProvider>
  );
};

export const UnauthenticatedAppProviders = ({ children }: any) => {
  return (
    <LoginDataProvider>
      <Splash>{children}</Splash>
    </LoginDataProvider>
  );
};
