import { intersection } from 'lodash';
import React, { PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

type ContextValue = {
  addLoader: (loadKey: string) => void;
  removeLoader: (loadKey: string) => void;
  getIsReady: (loaders?: string[]) => boolean;
};

const SplashContext = React.createContext<ContextValue | undefined>(undefined);

const SplashProvider = (props: PropsWithChildren<any>) => {
  const [loaders, setLoaders] = useState<string[]>([]);

  const addLoader = useCallback((loadKey: string) => {
    setLoaders((loaders: string[]) => {
      if (loaders.includes(loadKey)) {
        return loaders;
      }
      return [...loaders, loadKey];
    });
  }, []);

  const removeLoader = useCallback((loadKey: string) => {
    setLoaders((loaders: string[]) => {
      if (loaders.includes(loadKey)) {
        return loaders.filter(l => l !== loadKey);
      }
      return loaders;
    });
  }, []);

  const getIsReady = useCallback(
    (whitelistedLoaders?: string[]) => {
      return whitelistedLoaders != null
        ? intersection(whitelistedLoaders, loaders).length === 0
        : loaders.length === 0;
    },
    [loaders]
  );

  const value = useMemo(
    () => ({
      addLoader,
      removeLoader,
      getIsReady
    }),
    [addLoader, getIsReady, removeLoader]
  );

  return <SplashContext.Provider value={value} {...props} />;
};

const useSplash = (): ContextValue => {
  const context = useContext(SplashContext);
  if (context === undefined) {
    throw new Error('useSplash must be used within an SplashProvider');
  }
  return context;
};

export { useSplash, SplashProvider };
