import { history } from '@hypercharge/digitaldealer-commons/lib/providers/router/history';
import { message } from 'antd';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';
import { requestEmailCode } from '../context/auth/authClient';
import { useTenant } from '../context/tenant/TenantProvider';
import { FormInput } from './FormInput';
import { useLoginData } from './LoginDataProvider';
import { ScButton, ScContainer, ScTitle } from './styles';

type LoginValues = {
  email: string;
};

const initialValues: LoginValues = {
  email: ''
};

const EmailLoginForm = ({ location }: RouteComponentProps<any>) => {
  const { t } = useTranslation();
  const { tenant } = useTenant();
  const { setEmail } = useLoginData();

  const onSubmit = useCallback(
    async (values: LoginValues, actions: FormikActions<LoginValues>) => {
      const { promise } = requestEmailCode(tenant.id, values.email);
      await promise.then(({ error }) => {
        if (error == null) {
          setEmail(values.email);
          history.push('/login/code', location.state);
        } else {
          message.warning(t('AUTH__REQUEST_CODE_ERROR'));
        }
        actions.setSubmitting(false);
      });
    },
    [tenant, setEmail, location.state, t]
  );

  const formSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email(t('FORM__INVALID_EMAIL'))
          .required(t('FORM__REQUIRED'))
      }),
    [t]
  );

  return (
    <ScContainer>
      <ScTitle>{t('AUTH__SIGN_IN')}</ScTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={formSchema}
      >
        {(formProps: FormikProps<LoginValues>) => {
          return (
            <form noValidate onSubmit={formProps.handleSubmit}>
              <Field
                id="email"
                component={FormInput}
                disabled={formProps.isSubmitting}
                name="email"
                placeholder={t('AUTH__EMAIL_PLACEHOLDER')}
                size="large"
                type="text"
                value={formProps.values.email}
              />

              <ScButton
                loading={formProps.isSubmitting}
                onClick={formProps.handleSubmit as any}
                type="primary"
              >
                {t('AUTH__CONTINUE')}
              </ScButton>
            </form>
          );
        }}
      </Formik>
    </ScContainer>
  );
};
export default EmailLoginForm;
