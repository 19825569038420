import { AuthData } from '@hypercharge/digitaldealer-commons/lib/types/auth';
import * as http from '@hypercharge/digitaldealer-commons/lib/utils/httpClient';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as storage from '@hypercharge/digitaldealer-commons/lib/utils/storage';
import { useTenant } from '../tenant/TenantProvider';
import * as auth from './authClient';

type ContextValue = {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  authData?: AuthData;
  login: (userId: string, email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
};

const storageKey = '__hyper_auth__';

const AuthContext = React.createContext<ContextValue | undefined>(undefined);

const AuthProvider = (props: any) => {
  const { tenant } = useTenant();
  // state
  const [authData, setAuthData] = useState<AuthData | undefined>(() => storage.load(storageKey));
  const [interceptResponses, setInterceptResponses] = useState(false);
  // refs (changing values in useRef do not force a re-render)
  const isAuthenticating = useRef<boolean>(false);
  const failedAuthRequestsRef = useRef<Function[]>([]);

  // We don't want the children of this provider to also re-render every time this renders, as such, and
  // because we are passing login and logout function as values of the provider, we memoize them
  // with the useCallback
  const login = useCallback(
    async (userId: string, email: string, password: string): Promise<boolean> => {
      if (tenant) {
        isAuthenticating.current = true;
        const newAuthData = await auth.login(tenant.id, userId, email, password);
        setAuthData(newAuthData);
        isAuthenticating.current = false;
        return newAuthData != null;
      } else {
        return false;
      }
    },
    [tenant]
  );

  // see comment in login function
  const logout = useCallback(async (): Promise<void> => {
    if (authData != null && tenant) {
      await auth.logout(tenant.id, authData);
      setAuthData(undefined);
      failedAuthRequestsRef.current = [];
    }
  }, [authData, tenant]);

  const refreshJwt = useCallback(
    async (forcePageReload = false): Promise<AuthData | undefined> => {
      isAuthenticating.current = true;
      try {
        if (authData != null) {
          const newAuthData = await auth.refreshToken(tenant.id, authData);
          setAuthData(newAuthData);
          return newAuthData;
        }
      } catch (e) {
        await logout();

        if (forcePageReload) {
          window.location.reload();
        }
        throw e;
      } finally {
        isAuthenticating.current = false;
      }

      return;
    },
    [authData, logout, tenant.id]
  );

  // this effects is responsible for persisting auth data
  useEffect(() => {
    storage.save(storageKey, authData);
  }, [authData]);

  // Listen to changes in local storage in order to adapt to actions from other browser tabs
  useEffect(() => {
    const handleChange = () => {
      setAuthData(storage.load(storageKey));
    };
    window.addEventListener('storage', handleChange, false);
    return () => {
      window.removeEventListener('storage', handleChange);
    };
  }, []);

  // this effect is responsible for refreshing the jwt before it expires
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (authData != null) {
      const ttl = authData.jwtExpiry - Date.now();
      if (ttl > 0) {
        timer = setTimeout(() => refreshJwt(), ttl - 30000);
      }
    }

    return () => {
      if (timer != null) {
        clearTimeout(timer);
      }
    };
  }, [authData, refreshJwt]);

  // this effect adds interceptor that tries to refresh token if it is expired and logout if refresh is not possible
  useEffect(() => {
    let interceptor: http.RequestInterceptor | undefined;
    if (authData != null) {
      interceptor = async (url: string, options: RequestInit): Promise<void> => {
        if (
          !url.includes('/api/auth') &&
          !url.includes('/api/public') &&
          authData.jwtExpiry - Date.now() <= 0
        ) {
          await refreshJwt(true);
        }
      };

      http.registerRequestInterceptor(interceptor);
      setInterceptResponses(true);
    }

    return () => {
      if (interceptor != null) {
        http.unregisterRequestInterceptor(interceptor);
        setInterceptResponses(false);
        failedAuthRequestsRef.current = [];
      }
    };
  }, [authData, logout, refreshJwt]);

  // this effect should keep an eye on the authData and try to
  // refresh token if token is expired
  useEffect(() => {
    if (authData != null && authData.jwtExpiry < Date.now() && !isAuthenticating.current) {
      refreshJwt().then((newAuthData: AuthData | undefined) => {
        if (newAuthData != null) {
          failedAuthRequestsRef.current.forEach((r) => {
            r();
          });
        }
        failedAuthRequestsRef.current = [];
      });
    }
  }, [authData, isAuthenticating, refreshJwt]);

  // this value will still change after every refreshToken, because the authData changes
  const isAuthenticated = authData != null && interceptResponses;
  const value = useMemo(
    () => ({
      isAuthenticated,
      isAuthenticating: isAuthenticating.current,
      authData: isAuthenticated ? authData : undefined,
      login,
      logout,
    }),
    [authData, isAuthenticated, isAuthenticating, login, logout]
  );
  return <AuthContext.Provider value={value} {...props} />;
};

const useAuth = (): ContextValue => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
