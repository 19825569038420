import { TENANT_HEADER, APP_HEADER } from '@hypercharge/digitaldealer-commons/lib/constants';
import { MinimalTenant } from '@hypercharge/digitaldealer-commons/lib/types/common';
import {
  FetchValue,
  hyperfetch,
  setDefaultHeader
} from '@hypercharge/digitaldealer-commons/lib/utils/httpClient';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Loading } from '../../components/Loading';
import NotFound from '../../layout/NotFound';
import { Status } from '../../utils/types';

type ContextValue = {
  // Although strictly speaking this is not correct nothing under TenantProvider
  // will be rendered until we know our tenant "pretending" tenant can't be undefined
  tenant: MinimalTenant;
  status: Status;
};

const fetchMinimalTenant = async (): Promise<MinimalTenant | undefined> => {
  try {
    const { promise } = hyperfetch<MinimalTenant>(
      `/api/public/tenant-by-host/${window.location.hostname}/client`,
      {
        method: 'GET'
      }
    );
    const fetchResult: FetchValue<MinimalTenant> = await promise;
    return fetchResult.data;
  } catch (e) {
    console.error('Failed to fetch Public Tenant Data', e);
  }
};

const TenantContext = React.createContext<ContextValue | undefined>(undefined);

const TenantProvider = ({ children }: any) => {
  const [tenant, setTenant] = useState<MinimalTenant>();
  const [status, setStatus] = useState<Status>(Status.Loading);

  useEffect(() => {
    setStatus(Status.Loading);
    fetchMinimalTenant()
      .then(tenant => {
        if (tenant) {
          setStatus(Status.Success);
          setDefaultHeader(TENANT_HEADER, tenant.id);
          if (tenant.appId !== '') {
            setDefaultHeader(APP_HEADER, tenant.appId);
          }
          setTenant(tenant);
        } else {
          setStatus(Status.Error);
        }
      })
      .catch(() => setStatus(Status.Error));
  }, []);

  const value = useMemo(() => ({ status, tenant: tenant as MinimalTenant }), [status, tenant]);
  return (
    <TenantContext.Provider value={value}>
      {tenant ? children : status === Status.Error ? <NotFound /> : <Loading />}
    </TenantContext.Provider>
  );
};

const useTenant = (): ContextValue => {
  const context = useContext(TenantContext);
  if (context === undefined) {
    throw new Error('useTenant must be used within an TenantProvider');
  }
  return context;
};

export { useTenant, TenantProvider };
