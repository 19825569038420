import { __assign, __read, __rest, __spreadArray, __values } from "tslib";
import { ConditionType, SortOrder, } from '@hypercharge/portal-lambda-sdk/lib/types';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import { FilterOperatorTypes, IS_CONDITION_PROPERTY, IS_CONDITION_PROPERTY_NEW, IS_CONDITION_PROPERTY_USED, IS_CONDITION_PROPERTY_DEMO, PRICE_VAT_PROPERTY_ID, } from '../../constants';
import { hyperfetch, json } from '../../utils/httpClient';
import { getDefaultFilterRequest } from '../../utils/search';
import { Status } from '../../utils/types';
import { formatSort } from '../../utils/helpers';
import { OPTION_SOLD_BY_PARAM, useSearchParams } from './SearchParamsProvider';
export var getSearchResultsFactory = function (url, sortByOptions, isPublic) {
    if (isPublic === void 0) { isPublic = true; }
    var SearchResultsContext = createContext(undefined);
    var SearchResultsProvider = function (_a) {
        var _b = _a.showNew, showNew = _b === void 0 ? false : _b, _c = _a.showUsed, showUsed = _c === void 0 ? false : _c, _d = _a.showDemo, showDemo = _d === void 0 ? false : _d, _e = _a.isWebsite, isWebsite = _e === void 0 ? true : _e, _f = _a.newVehicles, newVehicles = _f === void 0 ? false : _f, otherProps = __rest(_a, ["showNew", "showUsed", "showDemo", "isWebsite", "newVehicles"]);
        var params = useSearchParams().params;
        var _g = __read(useState(), 2), results = _g[0], setResults = _g[1];
        var _h = __read(useState(Status.Idle), 2), status = _h[0], setStatus = _h[1];
        var replaceVehicle = useCallback(function (newVehicle) {
            setResults(function (currentResults) {
                var e_1, _a;
                if (currentResults == null) {
                    return undefined;
                }
                var newResults = [];
                try {
                    for (var _b = __values(currentResults.results), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var v = _c.value;
                        if (v.entityId === newVehicle.entityId) {
                            newResults.push(newVehicle);
                        }
                        else {
                            newResults.push(v);
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                return __assign(__assign({}, currentResults), { results: newResults });
            });
        }, []);
        var getResults = useCallback(function () {
            var filterRequest = __assign(__assign({}, getDefaultFilterRequest()), { fullText: params.textQuery || '', limit: params.pageSize, offset: (params.page - 1) * params.pageSize, sortBy: sortByOptions[params.sortBy]
                    ? [sortByOptions[params.sortBy]]
                    : formatSort(params.sortBy) });
            filterRequest.sortBy.push({
                field: 'purchaseInvoiceDate',
                order: SortOrder.asc,
            });
            filterRequest.sortBy.push({
                field: 'createdAt',
                order: SortOrder.asc,
            });
            var myVehiclesFilter = {
                filters: [
                    {
                        data: params[OPTION_SOLD_BY_PARAM] || '',
                        field: 'optionBy.entityId',
                        operator: FilterOperatorTypes.is,
                    },
                    {
                        data: params[OPTION_SOLD_BY_PARAM] || '',
                        field: 'soldBy.entityId',
                        operator: FilterOperatorTypes.is,
                    },
                ],
                condition: ConditionType.or,
            };
            var filters = params[OPTION_SOLD_BY_PARAM]
                ? [getFiltersForNewUsed(showNew, showDemo, showUsed), myVehiclesFilter]
                : [getFiltersForNewUsed(showNew, showDemo, showUsed)];
            if (params.filters && params.filters.filters.length > 0) {
                filters.push(getConditionQuery(params.filters));
            }
            filterRequest.query = {
                filters: filters,
                condition: ConditionType.and,
            };
            if (newVehicles) {
                filterRequest.limit = 10;
                filterRequest.sortBy = [{ field: 'createdAt', order: SortOrder.desc }];
            }
            setStatus(Status.Loading);
            var _a = hyperfetch(url, {
                method: 'POST',
                body: json(isPublic
                    ? {
                        filter: filterRequest,
                    }
                    : filterRequest),
            }), promise = _a.promise, abort = _a.abort;
            promise
                .then(function (_a) {
                var data = _a.data, error = _a.error;
                if (error != null || data == null) {
                    setStatus(Status.Error);
                }
                else {
                    setResults(data);
                    setStatus(Status.Success);
                }
            })
                .catch(function () {
                //console.log('request aborted');
            });
            return function () {
                abort();
            };
        }, [params, showNew, showDemo, showUsed]);
        // this effect fetches new results whenever the filter change
        useEffect(getResults, [getResults]);
        var value = useMemo(function () { return ({
            replaceVehicle: replaceVehicle,
            results: results != null ? results.results : undefined,
            totalCount: results != null ? results.totalCount : undefined,
            status: status,
            setStatus: setStatus,
            getResults: getResults,
        }); }, [replaceVehicle, results, status, getResults]);
        return React.createElement(SearchResultsContext.Provider, __assign({ value: value }, otherProps));
    };
    var useSearchResults = function () {
        var query = useContext(SearchResultsContext);
        if (query === undefined) {
            throw new Error('useSearchResults must be used inside a SearchResultsProvider');
        }
        return query;
    };
    return {
        SearchResultsContext: SearchResultsContext,
        SearchResultsProvider: SearchResultsProvider,
        useSearchResults: useSearchResults,
    };
};
export var convertBetweensToFilters = function (filters) {
    var newFilters = [];
    filters.forEach(function (f) {
        if (f.operator === FilterOperatorTypes.between) {
            if (f.data.start || f.data.start === 0) {
                newFilters.push({
                    operator: FilterOperatorTypes.greaterThanOrEqual,
                    data: f.data.start,
                    field: f.field,
                });
            }
            if (f.data.end || f.data.end === 0) {
                newFilters.push({
                    operator: FilterOperatorTypes.lessThanEqual,
                    data: f.data.end,
                    field: f.field,
                });
            }
        }
        else {
            newFilters.push(f);
        }
    });
    var price = newFilters.filter(function (_a) {
        var field = _a.field;
        return field === PRICE_VAT_PROPERTY_ID;
    });
    var notPrice = newFilters.filter(function (_a) {
        var field = _a.field;
        return field !== PRICE_VAT_PROPERTY_ID;
    });
    var emptyPrice = {
        condition: ConditionType.or,
        filters: [
            {
                operator: FilterOperatorTypes.empty,
                field: PRICE_VAT_PROPERTY_ID,
            },
            {
                condition: ConditionType.and,
                filters: price,
            },
        ],
    };
    return price.length
        ? __spreadArray(__spreadArray([], __read(notPrice)), [emptyPrice])
        : notPrice;
};
export var getConditionQuery = function (facetCondition) {
    var newFacetCondition = __assign(__assign({}, facetCondition), { filters: convertBetweensToFilters(facetCondition.filters) });
    return {
        condition: ConditionType.and,
        filters: [newFacetCondition],
    };
};
export var getFiltersForNewUsed = function (showNew, showDemo, showUsed) {
    var usedNewFilters = [];
    if (showUsed) {
        usedNewFilters.push({
            operator: FilterOperatorTypes.is,
            data: IS_CONDITION_PROPERTY_USED,
            field: IS_CONDITION_PROPERTY + ".title",
        });
    }
    if (showDemo) {
        usedNewFilters.push({
            operator: FilterOperatorTypes.is,
            data: IS_CONDITION_PROPERTY_DEMO,
            field: IS_CONDITION_PROPERTY + ".title",
        });
    }
    if (showNew) {
        usedNewFilters.push({
            operator: FilterOperatorTypes.is,
            data: IS_CONDITION_PROPERTY_NEW,
            field: IS_CONDITION_PROPERTY + ".title",
        });
    }
    return {
        condition: ConditionType.or,
        filters: usedNewFilters,
    };
};
