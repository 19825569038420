import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { useCmsDefinitions } from '../../../context/cms/CmsDefinitionsProvider';
import { OPTIONS_SPECS } from '../../../utils/constants';
import { ScContainer, ScRow, ScTdLabel, ScTdValue } from './styles';
import { getTableOptions, ScMdCheck, TableSpec } from './utils';
import { useTranslation } from 'react-i18next';
import { getTranslatedPropertyValue } from '@hypercharge/digitaldealer-commons/lib/utils/helpers';

type Props = {
  vehicle: Vehicle;
};

const OptionsSpecifications = ({ vehicle }: Props) => {
  const { properties } = useCmsDefinitions();
  const {
    t,
    i18n: { language }
  } = useTranslation();

  if (properties == null) {
    throw new Error('Inconsistent state, properties cannot be null');
  }

  const optionsE: TableSpec[] = useMemo(() => {
    if (!vehicle.optionsE || !vehicle.optionsE.length) return undefined;
    return vehicle.optionsE
      .filter(op => op.showWebsite === true)
      .sort((a, b) => (a.order || 999) - (b.order || 999))
      .map(op => {
        const title = getTranslatedPropertyValue(op, 'title', language);
        return {
          id: op.externalRefId || op.entityId,
          labelId: title,
          value: <ScMdCheck />
        } as TableSpec;
      });
  }, [vehicle, language]);

  const options: TableSpec[] = useMemo(() => {
    if (optionsE && optionsE.length) return optionsE;
    const availableOptions = OPTIONS_SPECS.filter(spec => !!get(vehicle, spec.field));
    return getTableOptions(properties, availableOptions, vehicle);
  }, [properties, vehicle, optionsE]);

  return (
    <ScContainer>
      {options.map(({ id, labelId, value }) => (
        <ScRow key={`${vehicle.entityId}_${id}`}>
          <ScTdLabel>{labelId}</ScTdLabel>
          <ScTdValue>{value}</ScTdValue>
        </ScRow>
      ))}

      {!options.length && t('VEHICLE__NO__OPTIONS')}
    </ScContainer>
  );
};

export default OptionsSpecifications;
