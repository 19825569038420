import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';
import Paper from '../../components/Paper';
import { useSearchResults } from '../../context/search/results';
import { BREAKPOINTS } from '../../utils/constants';
import { Status } from '../../utils/types';
import HorizontalResultsLoader from './loaders/HorizontalResultsLoader';
import VerticalResultsLoader from './loaders/VerticalResultsLoader';
import ResultView from './ResultView';
import RowResultsLoader from './loaders/RowResultsLoader';
import BrowsePagination from './BrowserPagination';
import { useCache } from '../../context/cache/CacheProvider';

const ScResultsPaper = styled(Paper as any)<{ fullWidth?: boolean }>`
  padding: ${props => (props.fullWidth ? 0 : '5px')};
  margin-top: 5px;
  overflow-x: ${props => (props.fullWidth ? 'auto' : 'none')};

  @media (min-width: ${BREAKPOINTS.md}px) {
    padding: ${props => (props.fullWidth ? 0 : '10px')};
  }
`;

const Results = ({ isWideView }) => {
  const { results, status } = useSearchResults();
  const { cache, setCache, cacheKey, setCacheKey } = useCache();
  const previousStatus = useRef<number | undefined>(undefined);
  const isFirstSuccess = useRef<boolean>(true);
  const isTabletOrBigger = useMediaQuery({ minWidth: BREAKPOINTS.md });

  if (status === Status.Success) {
    isFirstSuccess.current = false;
  }

  useEffect(() => {
    /* status==success && previousStatus==success when cars changed locally(reserve/sell vehicle) */
    if (
      status === Status.Success &&
      (previousStatus.current === Status.Loading || previousStatus.current === Status.Success)
    ) {
      setCache(results);
      setCacheKey(window.location.href);
    }
    previousStatus.current = status;
  }, [results, status, setCache, setCacheKey]);

  const cachedResults = useMemo(() => {
    if (
      status === Status.Success &&
      (previousStatus.current === Status.Loading || previousStatus.current === Status.Success)
    ) {
      return results;
    }

    return cacheKey.current === window.location.href ? cache.current : null;
  }, [status, cache, cacheKey, results]);

  // 1. Since our aggregations are dynamic, it does not make
  //    much sense to not scroll, since the aggregation we were
  //    looking at might have changed position.
  // 2. We do this in a useLayoutEffect so that the scroll happens faster.
  // 3. We don't want to do it on first render because the user might have
  //    done "browser back" and we want to keep the scroll position, also, if the
  //    the user just came to this page directly, the scroll position will already
  //    be at the top.
  useLayoutEffect(() => {
    if (!isFirstSuccess.current && status === Status.Loading) {
      window.scrollTo(0, 0);
    }
  });

  return (
    <ScResultsPaper fullWidth={isWideView}>
      {!results || (!cachedResults && status === Status.Loading) ? (
        isWideView ? (
          <RowResultsLoader />
        ) : /* skeletons / loaders are also important for making the scroll work when using the browser back button.*/
        isTabletOrBigger ? (
          <HorizontalResultsLoader />
        ) : (
          <VerticalResultsLoader />
        )
      ) : (
        <ResultView vehicles={cachedResults || results} />
      )}
      <BrowsePagination />
    </ScResultsPaper>
  );
};

export default Results;
