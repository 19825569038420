import { useSearchAggregations } from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchAggregationsProvider';
import { useSearchParams } from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchParamsProvider';
import { Button, Drawer } from 'antd';
import { Icon } from '@ant-design/compatible';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiRefreshCcw } from 'react-icons/fi';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';
import { BREAKPOINTS } from '../../utils/constants';
import { AggregationsContent } from './AggregationsContent';
import { ScResetContent } from './styles';

const ScDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0 !important;
  }
`;
const ScContainer = styled.div`
  padding: 0 0 64px; /* to account for the footer's height*/
`;
const ScFooter = styled.div<{ isMd: boolean }>`
  background: #fff;
  border-top: 1px solid #eceeef;
  bottom: 0;
  display: flex;
  height: 64px;
  left: 0;
  padding: 10px;
  position: fixed;
  width: ${props => (props.isMd ? '66vw' : '100vw')};
  button + button {
    margin-left: 10px;
  }
`;
const ScResetButton = styled(Button)`
  flex: none;
`;
const ScBrowseButton = styled(Button)`
  flex: 1;
`;

const ResetButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <ScResetButton onClick={onClick} size={'large'}>
      <ScResetContent>
        <FiRefreshCcw />
        {t('BROWSE__CLEAR_ACTIVE_FILTERS')}
      </ScResetContent>
    </ScResetButton>
  );
};

const BrowseButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <ScBrowseButton type={'primary'} size={'large'} onClick={onClick}>
      <Icon type="search" />
      {t('BROWSE__SHOW_VEHICLES')}
    </ScBrowseButton>
  );
};

const AggregationsDrawer = ({ visible, onClose }: { visible: boolean; onClose: () => void }) => {
  const { aggregations } = useSearchAggregations();
  const { aggParams, params, clearAll } = useSearchParams();
  const isMd = useMediaQuery({ minWidth: BREAKPOINTS.md });

  return (
    <ScDrawer
      visible={visible}
      onClose={onClose}
      placement={'left'}
      width={isMd ? '66vw' : '100vw'}
      closable={false}
    >
      <ScContainer>
        {aggregations == null ? null : <AggregationsContent aggregations={aggregations} />}
        <ScFooter isMd={isMd}>
          {aggParams.length > 0 || params.textQuery ? (
            <ResetButton
              onClick={() => {
                onClose();
                clearAll();
              }}
            />
          ) : null}
          <BrowseButton
            onClick={() => {
              onClose();
            }}
          />
        </ScFooter>
      </ScContainer>
    </ScDrawer>
  );
};

export default AggregationsDrawer;
