import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import { get } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi';
import styled from 'styled-components/macro';
import { useCmsDefinitions } from '../../../context/cms/CmsDefinitionsProvider';
import { OPTIONS_SPECS } from '../../../utils/constants';
import { ScContainer, ScRow, ScTdLabel, ScTdValue } from './styles';
import { getTableOptions, getTableSpecs, TableSpec } from './utils';
import { useTenant } from '../../../context/tenant/TenantProvider';

type Props = {
  vehicle: Vehicle;
  className?: string;
};

const COLLAPSED_ROW_COUNT = 5;

const ScShowMore = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  color: #007edb;
  cursor: pointer;
  margin-top: 5px;
  :hover {
    text-decoration: underline;
  }
`;

const BrowseSpecifications = ({ vehicle, className }: Props) => {
  const { properties } = useCmsDefinitions();
  const { t, i18n } = useTranslation();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { tenant } = useTenant();

  const toggleCollapsed = useCallback(() => setCollapsed((c) => !c), []);

  if (properties == null) {
    throw new Error('Inconsistent state, properties cannot be null');
  }

  const details: TableSpec[] = useMemo(() => {
    const availableDetails = tenant.intranet.specificationList?.filter(
      (spec) => !!get(vehicle, spec.propertyId)
    );

    if (!availableDetails) {
      return [];
    }

    return getTableSpecs(properties, availableDetails, vehicle, i18n.language, t);
  }, [properties, i18n.language, vehicle, t, tenant.intranet.specificationList]);

  const options: TableSpec[] = useMemo(() => {
    const availableOptions = OPTIONS_SPECS.filter((spec) => !!get(vehicle, spec.field));
    return getTableOptions(properties, availableOptions, vehicle);
  }, [properties, vehicle]);

  const allSpecs = useMemo(() => [...details, ...options], [details, options]);

  const tableSpecs = useMemo(() => {
    return collapsed ? allSpecs.slice(0, COLLAPSED_ROW_COUNT) : allSpecs;
  }, [allSpecs, collapsed]);

  return (
    <ScContainer className={className}>
      {tableSpecs.map(({ id, labelId, value }) => (
        <ScRow key={`${vehicle.entityId}_${id}`}>
          <ScTdLabel>{t(labelId)}</ScTdLabel>
          <ScTdValue>{value}</ScTdValue>
        </ScRow>
      ))}
      {allSpecs.length > COLLAPSED_ROW_COUNT ? (
        <ScShowMore onClick={toggleCollapsed}>
          {collapsed ? (
            <>
              <FiChevronsDown />
              {t('SHOW_MORE')}
            </>
          ) : (
            <>
              <FiChevronsUp />
              {t('SHOW_LESS')}
            </>
          )}
        </ScShowMore>
      ) : null}
    </ScContainer>
  );
};

export default BrowseSpecifications;
