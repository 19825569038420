import { __assign, __read, __rest, __values } from "tslib";
import { parseUrl, stringify } from 'query-string';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { history } from './history';
export var QUERY_OPTIONS = {
    arrayFormat: 'bracket'
};
export var QueryContext = createContext(undefined);
var parse = function (path) { return parseUrl(path, QUERY_OPTIONS); }; // TODO use qs
export var UrlQueryProvider = withRouter(function (_a) {
    var location = _a.location, otherProps = __rest(_a, ["location"]);
    var _b = __read(useState(function () { return parse(location.search); }), 2), parsedUrl = _b[0], setParsedUrl = _b[1];
    // we want a function that accepts multiple params in order to change all needed at once
    // and thus avoid unecessary downstream renders
    var setQueryParams = useCallback(function () {
        var e_1, _a;
        var newParams = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            newParams[_i] = arguments[_i];
        }
        var newQuery = __assign({}, parsedUrl.query);
        try {
            for (var newParams_1 = __values(newParams), newParams_1_1 = newParams_1.next(); !newParams_1_1.done; newParams_1_1 = newParams_1.next()) {
                var p = newParams_1_1.value;
                newQuery[p.key] = p.value;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (newParams_1_1 && !newParams_1_1.done && (_a = newParams_1.return)) _a.call(newParams_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        history.push({
            search: stringify(newQuery, QUERY_OPTIONS)
        });
    }, [parsedUrl.query]);
    var removeQueryParams = useCallback(function () {
        var e_2, _a;
        var keys = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            keys[_i] = arguments[_i];
        }
        var newQuery = __assign({}, parsedUrl.query);
        try {
            for (var keys_1 = __values(keys), keys_1_1 = keys_1.next(); !keys_1_1.done; keys_1_1 = keys_1.next()) {
                var key = keys_1_1.value;
                delete newQuery[key];
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (keys_1_1 && !keys_1_1.done && (_a = keys_1.return)) _a.call(keys_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        history.push({
            search: stringify(newQuery, QUERY_OPTIONS)
        });
    }, [parsedUrl.query]);
    useEffect(function () {
        setParsedUrl(parse(location.search));
    }, [location.search]);
    var value = useMemo(function () {
        return {
            setQueryParams: setQueryParams,
            query: parsedUrl.query,
            removeQueryParams: removeQueryParams
        };
    }, [setQueryParams, parsedUrl, removeQueryParams]);
    return React.createElement(QueryContext.Provider, __assign({ value: value }, otherProps));
});
export var useQuery = function () {
    var value = useContext(QueryContext);
    if (value === undefined) {
        throw new Error('useQuery must be used inside a QueryProvider');
    }
    return value;
};
