import { useSearchAggregations } from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchAggregationsProvider';
import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import React, { useEffect, useState } from 'react';
import ResultCard from './ResultCard';
import { useIsWideView } from '../../hooks/is-wide-view.hook';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { head } from 'lodash';
import VehicleStatusLabel from '../../vehicle/components/VehicleStatusLabel';
import VehicleActions from '../../vehicle/components/VehicleActions';
import { splitVinCode } from '@hypercharge/digitaldealer-commons/lib/utils/helpers';
import { useSearchResults } from '../../context/search/results';
import moment from 'moment';
import RowResultsLoader from './loaders/RowResultsLoader';
import { Table } from 'antd';
import { BREAKPOINTS } from '../../utils/constants';
import { useMediaQuery } from 'react-responsive';
import { fetchVehicleDefinition, fetchVehicleView } from '../../utils/vehicle';
import {
  SORT_PARAM,
  useSearchParams,
} from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchParamsProvider';

export const findProperty = (definition, propertyId) => {
  return definition.sections.reduce((a, section) => {
    if (a) return a;
    const property = section.properties.reduce((a, property) => {
      if (a) return a;
      return property.propertyId === propertyId ? property : null;
    }, null);
    return property || null;
  }, null);
};

type Props = {
  vehicles: Vehicle[];
};

const ResultView = ({ vehicles }: Props) => {
  const { isWideView } = useIsWideView();

  return isWideView ? <RowView vehicles={vehicles} /> : <CardView vehicles={vehicles} />;
};

const CardView = ({ vehicles }: Props) => {
  return (
    <>
      {vehicles.map((v) => (
        <ResultCard key={v.entityId} vehicle={v} />
      ))}
    </>
  );
};

export const ScResultView = styled(ResultView)`
  width: 100%;
`;

const ScVIN = styled.code``;
const ScVIN8 = styled.span`
  padding: 2px;
  border: 1px solid #000;
  border-radius: 4px;
  margin-left: 6px;
`;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'eur',
  minimumFractionDigits: 2,
});

const RowView = ({ vehicles }: Props) => {
  const { i18n } = useTranslation();
  const [views, setViews] = useState<unknown[]>();
  const [vehicleDefinition, setVehicleDefinition] = useState();
  const properties: any =
    vehicleDefinition &&
    views &&
    (views || []).map((view) => findProperty(vehicleDefinition, view));
  const { replaceVehicle, getResults } = useSearchResults();
  const { updateAggregations } = useSearchAggregations();
  const { params, setParam } = useSearchParams();
  const isSmall = useMediaQuery({ maxWidth: BREAKPOINTS.lg });

  const ScTableWrapper = styled.div`
    .ant-table-thead > tr > th {
      text-align: center;
      white-space: nowrap;
      ${isSmall && 'padding: 3em 0;'}
    }

    .ant-table-thead > tr > th.ant-table-cell-fix-right {
      ${isSmall && 'padding: 0 !important'}
    }

    .ant-table-tbody > tr > td {
      overflow-wrap: normal;
    }

    .ant-table-content > table {
      table-layout: auto !important;
    }

    .ant-table-pagination.ant-pagination {
      float: none;
      text-align: center;
    }
  `;

  const createCustomElement = (propertyId, vehicle) => {
    const formatDate = (date: number) => {
      if (!date) return undefined;
      return moment(date).format('DD-MM-YYYY');
    };

    const vinParts: string[] | [] = vehicle ? splitVinCode(vehicle.vin) : [];

    const customElements = vehicle
      ? {
          status: <VehicleStatusLabel vehicle={vehicle} />,
          actions: (
            <VehicleActions
              vehicle={vehicle}
              setVehicle={replaceVehicle}
              buttonsSmallView={true}
              updateAggregations={updateAggregations}
              getResultAfterSell={getResults}
              isSmall={isSmall}
            />
          ),
          vin: vinParts.length ? (
            <ScVIN>
              {vinParts[0]}
              lalala
              {Boolean(vinParts[1]) && <ScVIN8>{vinParts[1]}</ScVIN8>}
            </ScVIN>
          ) : (
            '-'
          ),
          createdAt: formatDate(vehicle.createdAt),
          orderCreatedAt: formatDate(vehicle.orderCreatedAt),
          acceptedDeliveryAt: formatDate(vehicle.acceptedDeliveryAt),
          updatedAt: formatDate(vehicle.updatedAt),
          announcedDeliveryAt: formatDate(vehicle.announcedDeliveryAt),
          lastModificationAt: formatDate(vehicle.lastModificationAt),
          purchaseInvoiceDate: formatDate(vehicle.purchaseInvoiceDate),
          dateFirstRegistration: formatDate(vehicle.dateFirstRegistration),
          optionStartDate: formatDate(vehicle.optionStartDate),
          optionEndDate: formatDate(vehicle.optionEndDate),
          saleDate: formatDate(vehicle.saleDate),
          isUsed: vehicle && vehicle.condition !== 'New' ? 'Used' : 'New',
          price: formatter.format(vehicle.price),
          priceVat: +vehicle.priceVat ? formatter.format(vehicle.priceVat) : '-',
          previousPrice: +vehicle.previousPrice ? formatter.format(vehicle.previousPrice) : '-',
          optionBy: vehicle.optionBy,
        }
      : {};

    return customElements[propertyId]
      ? customElements[propertyId]
      : Array.isArray(vehicle[propertyId])
      ? vehicle[propertyId].map((p) => p?.title || p?.entityId || p).join(', ')
      : vehicle[propertyId]?.title || vehicle[propertyId]?.entityId || null;
  };

  useEffect(() => {
    fetchVehicleView(i18n.language)
      .then((data) => {
        const result = data.results.find(
          (view) => view.referenceDefinitionId === 'vehicle' && view.isDefault === true
        );
        return result ? result.filters.responseFields : null;
      })
      .then(setViews);

    fetchVehicleDefinition().then((definitions) => setVehicleDefinition(head(definitions) as any));
  }, [i18n]);

  const columns =
    properties &&
    properties.map((property) => {
      const title = property?.labels?.[i18n.language];
      const propertyId = property?.propertyId;
      return {
        title: title,
        dataIndex: propertyId,
        key: propertyId,
        sorter: {
          multiple: 1,
        },
        sortDirections: ['ascend', 'descend'],
        render: (value, vehicle) =>
          createCustomElement(propertyId, vehicle) || value?.toString() || '',
      };
    });

  if (columns) {
    columns.push({
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value, vehicle) => createCustomElement('status', vehicle) || value,
    });

    columns.push({
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      render: (value, vehicle) => {
        return createCustomElement('actions', vehicle) || value;
      },
    });

    params.sortBy.split(',').forEach((param) => {
      const [key, order] = param.split('-');

      // set current sort order for column
      if (order) {
        const sortedColumn = columns.find((c) => c.key === key);

        if (sortedColumn) {
          sortedColumn.sortOrder = order;
        }
      }
    });
  }

  const data = vehicles.map((vehicle) => {
    const keys = Object.keys(vehicle);
    for (let key of keys) {
      vehicle[key] = customElements[key] ? customElements[key] : vehicle[key];
      if (key !== 'optionBy' && key !== 'soldBy') {
        vehicle[key] = vehicle[key] && vehicle[key].title ? vehicle[key].title : vehicle[key];
      }
    }

    vehicle.key = vehicle.entityId;

    return vehicle;
  });

  const onChange = (pagination, filters, sorter) => {
    const paramValue = (Array.isArray(sorter) ? sorter : [sorter])
      .filter((i) => i.order)
      .map((i) => `${i.columnKey}-${i.order}`);

    setParam(SORT_PARAM, paramValue.join(','));
  };

  return (
    <ScTableWrapper>
      {!views || !data ? (
        <RowResultsLoader />
      ) : (
        <Table
          columns={columns as any}
          onChange={onChange}
          dataSource={data}
          scroll={{ x: '100%' }}
          pagination={false}
        />
      )}
    </ScTableWrapper>
  );
};

export default ScResultView;
