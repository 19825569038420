import { range } from 'lodash';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { PAGE_SIZE } from '@hypercharge/digitaldealer-commons/lib/constants';

// done with http://danilowoz.com/create-content-loader/
const HorizontalCardLoader = () => (
  <ContentLoader height={274} width={850} speed={6} primaryColor="#f4f4f6" secondaryColor="#ecebeb">
    <rect x="308" y="60" rx="0" ry="0" width="545" height="30" />
    <rect x="308" y="120" rx="0" ry="0" width="545" height="30" />
    <rect x="308" y="180" rx="0" ry="0" width="545" height="30" />
    <rect x="0" y="60" rx="0" ry="0" width="300" height="200" />
    <rect x="648" y="235" rx="0" ry="0" width="97" height="32" />
    <rect x="751" y="235" rx="0" ry="0" width="97" height="32" />
    <rect x="0" y="10" rx="0" ry="0" width="499" height="19" />
    <rect x="0" y="35" rx="0" ry="0" width="326" height="16" />
  </ContentLoader>
);

const HorizontalResultsLoader = () => {
  return (
    <>
      {range(PAGE_SIZE).map((_, index) => (
        <HorizontalCardLoader key={`results-loader-${index}`} />
      ))}
    </>
  );
};

export default HorizontalResultsLoader;
