import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

// This checkbox exists because Antd's Checkbox spends ~3ms per checkbox,
// which is not acceptable in the aggregations sidebar

const ScInput = styled.input`
  cursor: pointer;
`;

export const Checkbox = ({ children, className, ...otherProps }: PropsWithChildren<any>) => {
  return (
    <label className={className}>
      <ScInput type="checkbox" {...otherProps} />
      {children}
    </label>
  );
};
