import { history } from '@hypercharge/digitaldealer-commons/lib/providers/router/history';
import {
  TEXT_PARAM,
  useSearchParams
} from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchParamsProvider';
import { Input } from 'antd';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const ScInputContainer = styled.div`
  text-align: center;

  @media print {
    display: none;
  }
`;
const ScInput = styled(Input)`
  max-width: 400px;
  padding: 0 !important;
  border-color: #31415a !important;
  border-radius: 3px !important;
  top: -6px;
  overflow: hidden;
  
  &.ant-input-affix-wrapper-focused {
    box-shadow: none !important;
    border-radius: 3px !important;
    
    .ant-input-suffix {
      background-color: #fafafa;
      border-radius: 0 3px 3px 0 !important;
    }
  }
  
  .ant-input-suffix {
    padding: 0 10px 0 0;
    background-color: #31415a;
    margin-left: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  input {
    padding: 6.5px 11px !important;
    background-color: #31415a;
    border: 0;
    border-radius: 0 !important;
    color: #fafafa;
    ::placeholder {
      color: #fafafa;
    }

    :focus {
      background-color: #fafafa;
      box-shadow: none !important;
      color: #222;
      outline: none;
      border-radius: 0 !important;

      ::placeholder {
        color: #888;
      }
    }
  }
`;

// Scenarios that should be considered when changing this component:
// * Page refresh should correctly initialize the input
// * Browser back should correctly change the input
// Bonus points to the one that makes tests for this
// - Ivo 30/Jul/19

const AppSearch = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const { setParam, params } = useSearchParams();
  const [inputValue, setInputValue] = useState<string | undefined>(params.textQuery);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    setInputValue(params.textQuery);
  }, [params.textQuery]);

  const search = (e: ChangeEvent<HTMLInputElement>) => {
    // sometimes we want to make an autocomplete request to the backend
    // every few hundred milliseconds, for that lodash has a debounce function.
    // Here we chose to do something only after Xms have passed since the use last
    // typed. - Ivo 30/Jul/19
    timeoutRef.current = setTimeout(() => {
      setParam(TEXT_PARAM, e.target.value);
    }, 400);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current);
    }

    e.persist();
    setInputValue(e.target.value);
    search(e);

    if (history.location.pathname !== '/') {
      history.push({ pathname: '/' });
    }
  };

  return (
    <ScInputContainer className={className}>
      <ScInput
        allowClear
        value={inputValue}
        onChange={onInputChange}
        placeholder={t('BROWSE__SEARCH_FILTER')}
        size="large"
      />
    </ScInputContainer>
  );
};

const StyledAppSearch = styled(AppSearch)`
  margin: 0 5px;
  flex: 1;
`;

export { StyledAppSearch };
export default AppSearch;
