import { Definition, Property } from '@hypercharge/cms/lib/types';
import {
  VEHICLE_LOCATION_DEFINITION_ID,
  VEHICLE_COLOR_DEFINITION_ID,
  VEHICLE_DEFINITION_ID,
  VEHICLE_ENGINE_DEFINITION_ID,
  VEHICLE_FINISH_DEFINITION_ID,
  VEHICLE_FUEL_DEFINITION_ID,
  VEHICLE_MAKE_DEFINITION_ID,
  VEHICLE_MODEL_YEAR_DEFINITION_ID,
  VEHICLE_PAINT_TYPE_DEFINITION_ID,
  VEHICLE_PRODUCT_LINE_DEFINITION_ID,
  VEHICLE_PURPOSE_DEFINITION_ID,
  VEHICLE_SILHOUETTE_DEFINITION_ID,
  VEHICLE_SPEC_DEFINITION_ID,
  VEHICLE_TRANSMISSION_DEFINITION_ID,
  VEHICLE_TRIM_DEFINITION_ID,
  VEHICLE_UPHOLSTERY_DEFINITION_ID
} from '@hypercharge/digitaldealer-commons/lib/constants';
import {
  failOnNullOrError,
  hyperfetch,
  json
} from '@hypercharge/digitaldealer-commons/lib/utils/httpClient';
import { compact, flatMap, get, keyBy, mapValues } from 'lodash';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Status } from '../../utils/types';
import { useSplash } from '../splash/SplashProvider';

type DefinitionInfo = {
  definition: Definition;
  propertyMap: { [propertyId: string]: Property };
};
type DefinitionInfoMap = {
  [definitionId: string]: DefinitionInfo;
};
export type DefinitionMap = { [definitionId: string]: Definition };
export type PropertyMap = { [definitionId: string]: { [propertyId: string]: Property } };

type ContextValue = {
  definitions?: DefinitionMap;
  properties?: PropertyMap;
  status: Status;
};

const SPLASH_KEY = 'cms-definitions-provider';
export const CmsDefinitionsContext = createContext<ContextValue | undefined>(undefined);

const getProperties = (definition: Definition): Property[] =>
  compact(flatMap(get(definition, 'sections', []), 'properties'));

export const CmsDefinitionsProvider = (props: PropsWithChildren<any>) => {
  const [definitions, setDefinitions] = useState<DefinitionMap>();
  const [properties, setProperties] = useState<PropertyMap>();
  const [status, setStatus] = useState(Status.Idle);
  const { addLoader, removeLoader } = useSplash();

  if (status !== Status.Success) {
    addLoader(SPLASH_KEY);
  }

  useEffect(() => {
    if (status === Status.Success) {
      removeLoader(SPLASH_KEY);
    }
  }, [removeLoader, status]);

  useEffect(() => {
    if (definitions == null) {
      setStatus(Status.Loading);
      fetchData()
        .then((definitionMap: DefinitionInfoMap) => {
          const newDefinitions = mapValues(definitionMap, di => di.definition);
          const newProperties = mapValues(definitionMap, di => di.propertyMap);
          setDefinitions(newDefinitions);
          setProperties(newProperties);
          setStatus(Status.Success);
        })
        .catch(err => {
          console.log(err);
          setStatus(Status.Error);
        });
    }
  }, [definitions]);

  const value = useMemo(
    () => ({
      definitions,
      properties,
      status
    }),
    [definitions, properties, status]
  );

  return <CmsDefinitionsContext.Provider value={value} {...props} />;
};

export const useCmsDefinitions = (): ContextValue => {
  const value = useContext(CmsDefinitionsContext);
  if (value === undefined) {
    throw new Error('useCmsDefinitions must be used inside a CmsDefinitionsProvider');
  }
  return value;
};

//
//
// Utilities
//
//

const fetchData = async (): Promise<DefinitionInfoMap> =>
  failOnNullOrError(
    hyperfetch<Definition[]>('/api/private/cms/definitions', {
      method: 'POST',
      body: json({
        definitionIds: [
          VEHICLE_COLOR_DEFINITION_ID,
          VEHICLE_DEFINITION_ID,
          VEHICLE_ENGINE_DEFINITION_ID,
          VEHICLE_MAKE_DEFINITION_ID,
          VEHICLE_FUEL_DEFINITION_ID,
          VEHICLE_PRODUCT_LINE_DEFINITION_ID,
          VEHICLE_PURPOSE_DEFINITION_ID,
          VEHICLE_SPEC_DEFINITION_ID,
          VEHICLE_TRANSMISSION_DEFINITION_ID,
          VEHICLE_TRIM_DEFINITION_ID,
          VEHICLE_MODEL_YEAR_DEFINITION_ID,
          VEHICLE_FINISH_DEFINITION_ID,
          VEHICLE_SILHOUETTE_DEFINITION_ID,
          VEHICLE_UPHOLSTERY_DEFINITION_ID,
          VEHICLE_PAINT_TYPE_DEFINITION_ID,
          VEHICLE_LOCATION_DEFINITION_ID
        ]
      })
    })
  ).then((data: Definition[]): DefinitionInfoMap => toDefinitionMap(data));

const toDefinitionMap = (definitions: Definition[]) => {
  const definitionMap: DefinitionInfoMap = {};
  for (const definition of definitions) {
    definitionMap[definition.definitionId] = {
      definition,
      propertyMap: keyBy(getProperties(definition), 'propertyId')
    };
  }
  return definitionMap;
};
