import { Property, PropertyTypes } from '@hypercharge/cms/lib/types';
import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import { Spec } from '@hypercharge/digitaldealer-commons/lib/utils/types';
import { TFunction } from 'i18next';
import { get } from 'lodash';
import React, { ReactNode } from 'react';
import { MdCheck } from 'react-icons/md';
import styled from 'styled-components/macro';
import { PropertyMap } from '../../../context/cms/CmsDefinitionsProvider';
import { getDateDisplay, getNumberDisplay } from '../../../utils/cms';
import { IS_MARGIN_VEHICLE_PROPERTY_ID } from '@hypercharge/digitaldealer-commons/lib/constants';
import { getTranslatedPropertyValue } from '@hypercharge/digitaldealer-commons/lib/utils/helpers';
import { DETAILS_SPECS, FALLBACK_LANGUAGE } from '../../../utils/constants';

export const ScMdCheck = styled(MdCheck)`
  color: #1f9c00;
  font-size: 1.3rem;
  margin-right: 0.5rem;
`;

export type TableSpec = {
  id: string;
  labelId: string;
  value: string | ReactNode;
};

export const getTableSpecs = (
  properties: PropertyMap,
  details: { propertyId: string }[],
  vehicle: Vehicle,
  language: string,
  t: TFunction
) => {
  const tableDetails: TableSpec[] = [];

  for (let { propertyId } of details) {
    const property: Property | undefined = get(properties, ['vehicle', propertyId]);

    const stdProperty = DETAILS_SPECS.find((spec) => spec.propertyId === propertyId);
    const format = stdProperty?.format;

    if (property != null) {
      let value: string = vehicle[propertyId].toString();

      if (property.type === PropertyTypes.entity) {
        value = getTranslatedPropertyValue(vehicle[propertyId], 'title', language);
      } else if (property.type === PropertyTypes.number) {
        value = getNumberDisplay(value, property.meta, language);
      } else if (
        property.type === PropertyTypes.checkbox &&
        property.propertyId === IS_MARGIN_VEHICLE_PROPERTY_ID
      ) {
        value = value ? t('PROP_MARGIN_YES') : t('PROP_MARGIN_NO');
      } else if (property.type === PropertyTypes.date) {
        value = getDateDisplay(vehicle[propertyId], property.meta);
      } else if (property.type === PropertyTypes.checkbox) {
        value = vehicle[propertyId] ? '+' : '-';
      }

      if (format && typeof format !== 'string') {
        value = format(value, t);
      }

      tableDetails.push({
        labelId: get(property.labels, language) || property.labels[FALLBACK_LANGUAGE],
        value,
        id: propertyId,
      });
    }
  }

  return tableDetails;
};

export const getTableOptions = (
  properties: PropertyMap,
  options: Spec[],
  vehicle: Vehicle
): TableSpec[] => {
  const tableOptions: TableSpec[] = [];

  for (let { definitionId, propertyId, field } of options) {
    const property: Property | undefined = get(properties, [definitionId, propertyId]);

    if (property != null) {
      for (let opt of get(vehicle, field)) {
        tableOptions.push({ id: `${field}_${opt}`, labelId: opt, value: <ScMdCheck /> });
      }
    }
  }

  return tableOptions;
};
