import { DateMeta, NumberMeta } from '@hypercharge/cms/lib/types';
import { LabelByLanguage } from '@hypercharge/digitaldealer-commons/lib/types/common';
import moment from 'moment';
import { FALLBACK_LANGUAGE } from './constants';

export const getTranslation = (
  labels: LabelByLanguage | undefined,
  language: string,
  fallback: string = ''
): string => {
  if (labels != null) {
    return labels[language] || labels[FALLBACK_LANGUAGE] || fallback;
  }

  return fallback;
};

export const getNumberDisplay = (
  value: any,
  { precision, units, useGrouping }: NumberMeta,
  language: string
) =>
  value !== '' && value != null && !isNaN(value)
    ? `${Number(value).toLocaleString([], {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
        useGrouping: !!useGrouping
      })} ${getTranslation(units, language)}`
    : '\u00a0';

export const getDateDisplay = (
  value: number | null,
  meta: DateMeta,
  specialFormat?: string
): string => {
  if (value != null) {
    // TODO i believe this is wrong when withTime is false;
    const format = specialFormat
      ? specialFormat
      : meta.withTime
      ? 'DD/MM/YYYY HH:mm'
      : 'DD/MM/YYYY';
    return moment(value).format(format);
  }

  return '\u00a0';
};

type ImageResizer = {
  width?: number;
  height?: number;
};

export const getImageUrl = (
  tenantId: string,
  imageId: string,
  sizeAttrs: ImageResizer = {}
): string => {
  const request = {
    key: `${tenantId}/${imageId}`,
    edits: {
      resize: {
        ...sizeAttrs,
        fit: 'inside',
        withoutEnlargement: true
      }
    }
  };
  return `${process.env.REACT_APP_IMAGES_SERVICE}/${btoa(JSON.stringify(request))}`;
};
