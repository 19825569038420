import {
  ANNOUNCED_DELIVERY_AT_PROPERTY_ID,
  COLOR_PROPERTY_ID,
  FINISH_PROPERTY_ID,
  FIRST_REGISTRATION_DATE_PROPERTY_ID,
  FUEL_PROPERTY_ID,
  INTERIOR_COLOR_PROPERTY_ID,
  KILOMETRAGE_PROPERTY_ID,
  LOCATION_PROPERTY_ID,
  MAKE_PROPERTY_ID,
  MODEL_YEAR_PROPERTY_ID,
  OPTION_REFERENCES_PROPERTY_ID,
  OPTIONS_100_PROPERTY_ID,
  OPTIONS_PROPERTY_ID,
  PRODUCT_LINE_PROPERTY_ID,
  PURCHASE_INVOICE_DATE_PROPERTY_ID,
  PURPOSE_PROPERTY_ID,
  SILHOUETTE_PROPERTY_ID,
  SPEC_ENGINE_SIZE_PROPERTY_ID,
  SPEC_EURONORM_PROPERTY_ID,
  SPEC_MAX_POWER_PROPERTY_ID,
  SPEC_MAX_TORQUE_PROPERTY_ID,
  SPEC_PROPERTY_ID,
  STATUS_PROPERTY_ID,
  TRANSMISSION_PROPERTY_ID,
  TRIM_PROPERTY_ID,
  VEHICLE_COLOR_DEFINITION_ID,
  VEHICLE_DEFINITION_ID,
  VEHICLE_FINISH_DEFINITION_ID,
  VEHICLE_FUEL_DEFINITION_ID,
  VEHICLE_LOCATION_DEFINITION_ID,
  VEHICLE_MAKE_DEFINITION_ID,
  VEHICLE_PAINT_TYPE_DEFINITION_ID,
  VEHICLE_PAINT_TYPE_PROPERTY_ID,
  VEHICLE_PURPOSE_DEFINITION_ID,
  VEHICLE_SILHOUETTE_DEFINITION_ID,
  VEHICLE_SPEC_DEFINITION_ID,
  VEHICLE_TRANSMISSION_DEFINITION_ID,
  VEHICLE_UPHOLSTERY_DEFINITION_ID,
  VEHICLE_UPHOLSTERY_PROPERTY_ID,
  VERSION_PROPERTY_ID,
  VIN_PROPERTY_ID,
  WARRANTY_PROPERTY_ID,
  CONDITION_PROPERTY_ID,
  COMPUTED_PRICE_PROPERTY_ID,
  PRICE_FILTER_MAX_VALUE,
  IS_MARGIN_VEHICLE_PROPERTY_ID,
} from '@hypercharge/digitaldealer-commons/lib/constants';
import { Spec } from '@hypercharge/digitaldealer-commons/lib/utils/types';
import { SortFilter, SortOrder, AggregationType } from '@hypercharge/portal-lambda-sdk/lib/types';
import { getFormattedPrice } from '@hypercharge/digitaldealer-commons/lib/utils/helpers';

export const FALLBACK_LANGUAGE = 'en';
export const LANGUAGE_SELECTION = {
  fr: 'Français',
  nl: 'Nederlands',
  en: 'English',
};
export const OLDEST_DELIVERY_SORT_KEY = 'oldestDelivery';
export const NEWEST_DELIVERY_SORT_KEY = 'newestDelivery';
export const OLDEST_INVOICE_DATE_SORT_KEY = 'oldestPurchaseInvoiceDate';
export const NEWEST_INVOICE_DATE_SORT_KEY = 'newestPurchaseInvoiceDate';
export const COMPUTED_PRICE_ASC = 'computedPriceAsc';
export const COMPUTED_PRICE_DESC = 'computedPriceDesc';
export const MAKE_ASC = 'makeAsc';
export const MAKE_DESC = 'makeDesc';
export const PRODUCT_LINE_ASC = 'productLineAsc';
export const PRODUCT_LINE_DESC = 'productLineDesc';
export const DEFAULT_TABLE_SORT = 'make-ascend,productLine-ascend';
export const DEFAULT_CARD_SORT = OLDEST_INVOICE_DATE_SORT_KEY;

export const SORT_BY_OPTIONS: { [sortKey: string]: SortFilter } = {
  [OLDEST_INVOICE_DATE_SORT_KEY]: {
    field: PURCHASE_INVOICE_DATE_PROPERTY_ID,
    order: SortOrder.asc,
  },
  [NEWEST_INVOICE_DATE_SORT_KEY]: {
    field: PURCHASE_INVOICE_DATE_PROPERTY_ID,
    order: SortOrder.desc,
  },
  [OLDEST_DELIVERY_SORT_KEY]: {
    field: ANNOUNCED_DELIVERY_AT_PROPERTY_ID,
    order: SortOrder.asc,
  },
  [NEWEST_DELIVERY_SORT_KEY]: {
    field: ANNOUNCED_DELIVERY_AT_PROPERTY_ID,
    order: SortOrder.desc,
  },
  [COMPUTED_PRICE_ASC]: {
    field: COMPUTED_PRICE_PROPERTY_ID,
    order: SortOrder.asc,
  },
  [COMPUTED_PRICE_DESC]: {
    field: COMPUTED_PRICE_PROPERTY_ID,
    order: SortOrder.desc,
  },
  [MAKE_ASC]: {
    field: MAKE_PROPERTY_ID,
    order: SortOrder.asc,
  },
  [MAKE_DESC]: {
    field: MAKE_PROPERTY_ID,
    order: SortOrder.desc,
  },
  [PRODUCT_LINE_ASC]: {
    field: PRODUCT_LINE_PROPERTY_ID,
    order: SortOrder.asc,
  },
  [PRODUCT_LINE_DESC]: {
    field: PRODUCT_LINE_PROPERTY_ID,
    order: SortOrder.desc,
  },
};
export const VISIBLE_SORT_OPTIONS = [
  OLDEST_INVOICE_DATE_SORT_KEY,
  NEWEST_INVOICE_DATE_SORT_KEY,
  OLDEST_DELIVERY_SORT_KEY,
  NEWEST_DELIVERY_SORT_KEY,
  COMPUTED_PRICE_ASC,
  COMPUTED_PRICE_DESC,
  MAKE_ASC,
  MAKE_DESC,
  PRODUCT_LINE_ASC,
  PRODUCT_LINE_DESC,
];

export const AVAILABLE_AGGREGATIONS = [
  {
    field: `${CONDITION_PROPERTY_ID}.title`,
    propertyId: CONDITION_PROPERTY_ID,
    type: AggregationType.term,
  },
  {
    field: `${STATUS_PROPERTY_ID}.title`,
    propertyId: STATUS_PROPERTY_ID,
    type: AggregationType.term,
  },
  { field: `${MAKE_PROPERTY_ID}.title`, propertyId: MAKE_PROPERTY_ID, type: AggregationType.term },
  {
    field: `${PURPOSE_PROPERTY_ID}.title`,
    propertyId: PURPOSE_PROPERTY_ID,
    type: AggregationType.term,
  },
  {
    field: `${PRODUCT_LINE_PROPERTY_ID}.title`,
    propertyId: PRODUCT_LINE_PROPERTY_ID,
    type: AggregationType.term,
  },
  { field: `${FUEL_PROPERTY_ID}.title`, propertyId: FUEL_PROPERTY_ID, type: AggregationType.term },
  {
    field: `${TRANSMISSION_PROPERTY_ID}.title`,
    propertyId: TRANSMISSION_PROPERTY_ID,
    type: AggregationType.term,
  },
  { field: VERSION_PROPERTY_ID, propertyId: VERSION_PROPERTY_ID, type: AggregationType.term },
  {
    field: COMPUTED_PRICE_PROPERTY_ID,
    propertyId: COMPUTED_PRICE_PROPERTY_ID,
    type: AggregationType.stats,
    min: 0,
    max: PRICE_FILTER_MAX_VALUE,
    step: 1000,
    displayFormatter: (value: number, language: string): string =>
      `${getFormattedPrice(value, language)}${value === PRICE_FILTER_MAX_VALUE ? '+' : ''}`,
    labels: {
      en: 'Price',
      nl: 'Prijs',
      fr: 'Prix',
    },
  },
  {
    field: `${COLOR_PROPERTY_ID}.title`,
    propertyId: COLOR_PROPERTY_ID,
    type: AggregationType.term,
  },
  { field: `${TRIM_PROPERTY_ID}.title`, propertyId: TRIM_PROPERTY_ID, type: AggregationType.term },
  {
    field: OPTION_REFERENCES_PROPERTY_ID,
    propertyId: OPTION_REFERENCES_PROPERTY_ID,
    type: AggregationType.term,
  },
  {
    field: `${LOCATION_PROPERTY_ID}.title`,
    propertyId: LOCATION_PROPERTY_ID,
    type: AggregationType.term,
  },
];

export const BROWSE_SPECS: Spec[] = [
  {
    definitionId: VEHICLE_DEFINITION_ID,
    propertyId: VERSION_PROPERTY_ID,
    field: VERSION_PROPERTY_ID,
    labelId: 'PROP_VERSION',
  },
  {
    definitionId: VEHICLE_FUEL_DEFINITION_ID,
    field: `${FUEL_PROPERTY_ID}.title`,
    propertyId: 'title',
    labelId: 'PROP_FUEL',
  },
  {
    definitionId: VEHICLE_COLOR_DEFINITION_ID,
    field: `${COLOR_PROPERTY_ID}.title`,
    propertyId: 'title',
    labelId: 'PROP_COLOR',
  },
  {
    definitionId: VEHICLE_DEFINITION_ID,
    field: FIRST_REGISTRATION_DATE_PROPERTY_ID,
    propertyId: FIRST_REGISTRATION_DATE_PROPERTY_ID,
    labelId: 'PROP_FIRST_REGISTRATION',
    format: 'DD/MM/YYYY',
  },
  {
    definitionId: VEHICLE_LOCATION_DEFINITION_ID,
    field: `${LOCATION_PROPERTY_ID}.title`,
    propertyId: 'title',
    labelId: 'PROP_LOCATION',
  },
];

export const DETAILS_SPECS: Spec[] = [
  {
    definitionId: VEHICLE_MAKE_DEFINITION_ID,
    propertyId: 'title',
    field: `${MAKE_PROPERTY_ID}.title`,
    labelId: 'PROP_MAKE',
  },
  ...BROWSE_SPECS,
  {
    definitionId: VEHICLE_PAINT_TYPE_DEFINITION_ID,
    field: `${VEHICLE_PAINT_TYPE_PROPERTY_ID}.title`,
    propertyId: 'title',
    labelId: 'PROP_PAINT_TYPE',
  },
  {
    definitionId: VEHICLE_COLOR_DEFINITION_ID,
    field: `${INTERIOR_COLOR_PROPERTY_ID}.title`,
    propertyId: 'title',
    labelId: 'PROP_INTERIOR_COLOR',
  },
  {
    definitionId: VEHICLE_UPHOLSTERY_DEFINITION_ID,
    field: `${VEHICLE_UPHOLSTERY_PROPERTY_ID}.title`,
    propertyId: 'title',
    labelId: 'PROP_UPHOLSTERY',
  },
  {
    definitionId: VEHICLE_FINISH_DEFINITION_ID,
    propertyId: 'title',
    field: `${FINISH_PROPERTY_ID}.title`,
    labelId: 'PROP_FINISH',
  },
  {
    definitionId: VEHICLE_SILHOUETTE_DEFINITION_ID,
    propertyId: 'title',
    field: `${SILHOUETTE_PROPERTY_ID}.title`,
    labelId: 'PROP_SILHOUETTE',
  },
  {
    definitionId: VEHICLE_SPEC_DEFINITION_ID,
    propertyId: 'title',
    field: `${SPEC_PROPERTY_ID}.title`,
    labelId: 'PROP_SPEC',
  },
  {
    definitionId: VEHICLE_DEFINITION_ID,
    propertyId: VIN_PROPERTY_ID,
    field: VIN_PROPERTY_ID,
    labelId: 'PROP_VIN',
  },
  {
    definitionId: VEHICLE_PURPOSE_DEFINITION_ID,
    propertyId: 'title',
    field: `${PURPOSE_PROPERTY_ID}.title`,
    labelId: 'PROP_PURPOSE',
  },
  {
    definitionId: VEHICLE_DEFINITION_ID,
    field: MODEL_YEAR_PROPERTY_ID,
    propertyId: MODEL_YEAR_PROPERTY_ID,
    labelId: 'PROP_MODEL_YEAR',
  },
  {
    definitionId: VEHICLE_TRANSMISSION_DEFINITION_ID,
    propertyId: 'title',
    field: `${TRANSMISSION_PROPERTY_ID}.title`,
    labelId: 'PROP_TRANSMISSION',
  },
  {
    definitionId: VEHICLE_SPEC_DEFINITION_ID,
    field: `${SPEC_PROPERTY_ID}.${SPEC_ENGINE_SIZE_PROPERTY_ID}`,
    propertyId: SPEC_ENGINE_SIZE_PROPERTY_ID,
    labelId: 'PROP_ENGINE_SIZE',
  },
  {
    definitionId: VEHICLE_SPEC_DEFINITION_ID,
    field: `${SPEC_PROPERTY_ID}.${SPEC_EURONORM_PROPERTY_ID}`,
    propertyId: SPEC_EURONORM_PROPERTY_ID,
    labelId: 'PROP_EURONORM',
  },
  {
    definitionId: VEHICLE_SPEC_DEFINITION_ID,
    field: `${SPEC_PROPERTY_ID}.${SPEC_MAX_POWER_PROPERTY_ID}`,
    propertyId: SPEC_MAX_POWER_PROPERTY_ID,
    labelId: 'PROP_MAX_POWER',
    format: (val, t) => {
      return `${val} ${t('PROP_MAX_POWER_UNIT')}`;
    },
  },
  {
    definitionId: VEHICLE_SPEC_DEFINITION_ID,
    field: `${SPEC_PROPERTY_ID}.${SPEC_MAX_TORQUE_PROPERTY_ID}`,
    propertyId: SPEC_MAX_TORQUE_PROPERTY_ID,
    labelId: 'PROP_MAX_TORQUE',
    format: (val) => `${val} kW`,
  },
  {
    definitionId: VEHICLE_DEFINITION_ID,
    field: KILOMETRAGE_PROPERTY_ID,
    propertyId: KILOMETRAGE_PROPERTY_ID,
    labelId: 'PROP_KILOMETRAGE',
  },
  {
    definitionId: VEHICLE_DEFINITION_ID,
    field: IS_MARGIN_VEHICLE_PROPERTY_ID,
    propertyId: IS_MARGIN_VEHICLE_PROPERTY_ID,
    labelId: 'PROP_MARGIN',
    format: (val) => `${val}`,
  },
  {
    definitionId: VEHICLE_DEFINITION_ID,
    field: WARRANTY_PROPERTY_ID,
    propertyId: WARRANTY_PROPERTY_ID,
    labelId: 'PROP_WARRANTY',
  },
];

export const OPTIONS_SPECS: Spec[] = [
  {
    definitionId: VEHICLE_DEFINITION_ID,
    propertyId: OPTIONS_PROPERTY_ID,
    field: OPTIONS_PROPERTY_ID,
    labelId: 'PROP_OPTIONS',
  },
  {
    definitionId: VEHICLE_DEFINITION_ID,
    propertyId: OPTIONS_100_PROPERTY_ID,
    field: OPTIONS_100_PROPERTY_ID,
    labelId: 'PROP_OPTIONS',
  },
];

export const BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 1024,
};

export const getFormattedNumber = (n: number, locale: string = 'en') =>
  Intl.NumberFormat(locale, {
    style: 'decimal',
  }).format(n);
