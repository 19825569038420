import { Button } from 'antd';
import { Icon } from '@ant-design/compatible';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import AggregationsDrawer from './AggregationsDrawer';

const ScButton = styled(Button)`
  z-index: 100;
  bottom: 10px;
  top: unset;
  width: calc(100vw - 20px);
  left: 10px;
  position: sticky !important;
`;

const MobileAggregations = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <ScButton type={'primary'} size={'large'} onClick={() => setVisible(true)}>
        <Icon type="filter" />
        {t('BROWSE__TOGGLE_FILTERS')}
      </ScButton>
      <AggregationsDrawer visible={visible} onClose={() => setVisible(false)} />
    </>
  );
};

export default MobileAggregations;
