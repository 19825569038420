import { useSearchAggregations } from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchAggregationsProvider';
import { history } from '@hypercharge/digitaldealer-commons/lib/providers/router/history';
import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import { Carousel } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useSearchResults } from '../../context/search/results';
import { useTenant } from '../../context/tenant/TenantProvider';
import { getImageUrl } from '../../utils/cms';
import BrowseSpecifications from '../../vehicle/components/specifications/BrowseSpecifications';
import VehicleActions from '../../vehicle/components/VehicleActions';
import VehicleTopInfo from '../../vehicle/components/VehicleTopInfo';
import VehicleTitle from '../../vehicle/components/VehicleTitle';

type Props = {
  vehicle: Vehicle;
};

const ScContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-rows: repeat(auto-fit, min-content);
  margin: 15px 0;
`;
const ScTitleArea = styled.div``;
const ScVehicleTopInfo = styled(VehicleTopInfo)`
  margin-bottom: 3px;
`;
const ScActionsArea = styled.div``;
const ScTitleLink = styled(Link)`
  color: #162133;
  font-size: 1.2rem;
`;
const ScDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const ScFlexWrapper = styled.div`
  width: 300px;
  height: 200px;
  display: flex;
  align-items: center;
`;

const ScCarousel = styled(Carousel)`
  width: 300px;
  min-height: 100px;

  img {
    cursor: pointer;
  }

  .slick-dots.slick-dots li button,
  .slick-dots.slick-dots li.slick-active button {
    background: #162133;
  }

  .slick-slide {
    text-align: center;

    img {
      width: auto !important;
      max-width: 100%;
      max-height: 200px;
    }
  }
`;
const ScBrowseSpecifications = styled(BrowseSpecifications)`
  flex: 1;
  min-width: 50%;
`;

const ScTop = styled.div``;

const ResultCard = ({ vehicle }: Props) => {
  const { replaceVehicle, getResults } = useSearchResults();
  const { tenant } = useTenant();
  const { updateAggregations } = useSearchAggregations();

  const images: string[] = useMemo(() => {
    return vehicle && !isEmpty(vehicle.pictures) && tenant
      ? vehicle.pictures.map(imageId => getImageUrl(tenant.id, imageId, { width: 300 }))
      : [tenant.defaultImage || '/images/coming-soon.webp'];
  }, [tenant, vehicle]);

  const goToVehiclePage = useCallback(() => {
    history.push(`/vehicle/${vehicle.entityId}`);
  }, [vehicle.entityId]);

  return (
    <ScContainer>
      <ScTop>
        <ScTitleArea>
          <ScTitleLink to={`/vehicle/${vehicle.entityId}`}>
            <VehicleTitle isList={true} vehicle={vehicle} />
          </ScTitleLink>
        </ScTitleArea>

        <ScVehicleTopInfo vehicle={vehicle} />
      </ScTop>

      <ScDetails>
        <ScFlexWrapper>
          <div>
            <ScCarousel>
              {images != null
                ? images.map(i => (
                    <img
                      alt={vehicle.title}
                      src={i}
                      key={`${vehicle.entityId}_${i}`}
                      onClick={goToVehiclePage}
                    />
                  ))
                : null}
            </ScCarousel>
          </div>
        </ScFlexWrapper>

        <ScBrowseSpecifications vehicle={vehicle} />
      </ScDetails>

      <ScActionsArea>
        <VehicleActions
          updateAggregations={updateAggregations}
          vehicle={vehicle}
          setVehicle={replaceVehicle}
          getResultAfterSell={getResults}
        />
      </ScActionsArea>
    </ScContainer>
  );
};

export default ResultCard;
