import React from 'react';
import styled from 'styled-components';
import LanguageSelector from './LanguageSelector';
import { ScWrapper } from './styles';

const now = new Date();
const currentYear = now.getFullYear();

const ScContainer = styled.div`
  margin-top: 1rem;
  flex: 0 0 auto;
`;
const ScLanguageSelector = styled(LanguageSelector)`
  margin-left: 1rem;
  @media print {
    display: none;
  }
`;
const ScContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-top: 1px solid #dfdfdf;
  padding: 1rem 5px;
`;

const Footer = ({ fullWidth = false }: { fullWidth?: boolean }) => (
  <ScContainer>
    <ScWrapper fullWidth={fullWidth}>
      <ScContent>
        © {currentYear} Hypercharge <ScLanguageSelector />
      </ScContent>
    </ScWrapper>
  </ScContainer>
);

export default Footer;
