import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCmsDefinitions } from '../../../context/cms/CmsDefinitionsProvider';
import { ScContainer, ScRow, ScTdLabel, ScTdValue } from './styles';
import { getTableSpecs, TableSpec } from './utils';
import { useTenant } from '../../../context/tenant/TenantProvider';

type Props = {
  vehicle: Vehicle;
};

const DetailsSpecifications = ({ vehicle }: Props) => {
  const { properties } = useCmsDefinitions();
  const { t, i18n } = useTranslation();
  const { tenant } = useTenant();

  if (properties == null) {
    throw new Error('Inconsistent state, properties cannot be null');
  }

  const details: TableSpec[] = useMemo(() => {
    const availableDetails = tenant.intranet.specificationList?.filter(
      (spec) => !!get(vehicle, spec.propertyId)
    );

    if (!availableDetails) {
      return [];
    }

    return getTableSpecs(properties, availableDetails, vehicle, i18n.language, t);
  }, [properties, i18n.language, vehicle, t, tenant.intranet.specificationList]);

  return (
    <ScContainer>
      {details.map(({ id, labelId, value }) => (
        <ScRow key={`${vehicle.entityId}_${id}`}>
          <ScTdLabel>{t(labelId)}</ScTdLabel>
          <ScTdValue>{value}</ScTdValue>
        </ScRow>
      ))}
    </ScContainer>
  );
};

export default DetailsSpecifications;
