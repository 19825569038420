import { useSearchAggregations } from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchAggregationsProvider';
import { useSearchParams } from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchParamsProvider';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiRefreshCcw } from 'react-icons/fi';
import { Status } from '../../utils/types';
import { AggregationsContent } from './AggregationsContent';
import { ScLoadingOverlay, ScResetContainer, ScResetContent, ScSider } from './styles';

const ResetButton = () => {
  const { t } = useTranslation();
  const { clearAll } = useSearchParams();

  return (
    <ScResetContainer>
      <Button onClick={clearAll}>
        <ScResetContent>
          <FiRefreshCcw />
          {t('BROWSE__CLEAR_ACTIVE_FILTERS')}
        </ScResetContent>
      </Button>
    </ScResetContainer>
  );
};

const AggregationsSidebar = () => {
  const { aggregations, status } = useSearchAggregations();
  const { aggParams, params } = useSearchParams();

  return (
    <ScSider width={300}>
      {aggregations == null ? null : (
        <>
          <ScLoadingOverlay show={status === Status.Loading} />
          {aggParams.length > 0 || params.textQuery ? <ResetButton /> : null}
          <AggregationsContent aggregations={aggregations} />
        </>
      )}
    </ScSider>
  );
};

export default AggregationsSidebar;
