import { Button } from 'antd';
import styled from 'styled-components/macro';

export const ScContainer = styled.div`
  margin: auto;
  margin-top: 8rem;
  max-width: 320px;
  text-align: center;
`;
export const ScButton = styled(Button)`
  width: 100%;
`;
export const ScTitle = styled.div`
  font-size: 1.5rem;
  margin: 1.5rem 0;
`;
