import styled from 'styled-components/macro';

export const ScContainer = styled.div`
  font-size: 0.9rem;
`;
export const ScRow = styled.div`
  &:nth-child(odd) {
    background-color: #f3f3f3;
  }
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  gap: 15px;
  padding: 5px;
`;
export const ScTdLabel = styled.div`
  align-items: center;
  display: grid;
  justify-items: start;
`;
export const ScTdValue = styled.div`
  font-weight: 500;
  align-items: center;
  display: grid;
  justify-items: end;
`;
