import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import { Button, Carousel, message } from 'antd';
import { Icon } from '@ant-design/compatible';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';
import { Loading } from '../components/Loading';
import Paper from '../components/Paper';
import { useTenant } from '../context/tenant/TenantProvider';
import NotFound from '../layout/NotFound';
import { getImageUrl } from '../utils/cms';
import { BREAKPOINTS } from '../utils/constants';
import { fetchVehicle, fetchVehicleReservation, getDealStatus } from '../utils/vehicle';
import DetailsSpecifications from './components/specifications/DetailsSpecifications';
import OptionsSpecifications from './components/specifications/OptionsSpecifications';
import VehicleActions from './components/VehicleActions';
import VehicleStatusInfo from './components/VehicleDealInfo';
import VehicleOrderInfo from './components/VehicleOrderInfo';
import VehicleTopInfo from './components/VehicleTopInfo';
import VehicleTitle from './components/VehicleTitle';
import { VehicleReservation } from '@hypercharge/digitaldealer-commons/lib/types/common';

type MatchProps = {
  id: string;
};

const ScTitle = styled.div`
  color: #162133;
  font-size: 1.5rem;
  font-weight: 600;
`;
const ScContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 5px;
  @media (min-width: ${BREAKPOINTS.md}px) {
    flex-direction: row;
    padding: 30px;
  }
`;
const ScBody = styled.div`
  flex: 1;
  min-width: 50%;
`;
const ScAux = styled.div`
  min-width: 300px;
  min-height: 100px;
  margin-bottom: 10px;
  @media (min-width: ${BREAKPOINTS.md}px) {
    margin-left: 10px;
    min-width: 450px;
    min-height: 200px;
  }
`;
const ScVehicleTopInfo = styled(VehicleTopInfo)`
  margin: 5px 0 15px;
`;
const ScSection = styled.div`
  & + & {
    margin-top: 30px;
    overflow: hidden;
  }
`;
const ScSectionTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0 10px;
`;
const ScActions = styled.div`
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  @media print {
    display: none;
  }
`;
const ScLoading = styled(Loading)`
  width: 100%;
  height: 200px;
  @media (min-width: ${BREAKPOINTS.md}px) {
    height: 500px;
  }
`;

const ScCarousel = styled(Carousel)`
  img {
    cursor: pointer;
  }
  .slick-dots.slick-dots li button,
  .slick-dots.slick-dots li.slick-active button {
    background: #162133;
  }
`;

const ScVehicleImg = styled.img<{ isPrintable }>`
  @media print {
    ${props =>
      props.isPrintable
        ? `
      max-width: 300px;
      display: inherit !important;`
        : 'display: none !important;'};
  }
`;

const ScTop = styled.div``;

export const VehicleDetails = ({ match }: RouteComponentProps<MatchProps>) => {
  const { t } = useTranslation();
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [reservation, setReservation] = useState<VehicleReservation | null>(null);
  const [found, setFound] = useState(true);
  const [images, setImages] = useState<string[] | undefined>();
  const { tenant } = useTenant();

  useEffect(() => {
    fetchVehicle(match.params.id)
      .then(vehicle => {
        if (vehicle) {
          setVehicle(vehicle);
          setFound(true);

          fetchVehicleReservation(match.params.id)
            .then(res => {
              setReservation(res);
            })
            .catch(() => message.error(t('MESSAGE__ERROR')));
        } else {
          setFound(false);
        }
      })
      .catch(() => message.error(t('MESSAGE__ERROR')));
  }, [match.params.id, t]);

  useEffect(() => {
    if (vehicle != null) {
      const images: string[] = !isEmpty(vehicle.pictures)
        ? vehicle.pictures.map(imageId => getImageUrl(tenant.id, imageId))
        : [tenant.defaultImage || '/images/coming-soon.webp'];
      setImages(images);
    } else {
      setImages(undefined);
    }
  }, [tenant, vehicle]);

  // when changing routes users must start from the top
  useLayoutEffect(() => {
    if (vehicle == null) {
      window.scrollTo(0, 0);
    }
  });

  if (!found) {
    return <NotFound />;
  }

  return !vehicle ? (
    <ScLoading />
  ) : (
    <>
      <Helmet title={`[${vehicle.entityId}] ${vehicle.title}`} />
      <ScTop>
        <ScTitle>
          <VehicleTitle vehicle={vehicle} />
        </ScTitle>
        <ScVehicleTopInfo vehicle={vehicle} />
      </ScTop>
      <Paper>
        <ScActions>
          <Button onClick={() => window.print()}>
            <Icon type="printer" />
          </Button>
          <VehicleActions
            hideInfo={true}
            vehicle={vehicle}
            setVehicle={setVehicle}
            onChangeReservation={setReservation}
          />
        </ScActions>
        <ScContent>
          <ScBody>
            <ScSection>
              <ScSectionTitle>{t('VEHICLE__SPECIFICATIONS')}</ScSectionTitle>
              <DetailsSpecifications vehicle={vehicle} />
            </ScSection>
            <ScSection>
              <ScSectionTitle>{t('VEHICLE__OPTIONS')}</ScSectionTitle>
              <OptionsSpecifications vehicle={vehicle} />
            </ScSection>
            <ScSection>
              <ScSectionTitle>{t('VEHICLE__ORDER_INFO')}</ScSectionTitle>
              <VehicleOrderInfo vehicle={vehicle} />
            </ScSection>
            {getDealStatus(vehicle).isAvailable ? null : (
              <ScSection>
                <ScSectionTitle>{t('VEHICLE__DEAL')}</ScSectionTitle>
                <VehicleStatusInfo vehicle={vehicle} reservation={reservation} />
              </ScSection>
            )}
            {!!vehicle.description && (
              <ScSection>
                <ScSectionTitle>{t('VEHICLE__DESCRIPTION')}</ScSectionTitle>
                {vehicle.description}
              </ScSection>
            )}
          </ScBody>
          <ScAux>
            <VehicleCarousel vehicle={vehicle} images={images} />
          </ScAux>
        </ScContent>
      </Paper>
    </>
  );
};

const VehicleCarousel = ({ vehicle, images }: { vehicle: Vehicle; images?: string[] }) => {
  const imageList = images || [];
  const [isPreviewing, setIsPreviewing] = useState<boolean>(false);
  const [previewIndex, setPreviewIndex] = useState<number>(0);
  return (
    <div>
      <ScCarousel>
        {imageList.map((src: string, index: number) => (
          <ScVehicleImg
            isPrintable={index === 0}
            alt={vehicle.title}
            src={src}
            key={`${vehicle.entityId}_${src}`}
            onClick={() => {
              setPreviewIndex(index);
              setIsPreviewing(true);
            }}
          />
        ))}
      </ScCarousel>
      {isPreviewing && (
        <ImagePreview
          images={imageList}
          initialPreviewIndex={previewIndex}
          closeImagePreview={() => {
            setIsPreviewing(false);
          }}
        />
      )}
    </div>
  );
};

const ImagePreview = ({
  className,
  images,
  closeImagePreview,
  initialPreviewIndex
}: {
  className?: string;
  images: string[];
  closeImagePreview: () => void;
  initialPreviewIndex?: number;
}) => {
  const [previewIndex, setPreviewIndex] = useState<number>(initialPreviewIndex || 0);

  const showPreviousImage = useCallback(() => {
    setPreviewIndex(currentIndex => (currentIndex + images.length - 1) % images.length);
  }, [images.length]);

  const showNextImage = useCallback(() => {
    setPreviewIndex(currentIndex => (currentIndex + 1) % images.length);
  }, [images.length]);

  const prevImage = images[previewIndex - 1];
  const nextImage = images[previewIndex + 1];

  return (
    <div className={className}>
      <Lightbox
        mainSrc={images[previewIndex]}
        prevSrc={prevImage != null ? prevImage : undefined}
        nextSrc={nextImage != null ? nextImage : undefined}
        onCloseRequest={closeImagePreview}
        onMovePrevRequest={showPreviousImage}
        onMoveNextRequest={showNextImage}
      />
    </div>
  );
};
