import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import React from 'react';
import PricePreview from './PricePreview';
import styled from 'styled-components/macro';

const ScTopDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const VehicleTitle = ({vehicle, isList}: { vehicle: Vehicle, isList?: boolean }) => {
  const { make, productLine} = vehicle;

  return (
    <ScTopDiv>
      <div>
        {((make && make.title) || '').toUpperCase()} {productLine && productLine.title}
      </div>
      <PricePreview isList={isList} vehicle={vehicle} />
    </ScTopDiv>
  );
};

export default VehicleTitle;
