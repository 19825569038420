import { VEHICLE_DEFINITION_ID } from '@hypercharge/digitaldealer-commons/lib/constants';
import { getSearchResultsFactory } from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchResultsProvider';
import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import { SORT_BY_OPTIONS } from '../../utils/constants';

const { SearchResultsProvider, useSearchResults } = getSearchResultsFactory<Vehicle>(
  `/api/private/search/${VEHICLE_DEFINITION_ID}`,
  SORT_BY_OPTIONS,
  false
);

export { SearchResultsProvider, useSearchResults };
