import { VEHICLE_DEFINITION_ID } from '@hypercharge/digitaldealer-commons/lib/constants';
import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import {
  failOnNullOrError,
  failOnError,
  FetchResponse,
  HttpErrors,
  hyperfetch,
  json
} from '@hypercharge/digitaldealer-commons/lib/utils/httpClient';
import { isNil } from 'lodash';
import { Definition } from '@hypercharge/cms/lib/types';
import { ReservationResponseT, VehicleReservation } from '@hypercharge/digitaldealer-commons/lib/types/common';

export type DealStatus = {
  isAvailable: boolean;
  isReserved: boolean;
  isSold: boolean;
};

export const deleteVehicleReservation = (vehicleId: string): Promise<ReservationResponseT> =>
  updateVehicleReservation(vehicleId, undefined);

export const updateVehicleReservation = async (
  vehicleId: string,
  optionEndDate: number | undefined,
  optionClientEmail?: string,
  comment?: string,
  clientLanguage?: string
): Promise<ReservationResponseT> =>
  failOnNullOrError(
    hyperfetch<ReservationResponseT>(`/api/private/actions/vehicle/option`, {
      method: 'POST',
      body: json({ vehicleId, optionEndDate, optionClientEmail, comment, clientLanguage })
    })
  );

export const sellVehicle = (vehicleId: string): Promise<Vehicle> =>
  updateVehicleSale(vehicleId, true);

export const deleteVehicleSale = (vehicleId: string): Promise<Vehicle> =>
  updateVehicleSale(vehicleId);

const updateVehicleSale = (vehicleId: string, isSold?: boolean | null): Promise<Vehicle> =>
  failOnNullOrError(
    hyperfetch<Vehicle>(`/api/private/actions/vehicle/sale`, {
      method: 'POST',
      body: json({ vehicleId, isSold })
    })
  );

export const fetchVehicle = async (vehicleId: string): Promise<Vehicle | null> => {
  const hyperResponse: FetchResponse<Vehicle> = hyperfetch<Vehicle>(
    `/api/private/cms/expanded-item/${VEHICLE_DEFINITION_ID}/${vehicleId}`,
    {
      method: 'GET'
    }
  );
  const httpResponse = await hyperResponse.promise;

  if (isNil(httpResponse.error)) {
    return httpResponse.data || null;
  } else {
    if (httpResponse.error === HttpErrors.NotFoundRequestError) {
      return null;
    } else {
      throw httpResponse.errorData;
    }
  }
};

export const getDealStatus = (vehicle: Vehicle): DealStatus => ({
  isAvailable: vehicle.status?.code === 'available' || vehicle.soldBy == null && vehicle.optionBy == null,
  isReserved: vehicle.optionBy != null,
  isSold: vehicle.soldBy != null
});

export const fetchVehicleView = (language: string) => {
  return failOnNullOrError(
    hyperfetch<any>('/api/private/search/vehicle/view', {
      method: 'POST',
      body: json({
        languageCode: language,
        query: {
          filters: [],
          condition: 'AND'
        },
        limit: 21,
        offset: 0,
        fullTextFields: [],
        fullText: '',
        responseFields: ['*'],
        sortBy: [{ field: 'purchaseInvoiceDate', order: 'ASC' }]
      })
    })
  );
};

export const fetchVehicleDefinition = () => {
  return failOnNullOrError(
    hyperfetch<Definition[]>('/api/private/cms/definitions', {
      method: 'POST',
      body: json({
        definitionIds: [VEHICLE_DEFINITION_ID]
      })
    })
  );
};

export const fetchVehicleReservation = async (vehicleId: string) => {
  return await failOnError(
    hyperfetch<VehicleReservation | null>('/api/private/search/vehicle/reservation', {
      method: 'POST',
      body: json({
        vehicleId
      })
    })
  ) || null;
};
