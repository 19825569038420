import { __assign, __read, __spreadArray } from "tslib";
import { FilterOperatorTypes as FilterOperatorTypesEnum, SortOrder, } from '@hypercharge/portal-lambda-sdk/lib/types';
export var DEFAULT_LANGUAGE = 'fr'; // TODO (andre) this needs to come from the tenant config
export var PRICE_PRECISION = 2;
export var PRICE_FILTER_MAX_VALUE = 100000;
export var KILOMETRAGE_FILTER_MAX_VALUE = 200000;
export var PAGE_SIZE = 21;
export var AGGREGATION_LIMIT = 100;
export var VIN_REGEX = new RegExp('(.+)(.{8})');
export var CONTACT_DEFINITION_ID = 'contact';
export var VEHICLE_DEFINITION_ID = 'vehicle';
export var VEHICLE_OPTIONS_DEFINITION_ID = 'vehicleOptions';
export var VIEW_DEFINITION_ID = 'view';
export var TITLE_PROPERTY_ID = 'title';
export var ENTITY_ID_PROPERTY_ID = 'entityId';
export var ENTITY_OWNER_PROPERTY_ID = 'entityOwner';
export var ENTITY_DOMAIN_PROPERTY_ID = 'entityDomain';
export var ORDER_PROPERTY_ID = 'order';
export var ID_TOKEN_COOKIE = 'idToken';
export var TENANT_HEADER = 'tenant';
export var APP_HEADER = 'app';
export var RELEVANCE_SORT_KEY = 'relevant';
export var RELEVANCE_SORT = {
    field: '_score',
    order: SortOrder.desc,
};
export var LOCALIZED_TITLES = ['titleEn', 'titleFr', 'titleNl'];
export var LOCALIZED_SUBTITLES = ['subtitleEn', 'subtitleFr', 'subtitleNl'];
export var LOCALIZED_DESCRIPTION = ['descriptionEn', 'descriptionFr', 'descriptionNl'];
export var LOCALIZED_META_TITLES = ['metaTitleEn', 'metaTitleFr', 'metaTitleNl'];
export var LOCALIZED_META_DESCRIPTIONS = [
    'metaDescriptionEn',
    'metaDescriptionFr',
    'metaDescriptionNl',
];
export var LOCALIZED_LABEL = ['labelEn', 'labelNl', 'labelFr'];
export var LOCALIZED_CUSTOM_TITLE = ['customTitleEn', 'customTitleNl', 'customTitleFr'];
export var LOCALIZED_HTML_BODY = ['htmlBodyNl', 'htmlBodyFr', 'htmlBodyEn'];
export var WEBSITE_HOME_BANNER_DEFINITION_ID = 'websiteHomeBanner';
export var WEBSITE_HOME_SOCIAL_MEDIA_DEFINITION_ID = 'websiteSocialMedia';
export var WEBSITE_HOME_CONTENT_DEFINITION_ID = 'websiteHomeContent';
export var WEBSITE_CONCESSION_DEFINITION_ID = 'websiteConcession';
export var WEBSITE_CONCESSION_TEXT_DEFINITION_ID = 'websiteConcessionText';
export var WEBSITE_OFFER_DEFINITION_ID = 'websiteOffer';
export var WEBSITE_ABOUT_DEFINITION_ID = 'websiteAbout';
export var WEBSITE_LANGUAGES_DEFINITION_ID = 'websiteLanguages';
export var WEBSITE_ABOUT_TESTIMONIAL_DEFINITION_ID = 'websiteAboutTestimonial';
export var WEBSITE_JOB_DEFINITION_ID = 'websiteJob';
export var WEBSITE_JOB_FORM_DEFINITION_ID = 'websiteFormJob';
export var WEBSITE_CONTACT_FORM_DEFINITION_ID = 'websiteFormContact';
export var WEBSITE_CONTACT_PAGES_DEFINITION_ID = 'websiteContactPages';
export var WEBSITE_MAINTENANCE_FORM_DEFINITION_ID = 'websiteFormMaintenance';
export var WEBSITE_SELL_FORM_DEFINITION_ID = 'websiteFormSell';
export var WEBSITE_LANDING_PAGE_DEFINITION_ID = 'websiteLandingPage';
export var WEBSITE_BRANDS_DEFINITION_ID = 'websiteBrand';
export var WEBSITE_FOOTER_CONTACTS_ID = 'websiteFooterContacts';
export var WEBSITE_CONTACT_PHONE_NUMBERS_ID = 'websiteContactPhoneNumbers';
export var WEBSITE_CONTACT_SCHEDULE_ID = 'websiteContactSchedule';
export var WEBSITE_MAINTENANCE_ID = 'websiteMaintenance';
export var WEBSITE_CONDITIONS_ID = 'websitePricingConditions';
export var WEBSITE_VEHICLE_NEW_DEFINITION_ID = 'websiteVehicleNew';
export var WEBSITE_VEHICLE_USED_DEFINITION_ID = 'websiteVehicleUsed';
export var WEBSITE_VEHICLE_DEMO_DEFINITION_ID = 'websiteVehicleDemo';
export var WEBSITE_VEHICLE_RESELL_DEFINITION_ID = 'websiteVehicleResell';
export var WEBSITE_OFFER_PAGE_DEFINITION_ID = 'websiteOfferPage';
export var WEBSITE_JOB_OFFERS_PAGE_DEFINITION_ID = 'websiteJobOffers';
export var IS_ONLINE_PROPERTY_ID = 'isOnline';
export var IS_ONLINE_INTRANET_PROPERTY_ID = 'isOnlineIntranet';
export var VEHICLE_COLOR_DEFINITION_ID = 'color';
export var VEHICLE_ENGINE_DEFINITION_ID = 'engine';
export var VEHICLE_FUEL_DEFINITION_ID = 'fuel';
export var VEHICLE_MAKE_DEFINITION_ID = 'make';
export var VEHICLE_PRODUCT_LINE_DEFINITION_ID = 'productLine';
export var VEHICLE_PURPOSE_DEFINITION_ID = 'purpose';
export var VEHICLE_SPEC_DEFINITION_ID = 'spec';
export var VEHICLE_TRANSMISSION_DEFINITION_ID = 'gearbox';
export var VEHICLE_TRIM_DEFINITION_ID = 'trim';
export var VEHICLE_LOCATION_DEFINITION_ID = 'location';
export var VEHICLE_MODEL_YEAR_DEFINITION_ID = 'modelYear';
export var VEHICLE_FINISH_DEFINITION_ID = 'finish';
export var VEHICLE_SILHOUETTE_DEFINITION_ID = 'silhouette';
export var VEHICLE_UPHOLSTERY_DEFINITION_ID = 'vehicleUpholstery';
export var VEHICLE_PAINT_TYPE_DEFINITION_ID = 'vehiclePaintType';
export var VEHICLE_COMPUTED_PRICE_ID = 'computedPrice';
export var VEHICLE_PRICE_ID = 'priceVat';
export var WHITELISTED_DIGITAL_DEALER_DEFINITION_IDS = [
    VEHICLE_COLOR_DEFINITION_ID,
    VEHICLE_DEFINITION_ID,
    VEHICLE_ENGINE_DEFINITION_ID,
    VEHICLE_FUEL_DEFINITION_ID,
    VEHICLE_LOCATION_DEFINITION_ID,
    VEHICLE_MAKE_DEFINITION_ID,
    VEHICLE_PRODUCT_LINE_DEFINITION_ID,
    VEHICLE_PURPOSE_DEFINITION_ID,
    VEHICLE_SPEC_DEFINITION_ID,
    VEHICLE_TRANSMISSION_DEFINITION_ID,
    VEHICLE_TRIM_DEFINITION_ID,
    VEHICLE_MODEL_YEAR_DEFINITION_ID,
    VEHICLE_FINISH_DEFINITION_ID,
    VEHICLE_SILHOUETTE_DEFINITION_ID,
    VEHICLE_UPHOLSTERY_DEFINITION_ID,
    VEHICLE_PAINT_TYPE_DEFINITION_ID,
    VEHICLE_COMPUTED_PRICE_ID,
    VEHICLE_PRICE_ID,
];
///////////////////////////////////////////////////////////////////////////////////
// Vehicle property IDs
///////////////////////////////////////////////////////////////////////////////////
export var CODE_PROPERTY_ID = 'code';
export var INTERNAL_VALUE_PROPERTY_ID = 'internalValue';
//
// Properties coming from Stefi file
//
export var COLOR_PROPERTY_ID = 'color';
export var ENGINE_PROPERTY_ID = 'engine';
export var PRODUCT_LINE_PROPERTY_ID = 'productLine';
export var PURPOSE_PROPERTY_ID = 'purpose';
export var SPEC_PROPERTY_ID = 'spec';
export var TRANSMISSION_PROPERTY_ID = 'transmission';
export var TRIM_PROPERTY_ID = 'trim';
export var FUEL_PROPERTY_ID = 'fuel';
export var MODEL_YEAR_PROPERTY_ID = 'dateModelYear';
export var FINISH_PROPERTY_ID = 'finish';
export var SILHOUETTE_PROPERTY_ID = 'silhouette';
//
// Properties coming from Cronos file
//
export var ACCEPTED_DELIVERY_AT_PROPERTY_ID = 'acceptedDeliveryAt';
export var ANNOUNCED_DELIVERY_AT_PROPERTY_ID = 'announcedDeliveryAt';
export var COMPUTED_PRICE_PROPERTY_ID = 'computedPrice';
export var CRONOS_ACTIVE_PROPERTY_ID = 'cronosActive';
export var LAST_MODIFICATION_AT_PROPERTY_ID = 'lastModificationAt';
export var MAKE_PROPERTY_ID = 'make';
export var OPTIONS_100_PROPERTY_ID = 'options100';
export var OPTIONS_PROPERTY_ID = 'options';
export var CRONOS_TITLE_PROPERTY_ID = 'cronosTitle';
export var OPTION_REFERENCES_PROPERTY_ID = 'optionsReferences';
export var ORDER_CREATED_AT_PROPERTY_ID = 'orderCreatedAt';
export var ORDER_ID_PROPERTY_ID = 'orderId';
export var PICTURES_PROPERTY_ID = 'pictures';
export var PURCHASE_AMOUNT_PROPERTY_ID = 'purchaseAmount';
export var PURCHASE_INVOICE_DATE_PROPERTY_ID = 'purchaseInvoiceDate';
export var SPECIFICATIONS_CODE_PROPERTY_ID = 'specCode';
export var VIN_PROPERTY_ID = 'vin';
//
// Properties coming from fastback
//
export var VERSION_DESCRIPTION_PROPERTY_ID = 'versionDescription';
export var DOORS_PROPERTY_ID = 'doors';
export var COMBINED_CONSUMPTION_PROPERTY_ID = 'consumptionCombined';
export var INTERIOR_COLOR_PROPERTY_ID = 'interiorColor';
export var FIRST_REGISTRATION_DATE_PROPERTY_ID = 'dateFirstRegistration';
export var OWNERS_PROPERTY_ID = 'owners';
export var WARRANTY_PROPERTY_ID = 'warrantyAmount';
export var WARRANTY_TYPE_PROPERTY_ID = 'warrantyType';
export var VEHICLE_UPHOLSTERY_PROPERTY_ID = 'upholstery';
export var VEHICLE_PAINT_TYPE_PROPERTY_ID = 'paintType';
//
// Computed properties
//
export var VERSION_PROPERTY_ID = 'version';
export var VEHICLE_URL_SLUG_PROPERTY_ID = 'vehicleUrlSlug';
//
// Vehicle's Price
//
export var PRICE_PROPERTY_ID = 'price';
export var MASTER_PRICE_PROPERTY_ID = 'masterPrice';
export var DEALER_PRICE_PROPERTY_ID = 'dealerPrice';
export var VAT_PROPERTY_ID = 'vat';
export var PRICE_VAT_PROPERTY_ID = 'priceVat';
export var PREVIOUS_PRICE_PROPERTY_ID = 'previousPrice';
export var IS_MARGIN_VEHICLE_PROPERTY_ID = 'isMarginVehicle';
//
// Used Vehicles
//
export var KILOMETRAGE_PROPERTY_ID = 'kilometrage';
export var IS_CONDITION_PROPERTY = 'condition';
export var IS_CONDITION_PROPERTY_NEW = 'New';
export var IS_CONDITION_PROPERTY_USED = 'Used';
export var IS_CONDITION_PROPERTY_DEMO = 'Demo';
//
// Properties used to manage deals
//
export var STATUS_PROPERTY_ID = 'status';
export var TYRE_ENERGY_LABELS_PROPERTY_ID = 'tyreEnergyLabels';
export var AVAILABLE_STATUS_PROPERTY_ID = 'available';
export var RESERVED_STATUS_PROPERTY_ID = 'reserved';
export var SOLD_STATUS_PROPERTY_ID = 'sold';
export var COMMENTS_PROPERTY_ID = 'comments';
export var CREATED_AT_PROPERTY_ID = 'createdAt';
export var CONDITION_PROPERTY_ID = 'condition';
export var LOCATION_PROPERTY_ID = 'stockLocation';
export var OPTION_BY_PROPERTY_ID = 'optionBy';
export var OPTION_END_DATE_PROPERTY_ID = 'optionEndDate';
export var OPTION_START_DATE_PROPERTY_ID = 'optionStartDate';
export var SALE_CCF_PROPERTY_ID = 'ccfNumber';
export var SALE_DATE_PROPERTY_ID = 'saleDate';
export var SOLD_BY_PROPERTY_ID = 'soldBy';
export var HOT_DEAL_PROPERTY_ID = 'hotDeal';
///////////////////////////////////////////////////////////////////////////////////
// Color and Trim property IDs
///////////////////////////////////////////////////////////////////////////////////
export var COLORTRIM_NAME_PROPERTY_ID = 'name';
export var LONG_CODE_PROPERTY_ID = 'longCode';
export var SHORT_CODE_PROPERTY_ID = 'shortCode';
///////////////////////////////////////////////////////////////////////////////////
// Vehicle specification property IDs
///////////////////////////////////////////////////////////////////////////////////
export var SPEC_COMBINED_CO2_PROPERTY_ID = 'combinedCo2';
export var SPEC_COMBINED_CONSUMPTION_PROPERTY_ID = 'combinedConsumption';
export var SPEC_ENGINE_SIZE_PROPERTY_ID = 'engineSize';
export var SPEC_EURONORM_PROPERTY_ID = 'euronorm';
export var SPEC_MAX_POWER_PROPERTY_ID = 'maxPower';
export var SPEC_MAX_TORQUE_PROPERTY_ID = 'maxTorque';
export var SPEC_URBAN_CONSUMPTION_PROPERTY_ID = 'urbanConsumption';
///////////////////////////////////////////////////////////////////////////////////
// Landing Page property IDs
///////////////////////////////////////////////////////////////////////////////////
export var PRODUCT_LINE_SLUG_PROPERTY_ID = 'productLineSlug';
export var LANDING_PAGE_URL_PROPERTY_ID = 'landingPageUrl';
export var SPECIFIC_FILTERS_PROPERTY_ID = 'specificFilters';
export var SUBHEADING_PROPERTY_ID = 'subheading';
export var DESCRIPTION_PROPERTY_ID = 'description';
export var IMAGE_PROPERTY_ID = 'image';
export var IS_DESCRIPTION_CENTERED_PROPERTY_ID = 'isDescriptionCentered';
export var THEME_COLOR_PROPERTY_ID = 'themeColor';
///////////////////////////////////////////////////////////////////////////////////
// Common properties
///////////////////////////////////////////////////////////////////////////////////
export var IS_ENABLED_PROPERTY_ID = 'isEnabled';
///////////////////////////////////////////////////////////////////////////////////
// Public website whitelists
///////////////////////////////////////////////////////////////////////////////////
export var WEBSITE_PUBLICLY_SEARCHABLE_DEFINITION_IDS = [
    VEHICLE_DEFINITION_ID,
    WEBSITE_HOME_BANNER_DEFINITION_ID,
    WEBSITE_HOME_SOCIAL_MEDIA_DEFINITION_ID,
    WEBSITE_CONCESSION_DEFINITION_ID,
    WEBSITE_CONCESSION_TEXT_DEFINITION_ID,
    WEBSITE_HOME_CONTENT_DEFINITION_ID,
    WEBSITE_JOB_DEFINITION_ID,
    WEBSITE_OFFER_DEFINITION_ID,
    WEBSITE_ABOUT_DEFINITION_ID,
    WEBSITE_LANGUAGES_DEFINITION_ID,
    WEBSITE_CONTACT_PAGES_DEFINITION_ID,
    WEBSITE_ABOUT_TESTIMONIAL_DEFINITION_ID,
    WEBSITE_LANDING_PAGE_DEFINITION_ID,
    WEBSITE_BRANDS_DEFINITION_ID,
    WEBSITE_FOOTER_CONTACTS_ID,
    WEBSITE_CONTACT_PHONE_NUMBERS_ID,
    WEBSITE_CONTACT_SCHEDULE_ID,
    WEBSITE_MAINTENANCE_ID,
    WEBSITE_CONDITIONS_ID,
    WEBSITE_VEHICLE_NEW_DEFINITION_ID,
    WEBSITE_VEHICLE_USED_DEFINITION_ID,
    WEBSITE_VEHICLE_DEMO_DEFINITION_ID,
    WEBSITE_VEHICLE_RESELL_DEFINITION_ID,
    WEBSITE_OFFER_PAGE_DEFINITION_ID,
    WEBSITE_JOB_OFFERS_PAGE_DEFINITION_ID,
];
export var WEBSITE_FORMS_DEFINITIONS = [
    WEBSITE_CONTACT_FORM_DEFINITION_ID,
    WEBSITE_MAINTENANCE_FORM_DEFINITION_ID,
    WEBSITE_SELL_FORM_DEFINITION_ID,
    WEBSITE_JOB_FORM_DEFINITION_ID,
];
export var WEBSITE_FORM_FILES_CONTENT_TYPES_WHITELIST = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
/*
  FilterOperatorTypes overwritten
*/
export var FilterOperatorTypesExtended;
(function (FilterOperatorTypesExtended) {
    FilterOperatorTypesExtended["between"] = "BETWEEN";
})(FilterOperatorTypesExtended || (FilterOperatorTypesExtended = {}));
export var FilterOperatorTypes = __assign(__assign({}, FilterOperatorTypesEnum), FilterOperatorTypesExtended);
export var PUBLIC_VEHICLE_PROPERTY_IDS = __spreadArray(__spreadArray(__spreadArray(__spreadArray([
    CREATED_AT_PROPERTY_ID,
    CONDITION_PROPERTY_ID,
    TITLE_PROPERTY_ID,
    ENTITY_ID_PROPERTY_ID,
    MAKE_PROPERTY_ID,
    PRODUCT_LINE_PROPERTY_ID,
    PURPOSE_PROPERTY_ID,
    TRANSMISSION_PROPERTY_ID,
    FUEL_PROPERTY_ID,
    COLOR_PROPERTY_ID,
    INTERIOR_COLOR_PROPERTY_ID,
    TRIM_PROPERTY_ID,
    ENGINE_PROPERTY_ID,
    SPEC_PROPERTY_ID,
    SPECIFICATIONS_CODE_PROPERTY_ID,
    'optionsE',
    MASTER_PRICE_PROPERTY_ID,
    OPTIONS_100_PROPERTY_ID,
    OPTION_REFERENCES_PROPERTY_ID,
    PICTURES_PROPERTY_ID,
    ORDER_ID_PROPERTY_ID,
    MODEL_YEAR_PROPERTY_ID,
    FINISH_PROPERTY_ID,
    SILHOUETTE_PROPERTY_ID,
    VERSION_PROPERTY_ID,
    PRICE_PROPERTY_ID,
    VAT_PROPERTY_ID,
    PRICE_VAT_PROPERTY_ID,
    PREVIOUS_PRICE_PROPERTY_ID,
    COMPUTED_PRICE_PROPERTY_ID,
    VEHICLE_URL_SLUG_PROPERTY_ID,
    KILOMETRAGE_PROPERTY_ID,
    VEHICLE_UPHOLSTERY_PROPERTY_ID,
    FIRST_REGISTRATION_DATE_PROPERTY_ID,
    DESCRIPTION_PROPERTY_ID,
    VEHICLE_PAINT_TYPE_PROPERTY_ID,
    'metaTitleEn',
    'metaTitleFr',
    'metaTitleNl',
    'metaDescriptionEn',
    'metaDescriptionFr',
    'metaDescriptionNl',
    IS_MARGIN_VEHICLE_PROPERTY_ID,
    'youtubeUrl',
    'dealer',
    'carpassUrl2',
    'optionPacks',
    'pk',
    'kW',
    'euronorm',
    'emissionClass',
    'driveType',
    'maximumWeightLoadedWeight',
    'seats',
    'lbh',
    'sleepingPlaces',
    STATUS_PROPERTY_ID
], __read(LOCALIZED_DESCRIPTION)), __read(LOCALIZED_LABEL)), __read(LOCALIZED_CUSTOM_TITLE)), [
    TYRE_ENERGY_LABELS_PROPERTY_ID,
]);
