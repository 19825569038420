import { getFormattedPrice } from '@hypercharge/digitaldealer-commons/lib/utils/helpers';
import { Vehicle } from '@hypercharge/digitaldealer-commons/lib/types/psa';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

type Props = {
  vehicle: Vehicle;
  className?: string;
  isList?: boolean;
};

const ScPricePreview = styled.div`
  padding: 7px 5px;
`;

const ScPreviousPrice = styled.div<any>`
  color: #888;
  text-decoration: line-through;
  text-align: right;
  font-size: ${(props) => (props.isList ? '0.9rem' : '1rem')};
`;

const ScDealerPriceWrapper = styled.div<any>`
  color: #888;
  text-align: right;
  font-size: ${(props) => (props.isList ? '.8rem' : '1rem')};
  margin-top: ${(props) => (props.isList ? '.3rem' : '.8rem')};
  font-weight: 400;
`;

const ScDealerPrice = styled.span<any>`
  color: #5c5c5c;
  font-weight: 600;
`;

const ScPrice = styled.div<any>`
  text-align: right;
  font-size: ${(props) => (props.isList ? '1.2rem' : '1.5rem')};
  line-height: 1;
  font-weight: 600;
  color: ${(props) => (props.isPromoPrice ? '#cc0000' : '#162133')};
`;

const PricePreview = ({ vehicle, className, isList }: Props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const isPromoPrice = useMemo(() => {
    return (
      vehicle?.priceVat &&
      Number(vehicle.computedPrice || 0) &&
      Number(vehicle.computedPrice) < Number(vehicle.priceVat || 0)
    );
  }, [vehicle]);

  const price = useMemo(() => {
    let regularPrice = Math.round(
      (isPromoPrice ? vehicle.computedPrice : vehicle.priceVat || vehicle.computedPrice) || 0
    );

    if (
      Number(vehicle.masterPrice || 0) &&
      Number(vehicle.masterPrice) > Number(vehicle.priceVat)
    ) {
      regularPrice = Number(vehicle.masterPrice);
    }

    return regularPrice;
  }, [isPromoPrice, vehicle]);

  const isPromoFake = useMemo(
    () => Math.round(vehicle.priceVat) === Math.round(price),
    [vehicle.priceVat, price]
  );

  return vehicle && (price || vehicle.dealerPrice) ? (
    <ScPricePreview className={className}>
      {Boolean(price) && (
        <>
          {isPromoPrice && !isPromoFake ? (
            <ScPreviousPrice isList={isList}>
              {getFormattedPrice(vehicle.priceVat || 0, language)}
            </ScPreviousPrice>
          ) : (
            ''
          )}
          <ScPrice isList={isList} isPromoPrice={isPromoPrice && !isPromoFake}>
            {getFormattedPrice(price || 0, language)}
          </ScPrice>
        </>
      )}
      {Boolean(vehicle.dealerPrice) && (
        <ScDealerPriceWrapper isList={isList}>
          {t('VEHICLE__DEALER_PRICE')}:{' '}
          <ScDealerPrice isList={isList}>
            {getFormattedPrice(vehicle.dealerPrice || 0, language)}
          </ScDealerPrice>
        </ScDealerPriceWrapper>
      )}
    </ScPricePreview>
  ) : null;
};

export default PricePreview;
