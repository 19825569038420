import {
  PAGE_NUMBER_PARAM,
  useSearchParams
} from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchParamsProvider';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSearchResults } from '../../context/search/results';
import { PAGE_SIZE } from '@hypercharge/digitaldealer-commons/lib/constants';

const ScPaginationContainer = styled.div`
  align-items: center;
  display: grid;
  justify-items: center;
  margin: 20px 0;
`;

const BrowsePagination = () => {
  const { totalCount } = useSearchResults();
  const { params, setParam } = useSearchParams();
  const [currentPage, setCurrentPage] = useState<number>(params.page);

  // useful when doing browser back, otherwise the component would not update
  useEffect(() => {
    setCurrentPage(params.page);
  }, [params.page]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    setParam(PAGE_NUMBER_PARAM, page);
  };

  return (
    <ScPaginationContainer>
      <Pagination
        simple
        current={currentPage}
        total={totalCount}
        pageSize={PAGE_SIZE}
        onChange={onPageChange}
      />
    </ScPaginationContainer>
  );
};

export default BrowsePagination;