import { Divider, Dropdown, Menu, Modal } from 'antd';
import { Icon } from '@ant-design/compatible';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAuth } from '../context/auth/AuthProvider';
import { useUser } from '../context/user/UserProvider';
import { StyledAppSearch } from '../header/AppSearch';

const ScActions = styled.div`
  color: #fafafa;
  font-size: 1rem;
  max-height: 64px;
  @media print {
    display: none;
  }
`;

const ScIcon = styled(Icon)`
  margin-right: 0.5rem;
`;

const ScClickable = styled.span`
  cursor: pointer;
`;

const ScName = styled.div`
  max-width: 20vw;
  overflow: hidden;
  display: inline-flex;
  white-space: nowrap;
`;

const ScDropdown = styled(Dropdown)`
  margin: 0 0.5rem;
`;

const UserActions = ({ asDropdown, icon = 'user' }) => {
  const { t } = useTranslation();
  const userContext = useUser();
  const { logout } = useAuth();

  let userName = '';
  if (userContext && userContext.user) {
    userName = userContext.user.firstName.trim();
  }

  const showLogoutConfirmation = () => {
    Modal.confirm({
      title: t('AUTH__SIGN_OUT_CONFIRMATION'),
      onOk: async () => {
        await logout();
      }
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="0" disabled={true}>
        <ScIcon type="user" />
        <span>{userName}</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <ScIcon type="logout" />
        <ScClickable onClick={showLogoutConfirmation}>{t('AUTH__SIGN_OUT')}</ScClickable>
      </Menu.Item>
    </Menu>
  );

  const ActionsDropdown = () => {
    return (
      <ScDropdown overlay={menu} trigger={['click']}>
        <ScIcon type={icon} />
      </ScDropdown>
    );
  };

  const ActionsList = () => {
    return (
      <>
        <ScIcon type="user" />
        <ScName>{userName}</ScName>
        <Divider style={{background: '#e8e8e8'}} type="vertical" />
        <ScIcon type="logout" />
        <ScClickable onClick={showLogoutConfirmation}>{t('AUTH__SIGN_OUT')}</ScClickable>
      </>
    );
  };

  return <ScActions>{asDropdown ? <ActionsDropdown /> : <ActionsList />}</ScActions>;
};

const SearchAndActions = ({ isSmall }) => {
  return (
    <>
      <StyledAppSearch />
      <UserActions asDropdown={isSmall} />
    </>
  );
};

export { UserActions, SearchAndActions };
