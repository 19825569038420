import { Form, Input } from 'antd';
import { FieldProps } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';

const ScError = styled.div`
  color: #ff3333;
`;
const ScInput = styled(Input)`
  margin-bottom: 1rem;
  text-align: center;
`;

export const FormInput = ({ form, field, ...props }: FieldProps<any>) => {
  const isError = form.errors[field.name] != null;
  const errorMessage = isError ? <ScError>{form.errors[field.name]}</ScError> : null;

  return (
    <Form.Item
      hasFeedback={isError}
      help={errorMessage}
      validateStatus={isError ? 'error' : undefined}
      {...field}
    >
      <ScInput {...props} onChange={(value: any) => field.onChange(field.name)(value)} />
    </Form.Item>
  );
};
