import { Layout } from 'antd';
import toUpper from 'lodash/toUpper';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';
import { useTenant } from '../context/tenant/TenantProvider';
import { BREAKPOINTS } from '../utils/constants';
import AggregationsSidebar from './aggregations/AggregationsSidebar';
import MobileAggregations from './aggregations/MobileAggregations';
import Results from './results/Results';
import ResultsHeader from './results/ResultsHeader';
import { useIsWideView } from '../hooks/is-wide-view.hook';

const { Content } = Layout;

const ScContainer = styled.div`
  position: relative;
`;
const ScLayout = styled(Layout)`
  min-height: 100vh;
`;

const ScContent = styled(Content)`
  padding: 5px;
`;

export const Browse = () => {
  const isBigScreen = useMediaQuery({ minWidth: BREAKPOINTS.lg });
  const { t } = useTranslation();
  const { tenant } = useTenant();
  const { isWideView } = useIsWideView();

  return (
    <ScContainer>
      <Helmet title={[toUpper(tenant.id), t('BROWSE__VEHICLE_STOCK')].join(' ')} />
      <ScLayout>
        {isBigScreen && <AggregationsSidebar />}
        <ScContent>
          <ResultsHeader isWideView={isWideView} />
          <Results isWideView={isWideView} />
        </ScContent>
        {!isBigScreen && <MobileAggregations />}
      </ScLayout>
    </ScContainer>
  );
};
