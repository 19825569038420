import {Layout, Slider} from 'antd';
import styled from 'styled-components/macro';
import { Checkbox } from '../../components/Checkbox';
export const { Sider } = Layout;

export const ScAggsContainer = styled.div`
  padding: 0 10px;
`;
export const ScSider = styled(Sider)``;
export const ScAggContainer = styled.div`
  font-size: 0.9rem;
  margin: 10px 0;
  & + & {
    padding-top: 10px;
    border-top: 1px solid #dfdfdf;
  }
`;
export const ScAggTitle = styled.div`
  font-weight: 700;
  padding-bottom: 5px;
`;
export const ScAggOptions = styled.div``;
export const ScAggOption = styled.div`
  :hover {
    background-color: #efefef;
  }
`;
export const ScCheckbox = styled(Checkbox)`
  align-items: center;
  cursor: pointer;
  display: grid;
  font-size: 0.85rem;
  gap: 10px;
  grid-template-columns: min-content auto;
  padding: 3px 5px;

  :hover {
    color: #007edb;
  }
`;
export const ScCheckboxContent = styled.div`
  align-items: center;
  display: grid;
  gap: 10px;
  grid-template-columns: auto min-content;
`;
export const ScCheckboxTitle = styled.div<{ selected?: boolean }>`
  font-weight: ${props => (props.selected ? '600' : 'normal')};
  &:first-letter {
    text-transform: capitalize;
  }
`;
export const ScCheckboxCount = styled.div`
  color: #888;
  font-family: 'Roboto Mono', monospace;
`;
export const ScAggBlockLink = styled.div`
  align-items: center;
  color: #007edb;
  cursor: pointer;
  display: grid;
  grid-template-columns: min-content auto;
  margin: 0 3px;
  :hover {
    text-decoration: underline;
  }
`;
export const ScSearchAll = styled.div``;
export const ScResetContainer = styled.div`
  width: 100%;
  text-align: center;
`;
export const ScResetContent = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto auto;
`;
export const ScLoadingOverlay = styled.div<{ show: boolean }>`
  opacity: ${props => (props.show ? 1 : 0)};
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  width: ${props => (props.show ? '100%' : '0')};
  height: ${props => (props.show ? '100%' : '0')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  transition: opacity 0.2s ease;
  cursor: default;
`;

export const ScSlider = styled(Slider)`
  .ant-slider-track {
    background-color: ${({theme}) => theme.primary};
  }
  .ant-slider-handle {
    border-color: ${({theme}) => theme.primary};
  }
  .ant-slider-mark-text {
    &:first-child {
      left: -5px !important;
      transform: none !important;
    }
    &:last-child {
      left: auto !important;
      right: -5px;
      transform: none !important;
    }
  }
`;
