import { range } from 'lodash';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { PAGE_SIZE } from '@hypercharge/digitaldealer-commons/lib/constants';

// done with http://danilowoz.com/create-content-loader/
const VerticalCardLoader = () => (
  <ContentLoader height={450} width={340} speed={6} primaryColor="#f4f4f6" secondaryColor="#ecebeb">
    <rect x="0" y="260" rx="0" ry="0" width="340" height="30" />
    <rect x="0" y="320" rx="0" ry="0" width="340" height="30" />
    <rect x="0" y="380" rx="0" ry="0" width="340" height="30" />
    <rect x="0" y="60" rx="0" ry="0" width="340" height="200" />
    <rect x="250" y="420" rx="0" ry="0" width="97" height="32" />
    <rect x="130" y="420" rx="0" ry="0" width="97" height="32" />
    <rect x="0" y="10" rx="0" ry="0" width="340" height="19" />
    <rect x="0" y="35" rx="0" ry="0" width="150" height="16" />
  </ContentLoader>
);

const VerticalResultsLoader = () => {
  return (
    <>
      {range(PAGE_SIZE).map((_, index) => (
        <VerticalCardLoader key={`results-loader-${index}`} />
      ))}
    </>
  );
};

export default VerticalResultsLoader;
