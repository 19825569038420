import { range } from 'lodash';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { PAGE_SIZE } from '@hypercharge/digitaldealer-commons/lib/constants';
import styled from 'styled-components';

// done with http://danilowoz.com/create-content-loader/
const RowLoader = () => (
  <ContentLoader height={31} width={840} speed={6} primaryColor="#f4f4f6" secondaryColor="#ecebeb">
    <rect x="10" y="0" rx="0" ry="0" width="820" height="31" />
  </ContentLoader>
);

const FilledRowResultsLoader = props => {
  return (
    <div {...props}>
      {range(PAGE_SIZE).map((_, index) => (
        <RowLoader key={`results-loader-${index}`} />
      ))}
    </div>
  );
};

const RowResultsLoader = styled(FilledRowResultsLoader)`
  :first-child {
    margin-top: 8px;
  }
`;

export default RowResultsLoader;
