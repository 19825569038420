import React from 'react';
import { Route, Switch } from 'react-router';
import './index.less';
import AuthenticatedApp from './layout/AuthenticatedApp';
import UnauthenticatedApp from './layout/UnauthenticatedApp';

const App: React.FC = () => {
  return (
    <Switch>
      <Route path="/login" component={UnauthenticatedApp} />
      <Route path="/" component={AuthenticatedApp} />
    </Switch>
  );
};

export default App;
