import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <ErrorPage title={t('ERROR_PAGE__PAGE_NOT_FOUND_TITLE')} imageUrl="/images/notSupported.png">
      <>{t('ERROR_PAGE__PAGE_NOT_FOUND_TEXT')}</>
    </ErrorPage>
  );
};

export default NotFound;
